import i18n from "../../../utilities/i18n";

export const appliedTableHeading = [
    i18n.t("payments.post_payments.table.claimId"),
    i18n.t("payments.post_payments.table.dos"),
    i18n.t("payments.post_payments.table.procedure"),
    i18n.t("payments.post_payments.table.charges"),
    i18n.t("payments.post_payments.table.apply_payment"),
    i18n.t("payments.post_payments.table.apply_adjustment"),
    i18n.t("payments.post_payments.table.balance"),
    i18n.t("payments.post_payments.table.responsibility_type"),
  ];

export const DROP_CHARGES_DATA = [
    {
      id: "charges",
      name: i18n.t("payments.post_payments.chargesWithBalance"),
    },
    { id: "all", name: i18n.t("payments.post_payments.allCharges") },
  ];


export const tableHeading = [
    i18n.t("payments.post_payments.table.claimId"),
    i18n.t("payments.post_payments.table.dos"),
    i18n.t("payments.post_payments.table.procedure"),
    i18n.t("payments.post_payments.table.charges"),
    i18n.t("payments.post_payments.table.receipts"),
    i18n.t("payments.post_payments.table.opening_balance"),
    i18n.t("payments.post_payments.table.apply_payment"),
    i18n.t("payments.post_payments.table.apply_adjustment"),
    i18n.t("payments.post_payments.table.closing_balance"),
    i18n.t("payments.post_payments.table.responsibility_type"),
    i18n.t("payments.post_payments.table.claim_status"),
    i18n.t("payments.post_payments.table.claim_sub_status"),

  ];