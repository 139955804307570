import React, { useEffect, useState, useContext } from "react";
import FileUploader from "./FileUploader";
import Table from '../../commons/Table/Table';
import { FileUploadTableData } from "./FileMangementTable";
import Notify from '../../commons/notify';
import LoadingContext from '../../../container/loadingContext';

import { commonTableBody } from "../../../utilities/commonUtilities";
import service from "./service";
import { getStorage } from '../../../utilities/browserStorage';
import i18n from '../../../utilities/i18n';

import Pagination from '../../commons/pagination';
import { DEFAULT_PAGING_SIZE, PAGING_END_INDEX } from "../../../utilities/staticConfigs";
import CustomizedSmallDialogs from "../../modalWindowComponent/CustomisedSmallDialog";


/**
 * 
 * @param {string} type  'claim' || 'patient' || 'payment'
 * @param {string} parentPK primary key of claim, patient or payment
 * @param {object} permissions {uploadPermission: boolean, viewPermission: boolean, deletePermission: boolean}
 * @returns jsx
 */
export default function FileManagement({
    type,
    parentPK,
    permissions
}
) {
    const [fileData, setFileData] = useState([]);
    const [apiResponseFlag, setAPIResponseFlag] = useState(false);

    //start ==== Alert message properties
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    const setShowLoadingBar = useContext(LoadingContext);
    const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
    const [deleteItem, setDeleteItem] = useState({
        id: null,
        fileName: null
    })


    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }
    //End ====


    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

    function onPagePrevious() {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        setActivePage(previousPage);

        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        getDataList(DEFAULT_PAGING_SIZE, previousPage);
    }

    function onPageUp(e) {
        let page = Number(e.target.id)
        setActivePage(page);
        getDataList(DEFAULT_PAGING_SIZE, page);
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);
        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);
        }
        getDataList(DEFAULT_PAGING_SIZE, nextPage);
    }
    //Pagination ends




    async function getDataList(pageSize, page) {
        setShowLoadingBar(true);
        try {
            const practicePK = getStorage("practice");
            const response = await service.GetTableDataList(pageSize, page, practicePK, type, parentPK);
            if (response?.data?.results) {
                setTotalPage(Math.ceil(response.data.count / response.data?.page_size));
                setFileData(response.data.results || []);
                const rowArray = commonTableBody(response.data.results, FileUploadTableData.tableBodyData[0]);
                FileUploadTableData.tableBodyData = rowArray;
            }
        } catch (error) {
            console.error("Failed to fetch file data:", error);
            resetPaginationAndTableData();
            showNotifyWindow('show', 'error', i18n.t('errorMessages.commonError'));

        } finally {
            setAPIResponseFlag(false);
            setShowLoadingBar(false);
        }
    }

    function resetPaginationAndTableData() {
        setTotalPage(1);
        setActivePage(1);
        setStartIndex(0);
        setEndIndex(PAGING_END_INDEX);
        setFileData([]);
    }

    useEffect(() => {
        getDataList(DEFAULT_PAGING_SIZE, 1, type, parentPK);
    }, [])

    useEffect(() => {
        if (apiResponseFlag)
            getDataList(DEFAULT_PAGING_SIZE, 1, type, parentPK);
    }, [apiResponseFlag]);

    function dropDownFunction(id, name) {
        if (!permissions?.deletePermission && name.toLowerCase() === 'delete') {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            return;
        }

        if (name.toLowerCase() === 'delete') {
            setDeleteItem({
                id: id,
                fileName: fileData.find(item => item.id == id)?.file_name
            });
            setShowDeleteModalWindow(true);
        } else if (name.toLowerCase() === 'download') {
            getSignedUrl(Number(id));
        }
    }

    async function getSignedUrl(id) {
        setShowLoadingBar(true);
        const response = await service.GetSignedUrl(id, type);
        if (response?.data?.url && response?.status !== 500) {
            const url = response.data.url;
            window.open(url, '_blank');
        }
        setShowLoadingBar(false);
    }

    async function onDeleteAction() {
        if (!deleteItem?.id) return;
        setShowLoadingBar(true);
        try {
            const response = await service.DeleteFile(deleteItem?.id, type);
            if (response.status == 204) {
                setFileData(prevData => prevData.filter(f => f.id !== deleteItem?.id));
                const rowArray = commonTableBody(fileData.filter(item => item.id !== deleteItem?.id), FileUploadTableData.tableBodyData[0]);
                FileUploadTableData.tableBodyData = rowArray;
                setAPIResponseFlag(true);
                showNotifyWindow('show', 'success', "File deleted successfully.");
                setDeleteItem({
                    id: null,
                    fileName: null
                });
                setShowDeleteModalWindow(false);
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error("Failed to delete file:", error);
            showNotifyWindow('show', 'error', i18n.t('errorMessages.commonError'));
        } finally {
            setShowLoadingBar(false);
        }
    }

    const onDeleteHide = () => {
        setShowDeleteModalWindow(false);
        setDeleteItem({
            id: null,
            fileName: null
        });
    }

    return (
        <>
            <Notify
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            />
            <FileUploader
                type={type}
                parentPK={parentPK}
                setAPIResponseFlag={setAPIResponseFlag}
                permission={permissions.uploadPermission}
            />
            <div className="box-content">
                <div style={{ overflowX: "auto", width: "100%"}} className="mb-4">
                    <Table
                        tableObject={FileUploadTableData}
                        dropDownFunction={dropDownFunction}
                    />
                    <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                </div>
            </div>
            <CustomizedSmallDialogs showModal={showDeleteModalWindow} header={i18n.t("commons.confirmDelete")} type="delete" deleteItem={onDeleteAction} onHide={onDeleteHide} setShowModalWindow={setShowDeleteModalWindow}>
                {`Are you sure you want to delete this file ${deleteItem?.fileName ?? ""}?`}
            </CustomizedSmallDialogs>  
        </>
    );
}
