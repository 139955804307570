import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { POST_PAYMENT_TYPE_INSURANCE_ID, POST_PAYMENT_TYPE_PATIENT_ID } from "../../../utilities/staticConfigs";
import { POST_PAYMENT_PRIMARY_TYPES } from "../../../utilities/dictionaryConstants";
import i18n from "../../../utilities/i18n";
import SelectInput from "../../commons/input/SelectInput";
import InsurancePayment from "./InsurancePayments";
import PatientPayment from "./PatientPayment";
import {removeAllPaymentDetails, setPostPaymentType, storePaymentDetailsTableClick } from "./StateManagement/paymentSlice";
import { useLocation } from 'react-router-dom';

const PostPayment = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { paymentPK, insurancePostPayment, postPaymentType } = useSelector((state) => state.paymentData.postPaymentDetails);
    /**
     * when clicking a payment id from table this useEffect block will be triggered to store all data
     */
    useEffect(() => {
        if (props.selectedEditPaymentPK) {
            dispatch(storePaymentDetailsTableClick({
                postPaymentType: props.selectedEditPaymentType,
                paymentPK: props.selectedEditPaymentSubPK,
                patientPaymentPost: props.selectedEditPaymentType,
                patientPaymentPatientPK: props.selectedEditPtientPK,
                patientPaymentPostType: props.selectedEditPatientPostType
            }))
        }
    }, [props.selectedEditPaymentPK])


    const onHandleChange = (e) => {
        dispatch(setPostPaymentType(e.target.value))
    }


    useEffect(()=>{
        if(location.pathname==="/payment/post-payment-1" && paymentPK){
            dispatch(removeAllPaymentDetails())
        }
    },[location])

    return (
        <React.Fragment>
            <div className={props.updatePayment ? " border-radius-8 " : " border-radius-8 col-md-12"}>
                <div className="box basic-info-padding pb-0 payment-box border-radius-8">
                    {!paymentPK && postPaymentType === POST_PAYMENT_TYPE_PATIENT_ID ? (
                        <div className="row" style={!props.updatePayment ? { paddingTop: 17, paddingLeft: 19 } : { paddingTop: 17, marginLeft: -20 }}>
                            <div className="col-3">
                                <div className="form-group">
                                    <SelectInput
                                        name="postPaymentType"
                                        data={POST_PAYMENT_PRIMARY_TYPES}
                                        value={postPaymentType}
                                        onValueChange={onHandleChange}
                                        label={i18n.t("payments.post_payments.post_payment_type")}
                                        selectOptionRemove={true}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        !insurancePostPayment &&
                        postPaymentType === POST_PAYMENT_TYPE_INSURANCE_ID && (
                            <div className="row" style={!props.updatePayment ? { paddingTop: 17, paddingLeft: 19 } : { paddingTop: 17, marginLeft: -20 }}>
                                <div className="col-3">
                                    <div className="form-group">
                                        <SelectInput
                                            name="postPaymentType"
                                            data={POST_PAYMENT_PRIMARY_TYPES}
                                            value={postPaymentType}
                                            onValueChange={onHandleChange}
                                            label={i18n.t("payments.post_payments.post_payment_type")}
                                            selectOptionRemove={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                    <div className="row">
                        {postPaymentType === POST_PAYMENT_TYPE_PATIENT_ID && (
                            <PatientPayment updatePayment={props.updatePayment} setSelectedEditPaymentPK={props.setSelectedEditPaymentPK}
                            onClosePayment={props.onClosePayment} setActiveTab={props.setActiveTab}/>
                        )}
                        {postPaymentType === POST_PAYMENT_TYPE_INSURANCE_ID && (
                            <InsurancePayment updatePayment={props.updatePayment} onClosePayment={props.onClosePayment} 
                            setSelectedEditPaymentPK={props.setSelectedEditPaymentPK} setActiveTab={props.setActiveTab}/>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}

export default PostPayment;