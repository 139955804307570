import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Box, Skeleton } from '@mui/material';

/**
 * ChartSkeleton Component
 * 
 * This component renders a specified number of skeleton charts for loading states.
 * It randomly generates different types of chart skeletons (bar, line, pie) for variety.
 *
 * @param {Object} props
 * @param {number} [props.count=10] - Required. The number of skeleton charts to render
 * @param {string} [props.type='bar'] - The type of chart skeleton to render (bar, line, pie)
 * @param {number} [props.gridXs=4] - Skeleton wrapper grid width as in mui xs size unit
 * @returns {React.ReactElement} The rendered chart skeletons
 */
const ChartSkeleton = ({ count = 10, chartType, gridXs = 4 }) => {
    /**
     * Renders a random chart type skeleton
     * @returns {React.ReactElement} A skeleton for either a bar, line, or pie chart
     */
    const renderRandomChartSkeleton = () => {
        const types = ['bar', 'pie'];
        const randomType = chartType ?? types[Math.floor(Math.random() * types.length)];

        switch (randomType) {
            case 'bar':
                return (
                    <Box sx={{ flex: 1, display: 'flex', alignItems: 'flex-end' }}>
                        {Array(5).fill(0).map((_, i) => (
                            <Skeleton
                                key={i}
                                variant="rectangular"
                                width="18%"
                                height={`${20 + Math.random() * 60}%`}
                                sx={{ mr: '2%', borderTopLeftRadius: '6px', borderTopRightRadius: '6px' }}
                            />
                        ))}
                    </Box>
                );
            case 'line':
                return (
                    <Box sx={{ flex: 1, position: 'relative' }}>
                        <Skeleton variant="rectangular" width="100%" height={2} sx={{ position: 'absolute', top: '50%' }} />
                        {Array(5).fill(0).map((_, i) => (
                            <Skeleton
                                key={i}
                                variant="circular"
                                width={10}
                                height={10}
                                sx={{ position: 'absolute', left: `${i * 25}%`, top: `${Math.random() * 100}%` }}
                            />
                        ))}
                    </Box>
                );
            case 'pie':
                return (
                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Skeleton variant="circular" width="50%" height={0} sx={{ paddingBottom: '50%' }} />
                    </Box>
                );
        }
    };

    return Array(count).fill(0).map((_, index) => (
        <Grid item xs={gridXs} key={`skeleton-${index}`}>
            <Paper elevation={2} sx={{ p: 2, height: '100%' }} className='chart-skeleton'>
                <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <Skeleton variant="text" width="60%" height={32} sx={{ mb: 2 }} />
                    {renderRandomChartSkeleton()}
                    <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between' }}>
                        {Array(5).fill(0).map((_, i) => (
                            <Skeleton key={i} variant="text" width="15%" />
                        ))}
                    </Box>
                </Box>
            </Paper>
        </Grid>
    ));
};

ChartSkeleton.propTypes = {
    count: PropTypes.number.isRequired,
    chartType: PropTypes.string,
    gridXs: PropTypes.number
};

ChartSkeleton.defaultProps = {
    count: 10,
    gridXs: 4
};

export default ChartSkeleton;