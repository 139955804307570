import React from "react";
import { useSelector } from "react-redux";
import i18n from "../../../utilities/i18n";
import { Link } from "react-router-dom";
import CommonButton from "../../commons/Buttons";
import { useDispatch } from "react-redux";
import { hideModalWindow, onContinuePosting, onHandleEditPayment, onHandleEditPaymentDate, showModalWindow, updateValueChange } from "./StateManagement/paymentSlice";
import { checkPermission, CommonDateFormat, currencyFormat } from "../../../utilities/commonUtilities";
import { permission_key_values_payments } from "../../../utilities/permissions";
import { DEFAULT_PAGING_SIZE, PAYEMNT_MODES_CREDITCARD_ID, ROUTE_VIEW_RECEIPTS } from "../../../utilities/staticConfigs";
import { getPatientPaymentClaimDetails, handleRadioButtonChange, ListPatientAppliedClaims, onUpdatePatientPaymentDetails } from "./StateManagement/asyncThunkAPI";
import CustomizedDialogs from "../../modalWindowComponent/CustomizedDialogs";
import { Form } from "react-bootstrap";
import TextInput from "../../commons/input/input";
import SelectInput from "../../commons/input/SelectInput";
import { CREDIT_CARD_TYPES, PAYEMNT_MODES, POST_PAYMENT_SECONDARY_SUB_TYPES, POST_PAYMENT_SECONDARY_TYPES } from "../../../utilities/dictionaryConstants";
import CalendarInput from "../../commons/input/CalendarInput";
import TextAreaInput from "../../commons/textarea/textarea";
import { setStorage } from "../../../utilities/browserStorage";

const PatientPaymentDetails = (props) => {
    const dispatch = useDispatch();
    const { autoPost, radioValue, postManually,
    } = useSelector((state) => state.paymentData.patientPostDetails);
    const { includeFamilyMemberBalances } = useSelector((state) => state.paymentData.patientPaymentVariables);
    const { patientPaymentData, editPaymentVariables, header, showEditPaymentModal } = useSelector((state) => state.paymentData);
    const { paymentPK } = useSelector((state) => state.paymentData.postPaymentDetails);
    const onHandleChange = (e) => {
        if (e.target) {
            let name = e.target.name;
            let value =
                e.target.type === "checkbox" ? e.target.checked : e.target.value;
            dispatch(updateValueChange({ name: name, value: value, key: "patientPaymentVariables" }))
        }
    }
    //eslint-disable-next-line
    const onHandleRadioButton = (e) => {
        dispatch(handleRadioButtonChange(e.target.value))
    }

    const onViewAppliedPayments = () => {
        dispatch(ListPatientAppliedClaims(paymentPK));
    }


    const onContinuePostingAction = () => {
        dispatch(onContinuePosting());
        dispatch(getPatientPaymentClaimDetails({pageSize:DEFAULT_PAGING_SIZE,page:1,patientPK:props.patientPK}))
    }

    const paymentPKSetToStorage = () => {
        if (paymentPK) setStorage("paymentPK", paymentPK);
    }

    function resetEditPaymentForm() {
        dispatch(hideModalWindow({ key: "showEditPaymentModal", open: false }))
    }


    const onHandleChangeEditPaymentDate=(value,name)=>{
        if (!value || value == "" || value == "Invalid Date") {
            dispatch(onHandleEditPaymentDate({value:"",name:name}))
        }else{
            dispatch(onHandleEditPaymentDate({value:value,name:name}))
        }
    }

    const onHandleChangeEditPayment = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name && name=="amount") {
          value = String(e.target.value)
          value = String(e.target.value)
          .trim()
          .replace(/[^0-9.]/g, "");
        }
    
        dispatch(onHandleEditPayment({value:value,name:name}))
      }

    const onSaveFormData = (e) => {
        e.preventDefault();
        dispatch(onUpdatePatientPaymentDetails({paymentPK:paymentPK,updatePayment:props.updatePayment}))
    }

    return (
        <>
            {autoPost ? (
                <div>
                    <div className="row padding-top15 margin-left20">
                        <div className="col-5">
                            <div className="checkbox-wrapper">
                                <div className="custom-radio">
                                    <input
                                        type="radio"
                                        id="radio1"
                                        name="radio"
                                        onChange={(e) => onHandleRadioButton(e)}
                                        value="balanceUnderPatientOldestFirst"
                                        checked={
                                            radioValue === "balanceUnderPatientOldestFirst"
                                                ? true
                                                : false
                                        }
                                    />
                                    <label className="radio" htmlFor="radio1"></label>
                                    <label className="" htmlFor="radio1">
                                        <div className="custom-radio padding-left-10">
                                            {i18n.t(
                                                "payments.post_payments.balanceUnderPatientOldestFirst"
                                            )}
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className="checkbox-wrapper">
                                <div className="custom-radio">
                                    <input
                                        type="radio"
                                        id="radio3"
                                        name="radio"
                                        value="balanceUnderPatientNewestFirst"
                                        onChange={(e) => onHandleRadioButton(e)}
                                        checked={
                                            radioValue === "balanceUnderPatientNewestFirst"
                                                ? true
                                                : false
                                        }
                                    />
                                    <label className="radio" htmlFor="radio3"></label>
                                    <label className="" htmlFor="radio3">
                                        <div className="custom-radio padding-left-10">
                                            {i18n.t(
                                                "payments.post_payments.balanceUnderPatientNewestFirst"
                                            )}
                                        </div>
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row padding-top15 margin-left20">
                        <div className="col-1 max-width-3 lh-1">
                            <div className="form-group">
                                <div className="custom-checkbox">
                                    <input
                                        type="checkbox"
                                        id="includeFamilyMemberBalances"
                                        name="includeFamilyMemberBalances"
                                        checked={includeFamilyMemberBalances}
                                        onChange={(e) => onHandleChange(e)}
                                    />
                                    <label
                                        className="checkbox"
                                        htmlFor="includeFamilyMemberBalances"
                                    ></label>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <label className="margin-top-3px">
                                {i18n.t(
                                    "payments.post_payments.includeFamilyMemberBalances"
                                )}
                            </label>
                        </div>
                    </div>
                    <div className="col justify-right editButton">
                        <CommonButton
                            variant="contained"
                            onClick={() => dispatch(showModalWindow({ key: "showEditPaymentModal", open: true, header: i18n.t("payments.post_payments.editPayment") }))}
                            label={i18n.t("commons.edit")}
                        />
                    </div>
                </div>
            ) : (
                <div className="" style={{ paddingBottom: "1px" }}></div>
            )}

            {postManually ? (
                <div className={props.updatePayment ? "margin-left30 margin-right40" : "margin-left30 margin-right40 margin-top25"} >
                    <table className="postPaymentTable">
                        <tr>
                            <td>
                                <span className="paymentFormText">
                                    {i18n.t("payments.post_payments.payment_from")}
                                </span>:
                            </td>
                            <td>
                                <span className="paymentFormValueText">
                                    {patientPaymentData.patient_name}
                                </span>
                            </td>
                            <td>
                                <span className="paymentFormText margin-left50">
                                    {i18n.t("payments.post_payments.payment_type")}
                                </span> :
                            </td>
                            <td>
                                <span className="paymentFormValueText">
                                    {(patientPaymentData.secondary_payment_type == 1) ? patientPaymentData.secondary_payment_type_name : patientPaymentData.secondary_payment_subtype_name}
                                </span>
                            </td>
                            <td style={{ textAlign: 'right' }}>
                                <span className="paymentFormText">
                                    {i18n.t("payments.post_payments.payment_mode")}
                                </span> :
                            </td>
                            <td style={{ textAlign: 'right' }}>
                                <span className="paymentFormValueText">
                                    {patientPaymentData.payment_mode_name}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span className="paymentFormText">{i18n.t(
                                    "payments.post_payments.deposit/payment_date"
                                )}</span> :
                            </td>
                            <td>
                                <span className="paymentFormValueText">
                                    {patientPaymentData.payment_date
                                        ? CommonDateFormat(patientPaymentData.payment_date)
                                        : ""}
                                </span>
                            </td>
                            <td>
                                <span className="paymentFormText margin-left50">
                                    {i18n.t("payments.post_payments.payment_amount")}
                                </span> :
                            </td>
                            <td>
                                <span className="paymentFormValueText">
                                    {currencyFormat(patientPaymentData.amount)}
                                </span>
                            </td>
                            {patientPaymentData.payment_mode_name !== 'Cash' &&
                                <>
                                    <td style={{ textAlign: 'right' }}>
                                        <span className="paymentFormText">
                                            {i18n.t("payments.post_payments.payment_reference")}
                                        </span> :
                                    </td>
                                    <td style={{ textAlign: 'right' }}>
                                        <span className="paymentFormValueText">
                                            {patientPaymentData.reference_check_number}
                                        </span>
                                    </td>
                                </>
                            }
                        </tr>
                        <tr>
                            {patientPaymentData.payment_mode === PAYEMNT_MODES_CREDITCARD_ID && (
                                <>
                                    <td>
                                        <span className="paymentFormText">
                                            {i18n.t("payments.post_payments.credit_card")}
                                        </span> :
                                    </td>
                                    <td>
                                        <span className="paymentFormValueText">
                                            {patientPaymentData.card_type}
                                        </span>
                                    </td>
                                </>
                            )}

                        </tr>
                    </table>
                    <div className="justify-right">
                        {checkPermission(permission_key_values_payments.payments_view_payments_modify,
                            permission_key_values_payments.post_payments
                        ) &&
                            <CommonButton
                                variant="contained"
                                onClick={() => dispatch(showModalWindow({ key: "showEditPaymentModal", open: true, header: i18n.t("payments.post_payments.editPayment") }))}
                                label={i18n.t("commons.edit")}
                            />}
                    </div>
                </div>
            ) : (
                ""
            )}
            {paymentPK && props.updatePayment && (
                <div className="row margin-top25 margin-left30">
                    <div>
                        <CommonButton
                            icon="eye"
                            onClick={() => onViewAppliedPayments()}
                            variant="outlined"
                            label={i18n.t(
                                "payments.post_payments.buttons.viewAppliedPayments"
                            )}
                        />
                    </div>
                    <div className="margin-left20">
                        <CommonButton
                            variant="outlined"
                            onClick={() => onContinuePostingAction()}
                            label={i18n.t("payments.post_payments.buttons.continuePosting")}
                        />
                    </div>
                    <div className="col-3">
                    </div>
                    <div className="col-3">
                        {patientPaymentData.payment_done ?
                            <Link
                                to={ROUTE_VIEW_RECEIPTS}
                                className="ml-1 mr-2 div-float-right"
                                target="#"
                                onClick={() => paymentPKSetToStorage()}
                            >
                                {i18n.t("payments.post_payments.viewReceipt")}
                            </Link> : ""
                        }
                    </div>
                </div>
            )}

            <div className="">
                <CustomizedDialogs
                    header={header}
                    type="save"
                    showModal={showEditPaymentModal}
                    setShowModalWindow={() => dispatch(hideModalWindow({ key: "showEditPaymentModal", open: false }))}
                    resetForm={() => resetEditPaymentForm()}
                >
                    <Form
                        id="form_dataEntry"
                        autoComplete="off"
                        onSubmit={(e) => onSaveFormData(e)}
                        encType="multipart/form-data"
                    >
                        <div className="">
                            <div className="">
                                <div className="row">
                                    <div className="col-md-6">
                                        <TextInput
                                            type="text"
                                            disabled={true}
                                            name="custom_payment_id"
                                            value={editPaymentVariables.custom_payment_id}
                                            onValueChange={(e) => onHandleChangeEditPayment(e)}
                                            label={i18n.t(
                                                "payments.post_payments.editPaymentModal.payment_id"
                                            )}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <TextInput
                                            type="text"
                                            disabled={true}
                                            name="patient_name"
                                            value={editPaymentVariables.patient_name}
                                            onValueChange={(e) => onHandleChangeEditPayment(e)}
                                            label={i18n.t(
                                                "payments.post_payments.editPaymentModal.payment_from"
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md">
                                                <SelectInput
                                                    data={POST_PAYMENT_SECONDARY_TYPES}
                                                    name="secondary_payment_type"
                                                    value={
                                                        editPaymentVariables.secondary_payment_type
                                                    }
                                                    onValueChange={(e) => onHandleChangeEditPayment(e)}
                                                    label={i18n.t(
                                                        "payments.post_payments.editPaymentModal.payment_type"
                                                    )}
                                                    className={
                                                        editPaymentVariables.secondary_payment_type
                                                            ? ""
                                                            : "input-error"
                                                    }
                                                    required={true}
                                                />
                                            </div>
                                            {editPaymentVariables.secondary_payment_type === 2
                                                && (
                                                    <div className="col-md pl-0">
                                                        <SelectInput
                                                            data={POST_PAYMENT_SECONDARY_SUB_TYPES}
                                                            name="secondary_payment_subtype"
                                                            value={
                                                                editPaymentVariables.secondary_payment_subtype
                                                            }
                                                            onValueChange={(e) => onHandleChangeEditPayment(e)}
                                                            label={i18n.t(
                                                                "payments.post_payments.payment_sub_type"
                                                            )}
                                                            className={
                                                                editPaymentVariables.secondary_payment_subtype
                                                                    ? ""
                                                                    : "input-error"
                                                            }
                                                            required={true}
                                                        />
                                                    </div>)}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md">
                                                <SelectInput
                                                    data={PAYEMNT_MODES}
                                                    name="payment_mode"
                                                    value={editPaymentVariables.payment_mode}
                                                    onValueChange={(e) => onHandleChangeEditPayment(e)}
                                                    label={i18n.t(
                                                        "payments.post_payments.editPaymentModal.payment_mode"
                                                    )}
                                                    className={
                                                        editPaymentVariables.payment_mode
                                                            ? ""
                                                            : "input-error"
                                                    }
                                                    required={true}
                                                />
                                            </div>
                                            {editPaymentVariables.payment_mode ===
                                                PAYEMNT_MODES_CREDITCARD_ID && (
                                                    <div className="col-md pl-0">
                                                        <SelectInput
                                                            name="card_type"
                                                            data={CREDIT_CARD_TYPES}
                                                            value={editPaymentVariables.card_type}
                                                            onValueChange={(e) => onHandleChangeEditPayment(e)}
                                                            label={i18n.t(
                                                                "payments.post_payments.credit_card"
                                                            )}
                                                            showCreditCardIcons={true}
                                                            className={
                                                                editPaymentVariables.card_type
                                                                    ? ""
                                                                    : "input-error"
                                                            }
                                                            required={true}
                                                        />
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <CalendarInput
                                            name="payment_date"
                                            selected={editPaymentVariables.payment_date}
                                            onValueChange={(e) => onHandleChangeEditPaymentDate(e, "payment_date")}
                                            label={i18n.t(
                                                "payments.post_payments.editPaymentModal.payment_date"
                                            )}
                                            className={
                                                editPaymentVariables.payment_date
                                                    ? ""
                                                    : "input-error"
                                            }
                                            required={true}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <TextInput
                                            type="text"
                                            name="amount"
                                            value={editPaymentVariables.amount}
                                            onValueChange={(e) => onHandleChangeEditPayment(e)}
                                            label={i18n.t(
                                                "payments.post_payments.editPaymentModal.payment_amount"
                                            )}
                                            className={
                                                editPaymentVariables.amount
                                                    ? ""
                                                    : "input-error"
                                            }
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    {editPaymentVariables.payment_mode != 1 &&
                                        <div className="col-md-6">
                                            <TextInput
                                                type="text"
                                                name="reference_check_number"
                                                value={
                                                    editPaymentVariables.reference_check_number
                                                }
                                                onValueChange={(e) => onHandleChangeEditPayment(e)}
                                                label={i18n.t(
                                                    "payments.post_payments.editPaymentModal.payment_reference"
                                                )}
                                                className={
                                                    editPaymentVariables.reference_check_number
                                                        ? ""
                                                        : "input-error"
                                                }
                                                required={true}
                                            />
                                        </div>
                                    }
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <TextAreaInput
                                            name="note"
                                            value={editPaymentVariables.note}
                                            onValueChange={(e) => onHandleChangeEditPayment(e)}
                                            rows={3}
                                            label={i18n.t(
                                                "payments.post_payments.editPaymentModal.payment_note"
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </CustomizedDialogs>
            </div>
        </>
    )

}


export default PatientPaymentDetails;