import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import Notify from "../commons/notify";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, styled, IconButton } from '@mui/material';
import { checkPermission, commonTableBody } from '../../utilities/commonUtilities';
import { permission_key_values_claim, permission_key_values_patient } from '../../utilities/permissions';

import ReactHtmlParser from "react-html-parser";

import service from "./Services/service";

import { useDispatch, useSelector } from "react-redux";

import i18n from "../../utilities/i18n";

import "./style.css";
import Table from '../commons/Table/Table';

import { SearchClaimTableData, TableBodyData } from "./SearchClaimTableData";
import { addRemoveFromSelectedTab, checkForPatientAlert, getUserOpenedClaimModuleTabs, getlast10TableData } from './StateManagement/asyncThunkAPI';
import { setLast10Sort, setOpenTab, showModalWindow } from "./StateManagement/ClaimModuleSlice";
import SearchClaimsTableComponent from './SearchClaimsTableComponent';
import { getStorage } from '../../utilities/browserStorage';
import AddIcon from "@mui/icons-material/Add";
import { MAX_OPEN_TABS_CLAIMS, MAX_OPEN_TABS_PATIENTS, ROUTE_PATIENTS_LIST } from '../../utilities/staticConfigs';
import ProfessionalClaim from './ProfessionalClaimNew';
import LoadingContext from '../../container/loadingContext';
import CommonButton from '../commons/Buttons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ExportIconButton from '../commons/exportIconButton';

import { csvxlsFileCheck } from '../../utilities/validations';
import CustomizedSmallDialogs from '../modalWindowComponent/CustomisedSmallDialog';
import { format } from 'date-fns';


// Custom style for the indicator
const CustomIndicator = styled('span')(() => ({
    // Define your custom indicator styles here
    backgroundColor: 'white',
    height: 7,
    position: 'absolute',
    bottom: 0,
    width: 'calc(100% - 4px)',
    marginLeft: '2px'

}));

const StyledTabList = styled(TabList)`
& .MuiTabs-indicator {
  background-color: transparent !important;
  height: 0px !important;
}
`;


const SearchClaimModule = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const {
        last10Table,
        openTabPks,
        tabsOpen,
        selectedClaimType,
        activeParentTab,
        claimParentTabs,
        searchClaimsTable,
        patientAlertModal,
        alertMessage,
        dataLoading,
        editDetailsLoading,
        insuranceLoading
    } = useSelector((state) => state.claimManagement);
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");
    const [last10List, setLast10List] = useState([]);
    const setShowLoadingBar = useContext(LoadingContext);

    const [importErrorMessage, setImportErrorMessage] = useState("");
    const [showImportErrorModalWindow, setShowImportErrorModalWindow] = useState(false);
    const [validateData, setValidateData] = useState([]);

    /**
    * table initial ordering and sorting initialization
    */
    SearchClaimTableData.tableHeadings[0].initialOrdering = last10Table.initialOrderingClaimId;
    SearchClaimTableData.tableHeadings[0].orderType = last10Table.orderType;
    SearchClaimTableData.tableHeadings[1].initialOrdering = last10Table.initialOrderingPatientName;
    SearchClaimTableData.tableHeadings[1].orderType = last10Table.orderType;
    SearchClaimTableData.tableHeadings[2].initialOrdering = last10Table.initialOrderingDos;
    SearchClaimTableData.tableHeadings[2].orderType = last10Table.orderType;
    SearchClaimTableData.tableHeadings[5].initialOrdering = last10Table.initialOrderingBillDate;
    SearchClaimTableData.tableHeadings[5].orderType = last10Table.orderType;

    // eslint-disable-next-line
    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    useEffect(() => {
            setShowLoadingBar(true);
        if (dataLoading || editDetailsLoading || insuranceLoading) {
            setShowLoadingBar(true);
        } else {
            setShowLoadingBar(false);
        }
    }, [dataLoading,editDetailsLoading,insuranceLoading])

    /***
     * useEffect for getting opened tab at the initial call
     */

    useEffect(() => {
        if (activeParentTab) {
            dispatch(getUserOpenedClaimModuleTabs());
        }
    }, [activeParentTab])


    /**
     * storing table data 
     */
    useEffect(() => {
        if (last10Table.tableData.length > 0) {
            const rowArray = commonTableBody(
                last10Table.tableData?.length > 0 ? last10Table.tableData : [],
                SearchClaimTableData.tableBodyData[0]
            );
            SearchClaimTableData.tableBodyData = rowArray;
            setLast10List(last10Table.tableData);
        } else {
            setLast10List([]);
            SearchClaimTableData.tableBodyData = TableBodyData
        }
    }, [last10Table.tableData])


    /**
     * calling last 10 table data in inital render and when ever table order type changes
     */
    useEffect(() => {
        if (activeParentTab == "last10" || last10Table.orderType)
            getPatientListClaim();
    }, [last10Table.orderType, activeParentTab])

    useEffect(
        () => {
            let arrPTabs = [];
            if (openTabPks && Array.isArray(openTabPks)) {
                arrPTabs = openTabPks.filter(
                    (item) => parseInt(item, 10) > 0 || item == "add_new"
                );
                dispatch(setOpenTab(arrPTabs ? arrPTabs.length : 0));
            } else {
                dispatch(setOpenTab(0))
            }
        },
        [openTabPks]
    );


    const getPatientListClaim = () => {
        let query =
            "?list=true&last10=true&practice_pk=" +
            getStorage("practice") +
            "&page=0";

        if (last10Table.sortField) {
            query +=
                "&needPageZeroPagination=1&column=" + last10Table.orderType + last10Table.sortField;
        }

        dispatch(getlast10TableData(query));
    }



    /**
     * function called on tab change 
     * @param {*} event 
     * @param {*} tab 
     * @param {*} id 
     * @param {*} claimId 
     */
    const onTabChange = (event, tab, id, claimId) => {
        if (event.target) {
            let name = event?.target?.name;
            if (name === "search") {
                let item = {
                    pk: name,
                    claim_id: name,
                    type: "claims",
                    action: "add",
                    practice_pk: getStorage("practice"),
                };
                dispatch(addRemoveFromSelectedTab({ item: item, tab: tab }))
            } else if (name === "last10") {
                let item = {
                    pk: name,
                    claim_id: name,
                    type: "claims",
                    action: "add",
                    practice_pk: getStorage("practice"),
                };
                dispatch(addRemoveFromSelectedTab({ item: item, tab: tab }));
            } else if (name === "add_new") {
                let item = {
                    pk: name,
                    claim_id: name,
                    type: "claims",
                    action: "add",
                    practice_pk: getStorage("practice"),
                };
                dispatch(addRemoveFromSelectedTab({ item: item, tab: tab }));
            } else if (name) {
                let item = {
                    pk: Number(name.split(" ")[1]),
                    claim_id: Number(name.split(" ")[2]),
                    type: "claims",
                    action: "add",
                    practice_pk: getStorage("practice"),
                };
                dispatch(addRemoveFromSelectedTab({ item: item, tab: tab }));
            }
        } else {
            let item = {
                pk: id,
                claim_id: claimId,
                type: "claims",
                action: "add",
                practice_pk: getStorage("practice"),
            };
            dispatch(addRemoveFromSelectedTab({ item: item, tab: tab }));
        }
    }

    /**
     * function to close claim tab on close button click
     * @param {*} e 
     * @param {*} item 
     */
    function closeClaimTab(e, item) {
        let newItem = { ...item, claimId: item.claim_id };
        newItem.action = "remove";
        newItem.type = "claims";
        dispatch(addRemoveFromSelectedTab({ item: newItem, tab: item.pk == "add_new" ? "editclaim" : "editclaim" + item.pk }));
    }

    /**
     * function to open a existing claim id
     */
    const openClaim = (id, name) => {
        /**
         * on Edit button click
         */
        if (name.toLowerCase() == "edit") {
            let item = last10Table?.tableData?.find((obj) => obj?.id == id);
            onEditClaim(
                item?.id,
                item?.patient_name,
                item?.custom_claim_id,
                item?.patient_id
            );
        }
        /**
         * on custom claim id Link click
         */
        if (name.toLowerCase() == "custom_claim_id") {
            let item = last10Table?.tableData?.find((obj) => obj?.id == id);
            onEditClaim(
                item?.id,
                item?.patient_name,
                item?.custom_claim_id,
                item?.patient_id
            );
        }

        /**
         * on patient namee click it will go to patient tabs
         */
        if (name.toLowerCase() == "patient_name") {
            if (checkPermission(permission_key_values_patient.patient_list_sub_module_view)) {
                let item = last10Table?.tableData?.find((obj) => obj?.id == id);
                onPatientNameClick(item?.patient_id);
            } else {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            }
        }
    }



    const onAlertOk = () => {
        dispatch(showModalWindow({ key: "patientAlertModal", value: false }));
    }


    const onEditClaim = (pk, name, claimId, patientID) => {
        let claimOpened = false;
        if (openTabPks && Array.isArray(openTabPks)) {
            claimOpened = openTabPks.includes(patientID);
        }

        /**
         * checking already tab opened if opened showing error message
         */
        if (!claimOpened && tabsOpen >= MAX_OPEN_TABS_CLAIMS) {
            showNotifyWindow(
                "show",
                "error",
                i18n.t("errorMessages.max_claim_tabs")
            );
        } else {

            if (openTabPks && openTabPks.includes(patientID)) {
                onAlertOk();
            }
            else {
                let data = {
                    patient_pk: patientID,
                    alert_type: 1, // Alert type of Patient
                };
                dispatch(checkForPatientAlert(data))
            }

            let item = {
                pk: pk,
                claimId: claimId,
                type: "claims",
                action: "add",
            };

            dispatch(
                addRemoveFromSelectedTab({
                    item: item,
                    tab: "editClaims" + pk,
                })
            );
        }
    };


    function onPatientNameClick(patientID) {
        const result = service.GetSelectedTabs("patients", getStorage("practice"));
        result.then((response) => {
            let openedPKs =
                response.data && response.data.opened_tab_pks
                    ? response.data.opened_tab_pks.filter(
                        (item) => parseInt(item, 10) > 0
                    )
                    : [];
            if (
                openedPKs &&
                Array.isArray(openedPKs) &&
                openedPKs.length >= MAX_OPEN_TABS_PATIENTS &&
                !openedPKs.includes(patientID)
            ) {
                showNotifyWindow(
                    "show",
                    "error",
                    i18n.t("errorMessages.max_patient_tabs")
                );
            } else {
                let item = { selectedID: patientID }
                history.push(ROUTE_PATIENTS_LIST, item)
            }
        });
    }

    function onLast10SortClick(e, name) {
        name = name.replace(" ", "_").toLowerCase();
        let fieldName = "";
        if (name == "patient_name") {
            fieldName = "patient__full_name";

        } else if (name == "claim_id") {
            fieldName = "custom_claim_id";

        } else if (name == "dos") {
            fieldName = "service_from_date";

        } else if (name == "billed_date") {
            fieldName = "last_billed_date";

        }

        dispatch(setLast10Sort(fieldName));
    }

    function onHandleCsvImport(e) {
        e.preventDefault();
        document.getElementById("claimfile").value = null;
        document.getElementById("claimfile").click();
    }

    function onHandleCSVChange(e) {
        setShowLoadingBar(true);
        const value = e.target.type === "file" ? e.target.files[0] : e.target.value;

        if (e.target.type === "file") {
            if (e.target.files[0] && csvxlsFileCheck(e.target.files[0].name)) {
                const data = new FormData();
                data.append("file", value);
                data.append("practice_pk", getStorage("practice"));
                let result = service.ImportClaim(data);
                result.then((response) => {
                    setShowLoadingBar(false);
                    if (response.data.message !== undefined) {
                        if (response.data.message === "no_file_records") {
                            showNotifyWindow(
                                "show",
                                "error",
                                i18n.t("errorMessages.empty_file")
                            );
                        } else if (response.data.message === "invalid_headings") {
                            showNotifyWindow(
                                "show",
                                "error",
                                i18n.t("errorMessages.invalid_headings")
                            );
                        } else if (response.data.message === "data_imported") {
                            if (response.data.row_list) {
                                setValidateData(response.data.row_list);
                                let validate_data = "";
                                for (var key in response.data.row_list) {
                                    let mandatory = [];
                                    let invalidData = [];
                                    response.data.row_list[key]["mandatory"].forEach((e) => {
                                        mandatory.push(`&nbsp; ${e}`);
                                    });
                                    response.data.row_list[key]["invalid_data"].forEach((item) => {
                                        invalidData.push(`&nbsp; ${item}`);
                                    });

                                    validate_data += `<p><u><strong>Row </strong>${key} </u> <br/> <strong>Patient ID :</strong> ${response.data.row_list[key]["patient_id"]}<br/>
                                        <strong>Mandatory :</strong> ${mandatory}<br/>
                                        <strong>Invalid_Data :</strong> ${invalidData} <br/></p>`;
                                }
                                setImportErrorMessage(
                                    `<p style='margin-bottom:10px;'>${response.data.success_count} out of ${response.data.claim_count} patient records have been imported.
                   The records that were not imported are listed below :</p>` +
                                    "<br/><br/>" +
                                    validate_data
                                );
                                setShowImportErrorModalWindow(true);
                            } else {
                                showNotifyWindow(
                                    "show",
                                    "success",
                                    i18n.t("validations.success.import_success")
                                );
                            }
                        }
                    } else {
                        showNotifyWindow(
                            "show", "error",
                            response?.data?.error_message ?? i18n.t("errorMessages.import_failed"),
                            5000
                        );
                    }
                });
            } else {
                showNotifyWindow(
                    "show",
                    "error",
                    i18n.t("errorMessages.invalid_file_format")
                );
            }
        }
    }

    function onImportErrorOK() {
        setShowImportErrorModalWindow(false);
    }

    function onDownloadCSV(e) {
        let csvData = ['"ROW","PATIENT ID","MANDATORY","INVALID DATA"'];
        for (var key in validateData) {
            let mandatory = "";
            let invalidData = "";
            for (var k in validateData[key]["mandatory"]) {
                mandatory += validateData[key]["mandatory"][k] + ",";
            }
            for (var i in validateData[key]["invalid_data"]) {
                invalidData += validateData[key]["invalid_data"][i] + ",";
            }
            csvData.push(
                '"' +
                key +
                '",' +
                '"' +
                validateData[key]["patient_id"] +
                '",' +
                '"' +
                mandatory +
                '",' +
                '"' +
                invalidData +
                '"'
            );
        }
        var CSV = csvData.join("\n");
        window.URL = window.webkitURL || window.URL;
        var contentType = "text/csv";
        var csvFile = new Blob([CSV], {
            type: contentType,
        });
        e.target.href = window.URL.createObjectURL(csvFile);
        e.target.dataset.downloadurl = [
            contentType,
            e.target.download,
            e.target.href,
        ].join(":");
    }


    const OnExportMenuItemClicked = (e, type) => {
        setShowLoadingBar(true);
        let query = "";
        if (activeParentTab === "last10") {
            query +=
                "?last10=true&practice_pk=" +
                getStorage("practice") +
                "&page=0&export=true&export_type=" +
                type
        } else if (activeParentTab === "listClaims") {
            let dos_from = "";
            let dos_to = "";
            let claim_from = "";
            let claim_to = "";
            try {
                dos_from = searchClaimsTable.dosFrom
                    ? format(searchClaimsTable.dosFrom, "yyyy-MM-dd")
                    : "";
                dos_to = searchClaimsTable.dosTo ? format(searchClaimsTable.dosTo, "yyyy-MM-dd") : "";
                claim_from = searchClaimsTable.claimEnteredFrom
                    ? format(searchClaimsTable.claimEnteredFrom, "yyyy-MM-dd")
                    : "";
                claim_to = searchClaimsTable.claimEnteredTo ? format(searchClaimsTable.claimEnteredTo, "yyyy-MM-dd") : "";
            } catch {
                return;
            }

            query +=
                "?practice_pk=" +
                getStorage("practice") +
                "&claim_id=" +
                searchClaimsTable.searchByClaimId +
                "&active=" +
                searchClaimsTable.activeStatus +
                "&claim_from=" +
                claim_from +
                "&claim_to=" +
                claim_to +
                "&patient_pk=" +
                `${searchClaimsTable.patientPk ?? ""}` +
                "&dos_from=" +
                dos_from +
                "&dos_to=" +
                dos_to +
                "&page=0&export=true&export_type=" + type

        }
        service
            .GetListPatientClaims(query, type)
            .then((result) => {
                if (result.status === 200 || result.status === 201) {
                    // eslint-disable-next-line
                    const fileDownload = require("js-file-download");
                    fileDownload(result.data, result.headers["content-disposition"]);
                    showNotifyWindow("show", "success", "Export Success");
                } else if (result.status === 404 && result.message === 'no_data_available_for_export') {
                    showNotifyWindow('show', 'error', i18n.t('errorMessages.no_record_found_to_export'));
                    setShowLoadingBar(false);
                } else {
                    showNotifyWindow("show", "error", "Unexpected Error");
                }
                setShowLoadingBar(false);
            })
            .catch(() => {
                showNotifyWindow("show", "error", "Unexpected Error");
                setShowLoadingBar(false);
            });
    };


    return (
        <>
            <Notify
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            />
            <div className="col-md-12" style={{ overflowX: "hidden" }}>
                <div
                    className="basic-info-padding "
                    style={{ minHeight: "700px" }}>
                    <input
                        name="claimfile"
                        type="file"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        id="claimfile"
                        onChange={onHandleCSVChange}
                        style={{ display: "none" }}
                    />
                    <div className="box-head padding-left5">
                        <TabContext value={activeParentTab}>
                            <Box
                                sx={{
                                    borderBottom: 1,
                                    borderColor: "divider",
                                }}>
                                <StyledTabList
                                    TabIndicatorProps={{
                                        children: <CustomIndicator />,
                                    }}
                                    onChange={onTabChange}
                                    scrollable
                                    scrollButtons="auto"
                                    aria-label="lab API tabs example"
                                    variant="scrollable">
                                    {checkPermission(
                                        permission_key_values_claim.claims_search_claims_view
                                    ) && (
                                            <Tab
                                                label={i18n.t(
                                                    "searchClaims.last10"
                                                )}
                                                name={"last10"}
                                                value={"last10"}
                                                className="tabMainMenu"
                                            />
                                        )}
                                    {checkPermission(
                                        permission_key_values_claim.claims_search_tab_module_view
                                    ) && (
                                            <Tab
                                                label={i18n.t(
                                                    "searchClaims.search"
                                                )}
                                                name={"search"}
                                                value={"listClaims"}
                                                className="tabMainMenu"
                                            />
                                        )}
                                    {checkPermission(
                                        permission_key_values_claim.claims_search_claims_add
                                    ) &&
                                        tabsOpen < MAX_OPEN_TABS_CLAIMS && (
                                            <Tab
                                                label={
                                                    <span className="closeIconCommonTab">
                                                        <IconButton
                                                            name="add_new"
                                                            onClick={() => {
                                                                onTabChange(
                                                                    {
                                                                        target: {
                                                                            name: "add_new",
                                                                        },
                                                                    },
                                                                    "add_new"
                                                                );
                                                            }}
                                                            aria-label="editclaim-add-new">
                                                            <AddIcon
                                                                className=""
                                                                style={{
                                                                    fontSize:
                                                                        "2rem",
                                                                    fontWeight: 400,
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </span>
                                                }
                                                value={"add_new"}
                                                name="add_new"
                                                className="tabMainMenu"
                                            />
                                        )}
                                    {claimParentTabs &&
                                        claimParentTabs?.map((item, index) => {
                                            if (
                                                item.pk !== "search" &&
                                                item.pk !== "add_new" &&
                                                item.pk !== "last10"
                                            ) {
                                                return (
                                                    <Tab
                                                        key={index}
                                                        className="justify-normal d-inline tabMainMenu"
                                                        value={
                                                            "editClaims" +
                                                            item.pk
                                                        }
                                                        name={
                                                            "editClaims " +
                                                            item.pk +
                                                            " " +
                                                            item.claim_id
                                                        }
                                                        label={
                                                            <>
                                                                {item.name + ""}
                                                                <i
                                                                    className="fa fa-times closeIconCommonTab ml-2 pt-4"
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        closeClaimTab(
                                                                            e,
                                                                            item
                                                                        )
                                                                    }
                                                                />
                                                            </>
                                                        }
                                                    />
                                                );
                                            }
                                        })}
                                    {selectedClaimType === "professional" && (
                                        <Tab
                                            value={"editClaims"}
                                            label={
                                                <div className="justify-normal">
                                                    <span
                                                        id='add-new-close'
                                                        onClick={() =>
                                                            onTabChange(
                                                                "",
                                                                "editClaims",
                                                                "add_new",
                                                                "add_new"
                                                            )
                                                        }>
                                                        {i18n.t(
                                                            "searchClaims.claimNavBar.professional"
                                                        )}
                                                    </span>
                                                    <span
                                                        id='add-new-close'
                                                        className="closeIconCommonTab margin-left15"
                                                        onClick={(e) =>
                                                            closeClaimTab(e, {
                                                                pk: "add_new",
                                                            })
                                                        }>
                                                        <i className="fa fa-times" />
                                                    </span>
                                                </div>
                                            }
                                            className="tabMainMenu"
                                            name={"add_new"}
                                        />
                                    )}
                                </StyledTabList>

                                {(activeParentTab == "last10" ||
                                    activeParentTab == "listClaims") && (
                                        <div className="d-flex searchListIcons">
                                            {activeParentTab == "last10" && (
                                                <div
                                                    className="div-float-right mr-3 margin-export-icon"
                                                    style={{
                                                        backgroundColor: "#FFFFFF",
                                                        borderRadius: "4px",
                                                    }}>
                                                    {/* permission checking */}
                                                    {checkPermission(
                                                        permission_key_values_claim.claims_search_claims_add
                                                    ) && (
                                                            <OverlayTrigger
                                                                placement="top"
                                                                delay={{
                                                                    show: 250,
                                                                    hide: 400,
                                                                }}
                                                                overlay={
                                                                    <Tooltip
                                                                        id="download-tooltip"
                                                                        style={{
                                                                            opacity: 0.4,
                                                                        }}>
                                                                        {i18n.t(
                                                                            "commons.importCSV"
                                                                        )}
                                                                    </Tooltip>
                                                                }>
                                                                <CommonButton
                                                                    icon="download"
                                                                    variant="outlined"
                                                                    label="Import"
                                                                    id="last10-import"
                                                                    onClick={(e) =>
                                                                        onHandleCsvImport(
                                                                            e
                                                                        )
                                                                    }
                                                                />
                                                            </OverlayTrigger>
                                                        )}
                                                </div>
                                            )}
                                            <div
                                                className="dropdown margin-right50"
                                                id="export-dropdown">
                                                <div
                                                    className="margin-top2"
                                                    style={{
                                                        backgroundColor: "#FFFFFF",
                                                        borderRadius: "4px",
                                                    }}>
                                                    {checkPermission(
                                                        permission_key_values_claim.claims_search_claims_view
                                                    ) && (
                                                            <ExportIconButton
                                                                disabled={!last10List.length}
                                                                onExportCsv={(e) =>
                                                                    OnExportMenuItemClicked(
                                                                        e,
                                                                        "csv"
                                                                    )
                                                                }
                                                                onExportXls={(e) =>
                                                                    OnExportMenuItemClicked(
                                                                        e,
                                                                        "xls"
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </Box>

                            {checkPermission(
                                permission_key_values_claim.claims_search_claims_view
                            ) &&
                                activeParentTab === "last10" && (
                                    <TabPanel
                                        value={"last10"}
                                        className="bordering">
                                        {/* ************* DOWNLOAD DROPDOWN - START ************** */}
                                        {/* ************* DOWNLOAD DROPDOWN - END **************** */}
                                        <div
                                            className=""
                                            style={{ overflowY: "scroll" }}>
                                            <Table
                                                tableObject={
                                                    SearchClaimTableData
                                                }
                                                onLinkClick={openClaim}
                                                dropDownFunction={openClaim}
                                                sortingFunction={
                                                    onLast10SortClick
                                                }
                                                list={last10List}
                                            />
                                            <div className="clear-b-scroll-sector">
                                                &nbsp;
                                            </div>
                                            <div className="clear-b-scroll-sector">
                                                &nbsp;
                                            </div>
                                        </div>
                                    </TabPanel>
                                )}

                            {checkPermission(
                                permission_key_values_claim.claims_search_tab_module_view
                            ) &&
                                activeParentTab === "listClaims" && (
                                    <TabPanel
                                        value={"listClaims"}
                                        className="bordering">
                                        <SearchClaimsTableComponent />
                                    </TabPanel>
                                )}

                            {selectedClaimType && (
                                <TabPanel
                                    value={"editClaims"}
                                    className="bordering mt-0 padding-bottom60">
                                    <ProfessionalClaim
                                        activeTab={activeParentTab}
                                        claimPK={""}
                                    />
                                </TabPanel>
                            )}
                            {claimParentTabs &&
                                claimParentTabs?.map((item, index) => {
                                    if (
                                        item.pk &&
                                        item.pk !== "search" &&
                                        item.pk !== "add_new" &&
                                        item.pk !== "last10" &&
                                        activeParentTab ===
                                        "editClaims" + item.pk
                                    ) {
                                        return (
                                            <TabPanel
                                                key={index}
                                                value={"editClaims" + item.pk}
                                                className="bordering mt-0 padding-bottom60">
                                                <ProfessionalClaim
                                                    claimPK={item.pk}
                                                    claimID={item.claim_id}
                                                    activeTab={activeParentTab}
                                                    activeSubTab={
                                                        item.active_tab
                                                    }
                                                />
                                            </TabPanel>
                                        );
                                    }
                                })}
                        </TabContext>
                    </div>
                </div>
            </div>
            <CustomizedSmallDialogs
                showModal={patientAlertModal}
                header={i18n.t("commons.alert")}
                alertOK={onAlertOk}
                type="alert"
                setShowModalWindow={onAlertOk}>
                <div className="display-grid">
                    {ReactHtmlParser(alertMessage)}
                </div>
            </CustomizedSmallDialogs>
            <CustomizedSmallDialogs
                showModal={showImportErrorModalWindow}
                header={i18n.t("commons.importErrorHeader")}
                alertOK={onImportErrorOK}
                onDownload={onDownloadCSV}
                type="alert"
                setShowModalWindow={setShowImportErrorModalWindow}>
                <div className="display-grid">
                    {ReactHtmlParser(importErrorMessage)}
                </div>
            </CustomizedSmallDialogs>
        </>
    );
}

export default SearchClaimModule