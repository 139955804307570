import api from "../../../service/api";

export const getBatchLevelClaims = (practice_pk, batchId, status, page, limit, searchKey) => {
    const requiredFields = { practice_pk, batchId, page, limit };
    const missingFields = Object.entries(requiredFields)
        .filter(([, value]) => value == null)
        .map(([key]) => key);

    if (missingFields.length > 0) {
        throw new Error(`Missing required field(s): ${missingFields.join(', ')}`);
    }

    const params = new URLSearchParams({
        batch_id: batchId,
        offset: page,
        limit: limit,
        practice_pk,
        list: true,
        ...(status && { status }),
        ...(searchKey && { search: searchKey }),
    });

    return api.get(`claim/batch_process_status_list/?${params.toString()}`);
};


export const GetSelectedTabs = async (type, practice_pk) => {
    let path = 'user/user-opened-tab/?type=' + type + '&practice_pk=' + practice_pk;
    return api.get(path);
}

export const AddRemoveSelectedTab = async (data) => {
    let path = 'user/user-opened-tab/';
    return api.post(path, data);
}