import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import LoadingContext from "../../../container/loadingContext";
import { getStorage, setStorage } from "../../../utilities/browserStorage";
import i18n from "../../../utilities/i18n";
import {
  DEFAULT_PAGING_SIZE,
  PAGING_END_INDEX,
  ROUTE_ACTIVE_PAYMENT_PLAN,
} from "../../../utilities/staticConfigs";
import PatientDropDownSearch from "../patients/patientDropDownSearch";
import PatientsAdvancedSearch from "../patients/PatientsAdvancedSearch";
import RightSidePanel from "../../commons/RightSidePanel/RightSidePanel";
import { ResizableTable } from "../../commons/resizabletable/ResizableTable";
import { currencyFormat } from "../../../utilities/commonUtilities";
import PaymentService from "./service";
import Pagination from "../../commons/pagination";

function PaymentPlans() {
  const router = useHistory();
  //
  const [practicePK, setPracticePK] = useState(getStorage("practice"));
  const [patientList, setPatientList] = useState([]);
  const setShowLoadingBar = useContext(LoadingContext);

  const [patientSelected, setPatientSelected] = useState([]);
  const [patientPK, setPatientPK] = useState("");
  const [patientAdvSearchData, setPatientAdvSearchData] = useState([]);

  //Pagination start
  const [totalPage, setTotalPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);
  function onPagePrevious() {
    let previousPage = startIndex + 1 - PAGING_END_INDEX;
    setActivePage(previousPage);
    if (startIndex !== 0) {
      setStartIndex(startIndex - PAGING_END_INDEX);
      setEndIndex(endIndex - PAGING_END_INDEX);
    }
    getPatientLedgers(DEFAULT_PAGING_SIZE, previousPage);
  }

  function onPageUp(e) {
    let page = Number(e.target.id);
    setActivePage(page);
    getPatientLedgers(DEFAULT_PAGING_SIZE, page);
  }

  function onPageNext() {
    let nextPage = startIndex + 1 + PAGING_END_INDEX;
    if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
      setActivePage(nextPage);
      setStartIndex(startIndex);
      setStartIndex(endIndex);
    } else {
      setActivePage(nextPage);
      setStartIndex(startIndex + PAGING_END_INDEX);
      setEndIndex(endIndex + PAGING_END_INDEX);
    }
    getPatientLedgers(DEFAULT_PAGING_SIZE, nextPage);
  }


  const getPatientLedgers = (pageSize, page) => {
    setShowLoadingBar(true);
    PaymentService.getPatientLedgerList(practicePK, pageSize, page)
      .then((response) => {
        setShowLoadingBar(false);
        if (response.status === 200) {
          setPatientList(response.data.results);
          if (response.data.results !== undefined) {
            let dataTotalPage = Math.ceil(
              response.data.count / response.data.page_size
            );
            setTotalPage(dataTotalPage);
            if (dataTotalPage < activePage) {
              setActivePage(dataTotalPage);
            }
          }
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch(() => {
        setShowLoadingBar(false);
      });
  };

  useEffect(() => {
    setPracticePK(getStorage("practice"));
    getPatientLedgers(DEFAULT_PAGING_SIZE, activePage);
  }, []);

  const onItemLinkClick = (patientData) => {
    setStorage("patient-data", JSON.stringify(patientData));
    router.push(ROUTE_ACTIVE_PAYMENT_PLAN);
  };

  const onSearchClickHandler = () => {
    if (patientPK) {
      setShowLoadingBar(true);
      PaymentService.getPatientLedgerListById(
        practicePK,
        patientPK,
        DEFAULT_PAGING_SIZE,
        1
      )
        .then((response) => {
          setShowLoadingBar(false);
          if (response.status === 200) {
            setStorage(
              "patient-data",
              JSON.stringify(response.data.results[0])
            );
            router.push(ROUTE_ACTIVE_PAYMENT_PLAN);
          } else {
            throw new Error(response.statusText);
          }
        })
        .catch(() => {
          setShowLoadingBar(false);
        });
    }
  };
  return (
    <div className="col-md-12 ">
      <div className="box margin-right17 ">  
        <Form id="form_patient_info" autoComplete="off">
          <div className="common-search-wrapper-style">
            <PatientDropDownSearch
              inputClass={"padding-top15"}
              orPaddingTop="49px"
              setPatientPK={setPatientPK}
              patientSelected={patientSelected}
              setPatientSelected={setPatientSelected}
              patientAdvSearchData={patientAdvSearchData}
            />
            
              <div className="col margin-leftM15">
                <div className="input-content-box padding-top40">
                  <button
                    type="button"
                    className="btn btn-primary-blue"
                    onClick={onSearchClickHandler}
                  >
                    {i18n.t("patientPages.patientList.search")}
                  </button>
                </div>
              </div>
              <div className="justify-right">
                <div className="input-content-box padding-top40">
                  <RightSidePanel
                    title={i18n.t("commons.advancedSearch")}
                    onclickLabel={i18n.t("commons.advancedSearch")}
                  >
                    <PatientsAdvancedSearch
                      setPatientPK={setPatientPK}
                      setPatientSelected={setPatientSelected}
                      setPatientAdvSearchData={setPatientAdvSearchData}
                    ></PatientsAdvancedSearch>
                  </RightSidePanel>
                </div>
              </div>
          </div>
          <div className="box-content">
            <div className="padding-top10 mt-3 table-responsive">
              <ResizableTable 
                className="fixed_head table-border-radius8 "
                id={"overflow_auto"}
                tableHeadings={
                 <TableHeadings/>
                }
                tableRows={
                <TableBodyData patientList={patientList} onItemLinkClick={onItemLinkClick} />
                }
              />
            </div>
            <div className="padding-top10">
            <Pagination
              totalPage={totalPage}
              activePage={activePage}
              startIndex={startIndex}
              endIndex={endIndex}
              onPagePrevious={onPagePrevious}
              onPageUp={onPageUp}
              onPageNext={onPageNext}
            />
            </div>
            
            
          </div>
          
        </Form>
      </div>
    </div>
  );
}


/**
 * table header is rendered here
 * @returns 
 */
function TableHeadings(){
  return(
  <tr>
  <th className="longTableHead">
    {i18n.t("paymentPlan.patientName")}
    <span className="resize-handle"></span>
  </th>
  <th className="shortTableHead">
    {i18n.t("paymentPlan.patientId")}
    <span className="resize-handle"></span>
  </th>
  <th className="shortTableHead">
    {i18n.t("paymentPlan.accountBalance")}
    <span className="resize-handle"></span>
  </th>
  <th className="shortTableHead">
    {i18n.t("paymentPlan.insrnceBalance")}
    <span className="resize-handle"></span>
  </th>
  <th className="shortTableHead">
    {i18n.t("paymentPlan.patientBalance")}
    <span className="resize-handle"></span>
  </th>
</tr>
  )
}

/**
 * table body is rendered here
 * @param {*} props 
 * @returns 
 */
function TableBodyData(props){
  return props.patientList.map((item) => {
    return (
      <tr key={item.id}>
        <td style={{maxWidth:'150px',whiteSpace:'pre-line',paddingLeft:'20px'}}>
          <a 
            className="button-normal"
            href="#"
            onClick={() => props.onItemLinkClick(item)}
          >
            {item.name}
          </a>
        </td>
        <td align="center">{item.custom_patient_id}</td>
        <td align="right">
          {currencyFormat(item.account_balance)}
        </td>
        <td align="right">
          {currencyFormat(item.insurance_balance)}
        </td>
        <td align="right">
          {currencyFormat(item.patient_balance)}
        </td>
      </tr>
    );
  })
}

export default PaymentPlans;
