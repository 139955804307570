import React from 'react'; 
import loadingImage from '../../assets/images/loading.gif'

const Loading =(props) =>{
	if(props.showLoadingBar){
		return (
			<div style={{ backgroundColor: 'rgba(97, 97, 97, 0.21)', height: '100%', width: '100%', zIndex: 9999, position: 'fixed', textAlign: 'center' }}>
	        	<div style={{marginTop: '20%'}}><img style={{ height:'100px' }} src={loadingImage} alt=""/>
					<p style={{ color: '#1074B9', fontSize: '22px', zIndex: '9999' }}>Loading...</p>
				</div>
			</div>
		)
	}else{
		return (<div></div>)
	}
	
}
export default Loading