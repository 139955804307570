import React, { memo } from "react";
import { useHistory } from 'react-router-dom';
import ReactDOM from "react-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Typography } from "@mui/material";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="outlined" {...props} />;
});

/**
 * @param {
 * showNotify // 'show' || 'hide'
 * setShowNotify // function to hide Notify
 * notifyPrimaryText // Primary text to display
 * notifyDescription // Sub text to display
 * notifyType // 'error' || 'success'
 * notifyRedirectLink // path to route user on click, this will be rendered along with the sub text, for currently use in reports
 * } 
 * @returns 
 */
const Notify = memo(function Notify(props) {
    const history = useHistory();
    function handleCloseToast() {
        setTimeout(() => {
            props.setShowNotify('hide');
            if (props) {
                props.setNotifyDescription("");
                if (props.setNotifyPrimaryText) {
                    props.setNotifyPrimaryText("");
                }
            }
        }, 2000);
    }

    function handleRedirectToReport() {
        history.push(props.notifyRedirectLink);
        setTimeout(() => props.setShowNotify('hide'), 2000)
    }

    return ReactDOM.createPortal(
            <Snackbar
                open={props.showNotify === "show"}
                autoHideDuration={7000}
                onClose={()=>handleCloseToast()}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                style={{
                    backgroundColor:
                        props?.notifyType === "success" ? "#E3F7FF" : "#FFECEC",
                    height: "auto !important",
                    width: "596px",
                    borderRadius: "8px",
                    boxShadow: "none",
                }}>
                <Alert
                    severity=""
                    sx={{
                        width: "100%",
                        boxShadow: "none",
                    }}
                    style={{
                        backgroundColor:
                            props?.notifyType === "success"
                                ? "#E3F7FF"
                                : "#FFECEC",
                        height: "auto",
                        width: "596px",
                        borderRadius: "8px",
                        boxShadow: "none",
                    }}>
                    <div style={{ marginTop: "15px" }}>
                        {props?.notifyType === "success" ? (
                            <ThumbUpOffAltIcon
                                style={{
                                    position: "absolute",
                                    height: "36px",
                                    width: "36px",
                                    color: "#25B40D",
                                }}
                            />
                        ) : (
                            <HighlightOffRoundedIcon
                                style={{
                                    position: "absolute",
                                    height: "24px",
                                    width: "24px",
                                    color: "#DF3C3C",
                                    marginTop: "-5px",
                                }}
                            />
                        )}
                        <div
                            style={{
                                marginTop:
                                    props.notifyPrimaryText == undefined &&
                                    props.notifyType === "success"
                                        ? "20px"
                                        : "0px",
                            }}>
                            <Typography
                                variant="h5"
                                style={{
                                    marginLeft:
                                        props.notifyType === "success"
                                            ? "50px"
                                            : "35px",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    lineHeight: "16.94px",

                                    color: "#212121",
                                }}>
                                {props.notifyPrimaryText ?? (props.notifyType === 'success' ? 'Success' :  'Error') }
                            </Typography>
                        </div>

                        <Typography
                            variant="body1"
                            style={{
                                marginLeft:
                                    props.notifyType === "success"
                                        ? "50px"
                                        : "35px",
                                fontSize: "14px",
                                marginTop: "14px",
                                color: "#909597",
                            }}>
                            {props.notifyDescription}
                            {props.notifyRedirectLink && (
                                <>&nbsp;<span onClick={()=>handleRedirectToReport()} style={{ cursor: 'pointer', color: 'blue' }}>Go to report.</span></>
                            )}
                        </Typography>
                    </div>
                </Alert>
            </Snackbar>,
            document.body 
    );
});

export default Notify;
