import React, { useState, useEffect, useContext } from 'react';
import { Form } from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs'
import service from './service';
import i18n from '../../../utilities/i18n';
import { ROUTE_SUPER_DICTIONARIES, MODIFIERS_CSV_NAME, PAGING_END_INDEX, DEFAULT_PAGING_SIZE } from '../../../utilities/staticConfigs';
import { ADD_SUCCESS, ADD_ERROR, UPDATE_SUCCESS, DELETE_SUCCESS } from '../../../utilities/labelConfigs';
import Notify from '../../commons/notify';
import TextInput from '../../commons/input/input';
import { csvFileCheck } from '../../../utilities/validations';
import Pagination from '../../commons/pagination';
import LoadingContext from '../../../container/loadingContext'
import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';
import Table from '../../commons/Table/Table';
import { ModifierTableData } from './ModifierTable';
import { checkPermission, commonTableBody } from '../../../utilities/commonUtilities';
import BackArrowWithLabel from "../../commons/Back"; 
import CommonButton from "../../commons/Buttons";

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { super_admin_permission_key_value, super_admin_privileges } from '../../../utilities/permissions';

const ModifiersList = (props) => {

    const [arrModifiersList, setModifiersList] = useState([]);

    const [showModalWindow, setShowModalWindow] = useState(false);
    const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
    const [modalHeader, setModalHeader] = useState(i18n.t('dictionariesPages.modifiersList.addHeader'));
    const [modifier, setModifier] = useState('');
    const [modifierDescr, setModifierDescr] = useState('');
    const [deleteModifier, setDeleteModifier] = useState('');
    const [deleteModifierId, setDeleteModifierId] = useState('');
    const [editModifierId, setEditModifierId] = useState('');
    const [form_error, setFormError] = useState({ 'modifier': '', 'modifierDescr': '' });
    const [editForm, setEditForm] = useState(false);
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const setShowLoadingBar = useContext(LoadingContext);
    const [searchValueClose, setSearchValueClose] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [orderType, setOrderType] = useState('');
    const [orderingField, setOrderingField] = useState('name'); /* show the triangle only in clicked header */
    const [initialOrdering, setInitialOrdering] = useState(true);
    const [permission, setPermission] = useState(false);

  
    ModifierTableData.tableHeadings[0].initialOrdering  = initialOrdering;
    ModifierTableData.tableHeadings[0].orderType = orderType;


    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);
    function onPagePrevious() {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        setActivePage(previousPage);

        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        getModifierData(DEFAULT_PAGING_SIZE, previousPage, orderingField);
    }

    function onPageUp(e) {
        let page = Number(e.target.id)
        setActivePage(page);
        getModifierData(DEFAULT_PAGING_SIZE, page, orderingField);
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);

        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);

        }
        getModifierData(DEFAULT_PAGING_SIZE, nextPage, orderingField);

    }
    //Pagination ends

    //start ==== Alert message properties
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    function addNew() {
        if (!permission) {
			showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'));
			return;
		}
        resetForm();
        setShowModalWindow(true);
    }

    function onHide() {
        setEditForm(false);
    }
    function onDeleteHide() {
        setDeleteModifier('');
        setDeleteModifierId('');
        setShowDeleteModalWindow(false);
    }

    function getModifierData(pageSize, page, fieldName, sorting) {
        let fieldOrderType = '';
        fieldOrderType=sorting ?  !orderType ? '-' : fieldOrderType : orderType;
        setShowLoadingBar(true);
        const result = service.GetModifiersList(pageSize, page, searchValue, fieldName, fieldOrderType);
        result.then(response => {
            if (response.data.results !== undefined) {
                setTotalPage(Math.ceil(response.data.count / response.data.page_size));
            }
            const rowArray = commonTableBody(response.data.results, ModifierTableData.tableBodyData[0]);
            ModifierTableData.tableBodyData = rowArray;
            setModifiersList(response.data.results);
            setShowLoadingBar(false);
        });
    }


    useEffect(() => {
        getModifierData(DEFAULT_PAGING_SIZE, activePage);
        setPermission(checkPermission(super_admin_privileges.super_admin_full_privilege,super_admin_permission_key_value.dictionary_add));
    }, [searchValueClose]);

    function onEditModifier(ModifierId) {
        const data = service.GetModifier(ModifierId);
        data.then(response => {
            setEditModifierId(ModifierId);
            setModalHeader(i18n.t('dictionariesPages.modifiersList.editHeader'));
            setEditForm(true);
            setShowModalWindow(true);
            setModifier(response.data.name);
            setModifierDescr(response.data.description);
        });
    }

    function onDeleteModifier(ModifierId) {
        arrModifiersList.map((item) => {
            if (item.id == parseInt(ModifierId)) {
                setDeleteModifier(item.name);
                setDeleteModifierId(item.id);
                setShowDeleteModalWindow(true);
            }
        });
    }

    function onDeleteAction() {
        const data = service.DeleteModifier(deleteModifierId);
        data.then(() => {
            setDeleteModifier('');
            setDeleteModifierId('');
            setShowDeleteModalWindow(false);
            showNotifyWindow('show', 'success', DELETE_SUCCESS);
            getModifierData(DEFAULT_PAGING_SIZE, activePage);
        });
    }

    function resetForm() {
        setModifier('');
        setModifierDescr('');
        setEditForm(false);
        setFormError({
            ...form_error,
            ['modifier']: '',
            ['modifierDescr']: ''
        });
        setSearchValueClose(0)
    }
   
    function onSaveFormData(e) {
        e.preventDefault();
      
        const hasEmptyFields = !modifier.trim() || !modifierDescr;
      
        if (hasEmptyFields) {
          const errorField = !modifier.trim() ? 'modifier' : 'modifierDescr';
          setFormError({
            ...form_error,
            [errorField]: 'error'
          });
          return;
        }
      
        const data = { name: modifier, description: modifierDescr };
        const result = editForm ? service.UpdateModifier(editModifierId,data) : service.AddModifier(data);
      
        result.then(response => {
            if (response.status === 201 || response.status === 200) {
              const successMessage = response.status === 201 ? ADD_SUCCESS : UPDATE_SUCCESS;
              showNotifyWindow('show', 'success', successMessage);
              getModifierData(DEFAULT_PAGING_SIZE, activePage);
              resetForm();
              setShowModalWindow(false);
            } else if (response.status === 400 && response.data.record_already_exists !== undefined) {
              showNotifyWindow('show', 'error', i18n.t('errorMessages.record_exists'));
            } else {
              showNotifyWindow('show', 'error', ADD_ERROR);
            }
          });
      }
      

    function onHandleChange(e) {
        const name = e.target.name;
        const value = e.target.value;
        let trimmedValue = '';

        if (name === "modifier") {
            if (e.target.validity.valid)
                trimmedValue = value;
            else {
                trimmedValue = modifier;
            }
        } else {
            trimmedValue = value;
        }

        if (!trimmedValue.trim() && name === "modifier") {
            setFormError({
                ...form_error,
                [name]: 'error'
            });
        } else {
            setFormError({
                ...form_error,
                [name]: ''
            });
        }

        if (name === 'modifier') setModifier(trimmedValue);
        else if (name === 'searchValue') setSearchValue(trimmedValue);
        else setModifierDescr(trimmedValue);
    }


    function onHandleCsvImport(e) {
        e.preventDefault();
        document.getElementById('file').click();
    }

    function onHandleCSVChange(e) {
        const { type, files } = e.target;
      
        if (type === "file") {
          const file = files[0];
          if (file.name !== MODIFIERS_CSV_NAME) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.invalid_file_name'));
            return;
          }
          if (!csvFileCheck(file.name)) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.invalid_file_format'));
            return;
          }
      
          setShowLoadingBar(true);
          const data = new FormData();
          data.append('file', file);
          service.ImportCSVData(data)
            .then(response => {
              setShowLoadingBar(false);
              if (response.data.message === 'no_file_records') {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.empty_file'));
              } else if (response.data.message === 'invalid_headings') {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.invalid_headings'));
              } else if (response.data.message === 'data_imported') {
                const { existing_name } = response.data;
                const successMessage = existing_name && existing_name.length > 0
                  ? `${i18n.t('errorMessages.record_not_imported')}\n${existing_name.join(',\n')}`
                  : i18n.t('validations.success.import_success');
                showNotifyWindow('show', 'success', successMessage);
                getModifierData(DEFAULT_PAGING_SIZE, activePage);
              } else {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.import_failed'));
              }
            });
        } else {
          // handle non-file input changes
        }
      }
      

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onSearchModifiers(e);
        }
    }

    function onSearchModifiers(e, fieldName) {
        e.preventDefault()
        setActivePage(1)
        setStartIndex(0);
        setEndIndex(PAGING_END_INDEX);
        if (!fieldName) { // if not field name then call from ,search button clic
            setInitialOrdering(true);
            setOrderType('');
        }
        setTotalPage(0);
        getModifierData(DEFAULT_PAGING_SIZE, 1 , fieldName, true);
    }

    function onSortingModifiers(e, fieldName) {
        e.preventDefault();
        setStartIndex(0);
        setEndIndex(PAGING_END_INDEX);
        if (!fieldName) { // if not field name then call from ,search button clic
            setInitialOrdering(true);
            setOrderType('');
        }
        setTotalPage(0);
        getModifierData(DEFAULT_PAGING_SIZE, activePage , fieldName, true);
    }

    const tableSorting = (e) => {
        setInitialOrdering(false);
        ModifierTableData.tableHeadings[0].initialOrdering = false;
        let name = 'name'
        setOrderingField(name);
        if (!orderType) {
            ModifierTableData.tableHeadings[0].orderType = '-';
            setOrderType('-');
        }
        else {
            ModifierTableData.tableHeadings[0].orderType = '';
            setOrderType('');
        }
        onSortingModifiers(e, name);
    }

    function dropDownFunction(id, name) {
        if (!permission) {
			showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'));
			return;
		}
        if (name.toLowerCase() == 'edit') {
            onEditModifier(Number(id));
        }
        if (name.toLowerCase() == 'delete') {
            onDeleteModifier(Number(id));
        }
    }

    function backToDictionaryPage() {
		props.history.push(ROUTE_SUPER_DICTIONARIES)
	}

    return (
        <React.Fragment>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />

            <div className="col-md-12">
                <div className="box box-content-white">
                    <div className="row div-backto-dictionaties">
                        <div className="link dictionaries-back pl-0" onClick={backToDictionaryPage} >
                            <BackArrowWithLabel label={i18n.t("dictionariesPages.dictionaries")} /> 
                        </div>
                    </div>

                    <div className="box-content">
                        <div className="common-search-wrapper-style">
                            <div className="input-content-box">
                                <div className="form-group padding-top15">
                                    <TextInput type="text" 
                                    name="searchValue" 
                                    value={searchValue}
                                     onValueChange={onHandleChange} 
                                     label={i18n.t("dictionariesPages.modifiersList.modifierOrDescription")}
                                      onKeyDown={handleKeyDown} />
                                     {(searchValue.length !== 0) && <IconButton
                    className="templateIconBtn_close"
                    onClick={() => {
                      setSearchValue('');
                      setActivePage(1);
                      setSearchValueClose(searchValueClose + 1)
                    }} >
                    <CloseIcon sx={{ fontSize: "small", color: '#9093a4' }} />
                  </IconButton>
                  }
                                </div>
                            </div>
                            <div className="padding-top25">
                                <div className="input-content-box padding-top15">
                                    <button type="button" onClick={(e) => onSearchModifiers(e)} className="btn btn-primary-blue">{i18n.t("dictionariesPages.modifiersList.search")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box box-content-white margin-top10">
                    <div className="width-100-perc">
                        <div className="alignRight ">
                            <div className="alignRight padding-top5 margin-bottom5 width-100-perc ">
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="download-tooltip" style={{
                                        opacity: 0.4,
                                    }}>{i18n.t('commons.importCSV')}
                                    </Tooltip>}
                                >
                                    <CommonButton icon="download" variant="outlined" label="Import" onClick={(e) => onHandleCsvImport(e)}  />
                                </OverlayTrigger>
                                <input name="csvFile" type="file" accept=".csv" id="file" data-testid='csv-upload-input' onChange={onHandleCSVChange} style={{ display: "none" }} />
                                <span className='ml-3'><CommonButton variant='contained' onClick={addNew} label={i18n.t("buttons.addNew")}/></span>
                            </div>
                        </div>
                    </div>

                    <div className="">          
                        <div className="padding-top5  margin-bottom10" style={{ overflowX: "auto", width: "100%"}} >
                            <Table tableObject={ModifierTableData} dropDownFunction={dropDownFunction} sortingFunction={tableSorting} />
                        </div>    
                        <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                        <div className="clear-b-scroll-sector">&nbsp;</div>
                        <div className="clear-b-scroll-sector">&nbsp;</div>
                    </div>  
                </div>
            </div>
            <CustomizedDialogs showModal={showModalWindow} type="save" header={modalHeader} setShowModalWindow={setShowModalWindow} resetForm={resetForm} onHide={onHide}>
                <Form id="form_dataEntry" onSubmit={(e) => onSaveFormData(e)} encType="multipart/form-data">
                    <Form.Group>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group padding-top15">
                                    <TextInput type="text" id="modifier" name="modifier" required={true} label={i18n.t('dictionariesPages.modifiersList.lblModifier')} onValueChange={onHandleChange} className={form_error.modifier ? 'input-error' : ""} value={modifier} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor='desc-text-area'>{i18n.t('dictionariesPages.modifiersList.lblDescription')}</label>
                                    <div className={'text-input'}>
                                        <textarea id='desc-text-area' placeholder={i18n.t('dictionariesPages.modifiersList.lblDescription')} name="modifierDescr" required={true} className={!modifierDescr ? 'textarea-100 input-error' : "textarea-100"} value={modifierDescr} onChange={(e) => onHandleChange(e)}></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </CustomizedDialogs>
            <CustomizedSmallDialogs showModal={showDeleteModalWindow} header={i18n.t('commons.confirmDelete')} type="delete" deleteItem={onDeleteAction} resetForm={resetForm} onHide={onDeleteHide} setShowModalWindow={setShowDeleteModalWindow}>
                {i18n.t('dictionariesPages.modifiersList.deletConfirmation') + " '" + deleteModifier + "'?"}
            </CustomizedSmallDialogs>
        </React.Fragment>

    )
}

export default ModifiersList;