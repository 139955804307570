import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#24A7E0', // blue primary
            light: '#1479BB', // blue light
            dark: '#1C3664', // blue dark shade
        },
        secondary: {
            main: '#757575', // labels - grey
            dark: '#616161', // background - grey
            light: '#424242',
            darklight: '#FFFFFF'
        },
        error: {
            main: '#f24949',
            light: '#FF6F6F',
        },
        success: {
            main: '#2e844a',
            light: '#52ab6f'
        },
      
    },
    typography: {
       
        fontFamily: [
            'Lato',
            '-apple-system',
            'BlinkMacSystemFont',
            'Segoe UI',
            'Arial',
            'sans-serif',
            'Apple Color Emoji',
            'Segoe UI Emoji',
            'Segoe UI Symbol',
        ].join(','),
    },
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize:'16px !important',
                    fontWeight:"500 !important",
                    fontFamily:'Lato',
                    '&.Mui-selected': {
                        color: '#1479BB',
                    },
                    '&:not(.Mui-selected)': {
                        color: 'var(--grey-700, #616161)'
                    }
                },
               
            }
        }
    }
});

export default theme;