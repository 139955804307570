import React, { useContext, useEffect } from "react";
import i18n from "../../../utilities/i18n";
import { useSelector } from "react-redux";
import { checkPermission, CommonDateFormat, currencyFormat } from "../../../utilities/commonUtilities";
import { Form, OverlayTrigger, Popover } from "react-bootstrap";
import { Divider, FormHelperText, Typography } from "@mui/material";
import { PAYEMNT_MODES_CREDITCARD_ID } from "../../../utilities/staticConfigs";
import { CREDIT_CARD_TYPES, INSURANCEPAYMENTMODES } from "../../../utilities/dictionaryConstants";
import { permission_key_values_payments } from "../../../utilities/permissions";
import CommonButton from "../../commons/Buttons";
import { useDispatch } from "react-redux";
import { getPaymentDetails, handleOnEditPayment, searchPayerList } from "./StateManagement/asyncThunkAPI";
import LoadingContext from "../../../container/loadingContext";
import TextAreaInput from "../../commons/textarea/textarea";
import TextInput from "../../commons/input/input";
import CustomizedDialogs from "../../modalWindowComponent/CustomizedDialogs";
import { hideModalWindow, onHandleEditPayment, onHandleEditPaymentDate, showModalWindow, updatePayerDetails } from "./StateManagement/paymentSlice";
import AsyncTypeInput from "../../commons/input/AsyncTypeHead/AsyncTypeInput";
import CalendarInput from "../../commons/input/CalendarInput";
import SelectInput from "../../commons/input/SelectInput";
import { MaterialMultiselect } from "../../../MaterialMultiselect/MaterialMultiselect";
const InsurancePaymentDetails = () => {
  const dispatch = useDispatch();
  const setShowLoadingBar = useContext(LoadingContext);
  let insurancePaymentData = useSelector((state) => state.paymentData.insurancePaymentData);
  let { paymentPK } = useSelector((state) => state.paymentData.postPaymentDetails);
  let { adjustmentTypes, getPaymentDetailsLoading, isEditButtondisable, savedProcedureLoading, editPaymentVariables,
    header, showEditPaymentModal, payersList,selectedPayer
  } = useSelector((state) => state.paymentData);
  let { payment_mode } = useSelector((state) => state.paymentData.editPaymentVariables);

  /**
   * loader invokes when initial data loading happens
   */
  useEffect(() => {
    setShowLoadingBar(getPaymentDetailsLoading);
  }, [getPaymentDetailsLoading])

  /**
  * loader invokes when saved procdure api data loading happens
  */
  useEffect(() => {
    setShowLoadingBar(savedProcedureLoading);
  }, [savedProcedureLoading])

  const resetEditPaymentDetails = () => {
    dispatch(hideModalWindow({ key: "showEditPaymentModal", open: false }));
    dispatch(getPaymentDetails({pk:paymentPK,action:"edit"}));
  }

  const onHandleChangeEditPayment = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (["level_adjustments", "amount"].includes(name)) {
      value = String(e.target.value)
        .trim()
        .replace(/[^\d.-]/g, "") // Allow digits, hyphen, and period
        .replace(/(?<=\d)-/g, "") // Remove hyphen if not at the start
        .replace(/(\.\d{0,1}\d{0,1})\d*/, "$1") // Limit decimal part to two digits or less
        .substring(0, 9);
    }

    dispatch(onHandleEditPayment({value:value,name:name}))
  }


  const onHandleChangeEditPaymentDate=(value, name)=>{
    dispatch(onHandleEditPaymentDate({value:value,name:name}));
  }
  

  /**
    * funtion inovke while user search a perticular payer from the search list is clicked
    * @param {*} e  
    */
  const handleSearchPayer = (e) => {
    dispatch(updatePayerDetails({e:e,action:"edit"}))
  }

  /**
   *funtion inovke while user search a perticular payer details 
   */
  const onSearchPayer = (searchValue) => {
    dispatch(searchPayerList(searchValue))
  }

  const onSaveFormData=(e)=>{
    e.preventDefault();
    dispatch(handleOnEditPayment(paymentPK));
  }



  return (
    <>
      <div style={{ marginLeft: 20, marginRight: 20 }}>
        <table className='postPaymentTable'>
          <tr>
            <td>
              <span className="paymentFormText">
                {i18n.t(
                  "payments.post_payments.insurancePayment.payment_id"
                )}
                :
              </span>
            </td>
            <td>
              <span className="paymentFormValueText">
                {insurancePaymentData.custom_payment_id}
              </span>
            </td>
            <td className="paymentFormText">
              {i18n.t(
                "payments.post_payments.insurancePayment.payment_from"
              )}
              :
            </td>
            <td>
              <span className="paymentFormValueText">
                {insurancePaymentData.payer_name}
              </span>
            </td>
            <td>
              <span className="paymentFormText">
                {i18n.t(
                  "payments.post_payments.insurancePayment.payment_mode"
                )}
                :
              </span>
            </td>
            <td >
              <span className="paymentFormValueText">
                {insurancePaymentData.payment_mode_name}
              </span>
            </td>
            <td style={{ textAlign: 'end' }}>
              <span className="paymentFormText">
                {i18n.t(
                  "payments.post_payments.insurancePayment.payment_reference"
                )}
                :
              </span>
            </td>
            <td style={{ textAlign: 'end' }}>
              <span className="paymentFormValueText">
                {insurancePaymentData.reference_check_number}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span className="paymentFormText">
                {i18n.t(
                  "payments.post_payments.insurancePayment.payment_date"
                )}
                :
              </span>
            </td>
            <td>
              <span className="paymentFormValueText">
                {insurancePaymentData.payment_date
                  ?
                  CommonDateFormat(insurancePaymentData.payment_date)
                  : ""}
              </span>
            </td>
            <td>
              <span className="paymentFormText">
                {i18n.t(
                  "payments.post_payments.insurancePayment.payment_amount"
                )}
                :
              </span>
            </td>
            <td>
              <span className="paymentFormValueText">
                {currencyFormat(insurancePaymentData.amount)}
              </span>
            </td>
            <td>
              <span className="paymentFormText">
                {i18n.t(
                  "payments.post_payments.insurancePayment.applied"
                )}
                :
              </span>
            </td>
            <td>
              <span className="paymentFormValueText">
                {currencyFormat(Number(insurancePaymentData.appliedAmount) ? Number(insurancePaymentData.appliedAmount) : 0.00)}
              </span>
            </td>
            <td style={{ textAlign: 'end' }}>
              <span className="paymentFormText">
                {i18n.t(
                  "payments.post_payments.insurancePayment.unapplied"
                )}{" "}
                :
              </span>
            </td>
            <td style={{ textAlign: 'end' }}>
              <span className="paymentFormValueText">
                {currencyFormat(Number(insurancePaymentData.unAppliedAmount) ? Number(insurancePaymentData.unAppliedAmount) : 0.00)}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span className="paymentFormText">
                {i18n.t(
                  "payments.post_payments.insurancePayment.payment_level_adjustment"
                )}
                :
              </span>
            </td>
            <td>
              <span className="paymentFormValueText">
                {currencyFormat(insurancePaymentData.level_adjustments)}
              </span>
            </td>
            <td>
              <span className="paymentFormText">
                {i18n.t("payments.post_payments.insurancePayment.adjustment_type")}:
              </span>
            </td>
            <td>
              <span className="paymentFormValueText">
                {insurancePaymentData.adjustment_type?.length === 1 ? (
                  adjustmentTypes.find(item => item.id === insurancePaymentData.adjustment_type[0])?.name
                ) : insurancePaymentData.adjustment_type?.length > 1 ? (
                  <>
                    <OverlayTrigger
                      placement={"right"}
                      delay={{ show: 250 }}
                      overlay={
                        <Popover id="popover-trigger-hover-focus">
                          <div style={{ padding: '10px', maxWidth: '300px', minWidth: '200px' }}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>
                              Adjustment Types
                            </Typography>
                            <Divider sx={{ margin: '8px 0' }} />
                            <div style={{ padding: '5px', textAlign: 'left' }}>
                              {insurancePaymentData.adjustment_type.map((item, index) => (
                                <div key={index}>
                                  <strong>{adjustmentTypes.find(adjustment => adjustment.id === item)?.name}</strong>
                                  <Divider sx={{ margin: '4px 0' }} />
                                </div>
                              ))}
                            </div>
                          </div>
                        </Popover>
                      }
                    >
                      <div id='adjustment-types' style={{ display: 'inline-block' }}>
                        {adjustmentTypes.find(item => item.id === insurancePaymentData.adjustment_type[0])?.name.length > 40
                          ? `${adjustmentTypes.find(item => item.id === insurancePaymentData.adjustment_type[0])?.name.substring(0, 37)}...`
                          : adjustmentTypes.find(item => item.id === insurancePaymentData.adjustment_type[0])?.name}{' '}
                        <a className="pl-2 link-text-color">+{insurancePaymentData.adjustment_type.length - 1} more...</a>
                      </div>
                    </OverlayTrigger>
                  </>
                ) : ""}
              </span>
            </td>
            {payment_mode === PAYEMNT_MODES_CREDITCARD_ID && (
              <>
                <td>
                  <span className="paymentFormText">
                    {i18n.t("payments.post_payments.credit_card")} :
                  </span>
                </td>
                <td>
                  <span className="paymentFormValueText">
                    {CREDIT_CARD_TYPES.map((item) => item.id === insurancePaymentData.card_type ? item.name : <></>)}
                  </span>
                </td>
              </>
            )}
          </tr>
          <tr>
            <td></td><td></td><td></td><td></td><td></td><td></td><td></td>
            <td style={{ textAlign: 'end' }}>
              {checkPermission(permission_key_values_payments.payments_view_payments_modify,
                permission_key_values_payments.post_payments
              ) &&
                <CommonButton
                  disabled={isEditButtondisable}
                  variant="contained"
                  onClick={() => dispatch(showModalWindow({ key: "showEditPaymentModal", open: true, header: i18n.t("payments.post_payments.editPayment") }))}
                  label={i18n.t("commons.edit")}
                />}
            </td>
          </tr>
        </table>
        <Divider style={{ marginLeft: -20, marginRight: -20, marginTop: 20 }} />
      </div>
      <div className="">
        <CustomizedDialogs
          header={header}
          type="save"
          showModal={showEditPaymentModal}
          setShowModalWindow={() => dispatch(hideModalWindow({ key: "showEditPaymentModal", open: false }))}
          resetForm={() => resetEditPaymentDetails()}
        >
          <Form
            id="form_dataEntry"
            autoComplete="off"
            onSubmit={(e) => onSaveFormData(e)}
            encType="multipart/form-data"
          >
            <div className="">
              <div className="">
                <div className="row">
                  <div className="col-md-6">
                    <TextInput
                      type="text"
                      disabled={true}
                      name="custom_payment_id"
                      value={editPaymentVariables.custom_payment_id}
                      onValueChange={(e) => onHandleChangeEditPayment(e)}
                      label={i18n.t(
                        "payments.post_payments.editPaymentModal.payment_id"
                      )}
                    />
                  </div>
                  <div className="col-md-6">
                    <AsyncTypeInput
                      labelKey="name"
                      name="payer"
                      class_name={editPaymentVariables.payer == "" ? "input-error" : ""}
                      minLength={1}
                      placeholder={""}
                      onValueChange={(e) => handleSearchPayer(e, "payer")}
                      options={payersList}
                      onSearch={onSearchPayer}
                      id={"payer"}
                      label={i18n.t(
                        "payments.post_payments.insurancePayment.payer"
                      )}
                      clearButton={true}
                      selected={selectedPayer}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <CalendarInput
                      name="payment_date"
                      required={true}
                      selected={editPaymentVariables.payment_date}
                      onValueChange={(e) =>
                        onHandleChangeEditPaymentDate(e, "payment_date")
                      }
                      label={i18n.t(
                        "payments.post_payments.editPaymentModal.payment_date"
                      )}
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md">
                        <SelectInput
                          data={INSURANCEPAYMENTMODES}
                          name="payment_mode"
                          required={true}
                          value={editPaymentVariables.payment_mode}
                          onValueChange={(e)=>onHandleChangeEditPayment(e)}
                          label={i18n.t(
                            "payments.post_payments.editPaymentModal.payment_mode"
                          )}
                        />
                      </div>
                      {editPaymentVariables.payment_mode ===
                        PAYEMNT_MODES_CREDITCARD_ID && (
                          <div className="col-md pl-0">
                            <SelectInput
                              name="card_type"
                              data={CREDIT_CARD_TYPES}
                              value={editPaymentVariables.card_type}
                              onValueChange={(e)=>onHandleChangeEditPayment(e)}
                              label={i18n.t(
                                "payments.post_payments.credit_card"
                              )}
                              showCreditCardIcons={true}
                              className={
                                editPaymentVariables.card_type
                                  ? ""
                                  : "input-error"
                              }
                              required={true}
                            />
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <TextInput
                      type="text"
                      name="reference_check_number"
                      required={true}
                      value={
                        editPaymentVariables.reference_check_number
                      }
                      onValueChange={(e)=>onHandleChangeEditPayment(e)}
                      label={i18n.t(
                        "payments.post_payments.editPaymentModal.payment_reference"
                      )}
                    />
                  </div>

                  <div className="col-md-6">
                    <TextInput
                      type="number"
                      name="amount"
                      required={true}
                      value={editPaymentVariables.amount}
                      onValueChange={(e)=>onHandleChangeEditPayment(e)}
                      label={i18n.t(
                        "payments.post_payments.editPaymentModal.payment_amount"
                      )}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <TextInput
                      type="number"
                      name="level_adjustments"
                      value={editPaymentVariables.level_adjustments}
                      onValueChange={(e)=>onHandleChangeEditPayment(e)}
                      label={i18n.t(
                        "payments.post_payments.insurancePayment.payment_level_adjustments"
                      )}
                    />
                  </div>
                  <div className="col-md-6">
                    <MaterialMultiselect
                      name="adjustment_type"
                      value={editPaymentVariables.adjustment_type}
                      required={editPaymentVariables.level_adjustments == 0 ? false : true}
                      onValueChange={(e)=>onHandleChangeEditPayment(e)}
                      options={adjustmentTypes}
                      label={i18n.t("payments.post_payments.insurancePayment.adjustment_type")}
                    />
                    {/* Display FormHelperText for error */}
                    {
                      editPaymentVariables.level_adjustments <= 0 && editPaymentVariables.adjustment_type?.length >= 1 && (
                        <FormHelperText error>
                          {i18n.t(
                            "payments.post_payments.insurancePayment.paymenMessages.paymentAdjstmentVsAdjustmentType"
                          )}
                        </FormHelperText>
                      )
                    }
                  </div >
                </div >
                <div className="row">
                  <div className="col">
                    <TextAreaInput
                      name="note"
                      value={editPaymentVariables.note}
                      onValueChange={(e)=>onHandleChangeEditPayment(e)}
                      rows={3}
                      label={i18n.t(
                        "payments.post_payments.editPaymentModal.payment_note"
                      )}
                    />
                  </div>
                </div>
              </div >
            </div >
          </Form >
        </CustomizedDialogs >
      </div >
    </>
  )
}

export default InsurancePaymentDetails;