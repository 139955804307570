import React, { useState, useEffect, useContext } from "react";
import { useDispatch ,useSelector} from "react-redux";
import { Form } from "react-bootstrap";
import { format } from "date-fns";
import ReactHtmlParser from "react-html-parser";

import "./style.css";
import service from "./service";
import PatientsToAddFamily from "./PatientsToAddFamily";
import PatientPastCoverage from "./PatientPastCoverage";
import TextInput from "../../commons/input/input";
import Notify from "../../commons/notify";


import i18n from "../../../utilities/i18n";
import { getStorage } from "../../../utilities/browserStorage";
import { imageExtension } from "../../../utilities/validations";
import {
  ADD_SUCCESS,
  ADD_ERROR,
  UPDATE_SUCCESS,
  DELETE_SUCCESS,
} from "../../../utilities/labelConfigs";
import {
  DEFAULT_COUNTRY,
  DEFAULT_PAGING_SIZE,
  INSURANCE_ACTIVE,
  INSURANCE_EXPIRED,
} from "../../../utilities/staticConfigs";
import {
  GENDERS,
  PRIORITIES,
  AUTHORIZATION_REFERRAL,
} from "../../../utilities/dictionaryConstants";

import LoadingContext from "../../../container/loadingContext";

import ImageCrop from "../../commons/image-cropper/ImageCrop";
import CalendarInput from "../../commons/input/CalendarInput";
import SelectInput from "../../commons/input/SelectInput";
import GeoCodeSearch from "../../commons/helpers/geoCodeApiSearch";
import PhoneInput from "../../commons/PhoneInput/PhoneInput";
import RightSidePanelWithWidth from "../../commons/RightSidePanelWithWidth/RightSidePanelWithWidth";
import { CityInput } from "../../commons/input/CityInput";
import CustomizedDialogs from "../../modalWindowComponent/CustomizedDialogs";
import CustomizedSmallDialogs from "../../modalWindowComponent/CustomisedSmallDialog";
import AsyncTypeInput from "../../commons/input/AsyncTypeHead/AsyncTypeInput";
import { InsuranceDataTable } from "./patientBillingInfoTable";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { CommonDateFormat, checkPermission } from "../../../utilities/commonUtilities";
import { permission_key_values_patient } from "../../../utilities/permissions";
import { PatientMangeAuthorInfoTable } from "./PatientMangeAuthorInfoTable";
import CommonButton from "../../commons/Buttons";
import CurrencyInputs from "../../commons/input/CurrencyInput";
import { Chip } from "@mui/material";

const PatientInsuranceInfo = (props) => {
  const dispatch = useDispatch();
  const practicePK = getStorage("practice")
    ? parseInt(getStorage("practice"))
    : "";
  const [header, setHeader] = useState("");
  const activePage = 1;
  const [showAddInsuranceModalWindow, setShowAddInsuranceModalWindow] =
    useState(false);
  const [patientPK, setPatientPK] = useState(props.patientPK);
  const [selectedInsurance, setSelectedInsurance] = useState([])
  const [primaryInsurance, setPrimaryInsurance] = useState([]);
  const [secondaryInsurance, setSecondaryInsurance] = useState([]);
  const [tertiaryInsurance, setTertiaryInsurance] = useState([]);
  const [addInsuranceRelation, setAddInsuranceRelation] = useState("");
  const [insuranceFirstName, setInsuranceFirstName] = useState("");
  const [insuranceMiddleName, setInsuranceMiddleName] = useState("");
  const [insuranceLastName, setInsuranceLastName] = useState("");
  const [insuranceSuffix, setInsuranceSuffix] = useState("");
  const [insuranceGender, setInsuranceGender] = useState("");
  const [insuranceDOB, setInsuranceDOB] = useState(new Date());
  const [insuranceStreet1, setInsuranceStreet1] = useState("");
  const [insuranceStreet2, setInsuranceStreet2] = useState("");
  const [insuranceCity, setInsuranceCity] = useState("");
  const [insuranceState, setInsuranceState] = useState("");
  const [insuranceZip, setInsuranceZip] = useState("");
  const [insuranceCountry, setInsuranceCountry] = useState(DEFAULT_COUNTRY);
  const [insurancePhone, setInsurancePhone] = useState("");
  const priorityList = PRIORITIES;
  const [insurancePriority, setInsurancePriority] = useState("");
  const [poilicyTypeList, setPoilicyTypeList] = useState([]);
  const [insurancePoilicyType, setInsurancePoilicyType] = useState("");
  const [insuranceList, setInsuranceList] = useState([]);
  const [insuranceName, setInsuranceName] = useState("");
  const [insurancePolicyId, setInsurancePolicyId] = useState("");
  const [insuranceGroupNumber, setInsuranceGroupNumber] = useState("");
  const [insuranceEffectiveDate, setInsuranceEffectiveDate] = useState("");
  const [insuranceTerminationDate, setInsuranceTerminationDate] = useState("");
  const [insuranceCopay, setInsuranceCopay] = useState("0");
  const [insuranceCoInsurance, setInsuranceCoInsurance] = useState("0");
  const [insuranceDeductibles, setInsuranceDeductibles] = useState("0");
  const [insuranceOutofPocket, setInsuranceOutofPocket] = useState("0");
  const [editIncurancePK, setEditIncurancePK] = useState("");
  const [sameAsPatient, setSameAsPatient] = useState(false);
  const [contactAsPatient, setContactAsPatient] = useState(false);
  const [relationShipList, setRelationShipList] = useState([]);
  const genderList = GENDERS;
  const [cptList, setCptList] = useState([]);

  const [imgUrl, setImgUrl] = useState(null)
  const [showImageCropperModalWindow, setShowImageCropperModalWindow] =
    useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageName, setImageName] = useState("");
  const [selectedInsurancePK, setSelectedInsurancePK] = useState("");

  const [inactive, setInactive] = useState(false);

  // add authorization
  const [showAddAuthorizationModal, setShowAddAuthorizationModal] =
    useState(false);
  const [identifier, setIdentifier] = useState("");
  const [authorizationType, setAuthorizationType] = useState("");
  const authorizationTypeList = AUTHORIZATION_REFERRAL;
  const [authorizationProvider, setAuthorizationProvider] = useState("");
  const [providerList, setProviderList] = useState([]);
  const [referringProvidersList, setReferringProvidersList] = useState([]);
  const [unitsApproved, setUnitsApproved] = useState("");
  const [authorizatioValidFrom, setAuthorizatioValidFrom] = useState("");
  const [authorizatioValidTo, setAuthorizatioValidTo] = useState("");
  const [authCpts, setAuthCpts] = useState([]);
  const [showPanel, setShowPanel] = useState(false);
  const [selectedAuthorizedInsurancePK, setSelectedAuthorizedInsurancePK] =
    useState("");
  const [authorizedContactsList, setAuthorizedContactsList] = useState([]);
  const [initialTableData, setInitialTableData] = useState(
    i18n.t("commons.loading")
  );
  const [activeRowId, setActiveRowId] = useState(0);
  const [authorizationEditID, setAuthorizationEditID] = useState("");
  const [editForm, setEditForm] = useState(false);

  const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
  const [deleteAuthorizeName, setDeleteAuthorizeName] = useState("");
  const [deleteAuthorizeId, setDeleteAuthorizeId] = useState("");
  const [eligibilityResponse, setEligibilityResponse] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [checkEligibilityHeader, setCheckEligibilityHeader] = useState(
    i18n.t("Eligibility.eligibilityTab1.window.eligibilitySummary")
  );
const patientDOB=useSelector((state)=>state.patientData?.patientAddDetails?.date_of_birth)

  function onChangeShowImageCropperModalWindow(newValue) {
    setShowImageCropperModalWindow(newValue);
  }

  function onCroppedImageChange(newValue) {
    const data = new FormData();
    if (
      [
        "file_PrimaryFrontCard",
        "file_SecondaryFrontCard",
        "file_TertiaryFrontCard",
      ].includes(selectedImage.slice(0, selectedImage.length - 1))
    ) {
      data.append("card_front_page", newValue);
    }
    if (
      [
        "file_PrimaryBackCard",
        "file_SecondaryBackCard",
        "file_TertiaryBackCard",
      ].includes(selectedImage.slice(0, selectedImage.length - 1))
    ) {
      data.append("card_back_page", newValue);
    }
    onUpdateCardImage(selectedInsurancePK, data, selectedImage);
  }

  const [showNotify, setShowNotify] = useState("hide");
  const [notifyDescription, setNotifyDescription] = useState("");
  const [notifyType, setNotifyType] = useState("success");
  const setShowLoadingBar = useContext(LoadingContext);

  function showNotifyWindow(action, type, desc, age = 3000) {
    if (action === "show") {
      setTimeout(() => {
        setShowNotify("hide");
      }, age);
    }
    setShowNotify(action);
    setNotifyType(type);
    setNotifyDescription(desc);
  }

  function getRenderingProviders(pageSize) {
    setShowLoadingBar(true);
    let pageNum = 0;
    const result = service.ListRenderingProviders(pageSize, pageNum, practicePK);
    result.then((response) => {
      setShowLoadingBar(false);
      setProviderList(response?.data || []);
    }).catch(() => {
        setShowLoadingBar(false);
        setProviderList([]);
    })
  }

  async function getReferringProviderList() {
    const pageSize = 0;
    const pageNum = 1;
    const response = await service.ListReferringProviders(pageSize, pageNum, practicePK);
    if (response && response.data?.results?.length)
      setReferringProvidersList(response.data.results);
  }

  useEffect(() => {
    getInsuranceInfosOfPatient(props.patientPK);
  },[props.patientPK])

  useEffect(() => {
    getRelationShips(DEFAULT_PAGING_SIZE, activePage);
    getPolicyTypes();
    getInsuranceNames("");
    getRenderingProviders(DEFAULT_PAGING_SIZE, activePage);
    getReferringProviderList();
  }, []);

  function getRelationShips(pageSize) {
    let pageNum = 0;
    const result = service.ListRelationShips(
      pageSize,
      pageNum,
      patientPK,
      "insurance"
    );
    result.then((response) => {
      setRelationShipList(response.data);
    });
  }

  function getPolicyTypes() {
    var page = 0;
    const result = service.ListPolicyTypes(
      DEFAULT_PAGING_SIZE,
      page,
      patientPK
    );
    result.then((response) => {
      setPoilicyTypeList(response.data);
    });
  }

  const onSearchInsurance = (query) => {
    getInsuranceNames(query);
  };

  async function getInsuranceNames(query) {
    var page = 0;
    try {
      const result = await service.ListInsuranceNames(
        DEFAULT_PAGING_SIZE,
        page,
        practicePK,
        patientPK,
        query
      );
      if (result.data) {
        setInsuranceList(result.data)
      }
      else {
        if (result?.statusText == 'Not Found') {
          showNotifyWindow("show", "error","Could not find any insurance in the practice record");
          setInsuranceList([])
        }
      }
    }
    catch (error) {
      showNotifyWindow("show", "error", "Unknown error");
    }
    
  }

  function addNewInsurance() {
    setEditIncurancePK("");
    getInsuranceInfosOfPatient(props.patientPK);
    setHeader(i18n.t("patientPages.insuranceInfo.addInsurance.addHeader"));
    setContactAsPatient(false);
    setShowAddInsuranceModalWindow(true);
  }

  useEffect(() => {
    if (props.patientPK) {
      setPatientPK(props.patientPK);
    }
  }, [props.patientPK]);

  function editInsurance(insuranceInfoPK) {
    setHeader(i18n.t("patientPages.insuranceInfo.addInsurance.editHeader"));
    setShowAddInsuranceModalWindow(true);
    const data = service.GetInsuranceDetails(insuranceInfoPK);
    data.then((response) => {
      const InsuranceResult = insuranceList.filter(item => item.id == response.data.insurance);
      setSelectedInsurance(InsuranceResult)
      setEditIncurancePK(response.data.id);
      setAddInsuranceRelation(response.data.relationship);
      if (
        response.data.relationship_name &&
        response.data.relationship_name.trim().toLowerCase() === "self"
      ) {
        setContactAsPatient(true);
      } else {
        setContactAsPatient(false);
      }

      setInsuranceFirstName(response.data.first_name);
      setInsuranceMiddleName(response.data.middle_name);
      setInsuranceLastName(response.data.last_name);
      setInsuranceSuffix(response.data.suffix);
      setInsuranceGender(response.data.gender);
      setInsuranceDOB(
        response.data.date_of_birth ? new Date(response.data.date_of_birth) : ""
      );
      setSameAsPatient(response.data.contact_patient_same);
      if (response.data.location_data) {
        setInsuranceStreet1(response.data.location_data.street1);
        setInsuranceStreet2(response.data.location_data.street2);
        setInsuranceCity(response.data.location_data.city);
        setInsuranceState(response.data.location_data.state);
        setInsuranceZip(response.data.location_data.zip_code);
        setInsuranceCountry(response.data.location_data.country);
      }
      setInsurancePhone(response.data.phone);
      setInsurancePriority(response.data.priority);
      setInsurancePoilicyType(response.data.policy_type);
      setInsuranceName(response.data.insurance);
      setInsurancePolicyId(response.data.policy_id);
      setInsuranceGroupNumber(response.data.group_number);
      setInsuranceEffectiveDate(
        response.data.effective_date
          ? CommonDateFormat(response.data.effective_date)
          : ""
      );
      setInsuranceTerminationDate(
        response.data.termination_date
          ? CommonDateFormat(response.data.termination_date)
          : ""
      );
      setInsuranceCopay(response.data.copay);
      setInsuranceCoInsurance(response.data.co_insurance);
      setInsuranceDeductibles(response.data.deductibles);
      setInsuranceOutofPocket(response.data.out_of_pocket);
    });
  }

  const handleSearchInsurance = (e, name, value) => {
    let label = "";
    let selected = [];
    if (Array.isArray(e) && e.length > 0) {
      label = e[0].id;
      selected = e;
    }
    setInsuranceName(label);
    setSelectedInsurance(selected)
    let insuranceNameObj = insuranceList.filter((item) => {
      if (item.id === value) return item;
    });
    if (
      insuranceNameObj &&
      Array.isArray(insuranceNameObj) &&
      insuranceNameObj.length > 0 &&
      insuranceNameObj[0].alert
    ) {
      setAlertMsg(insuranceNameObj[0].alert);
      setOpenAlertModal(true);
    } else {
      setAlertMsg("");
    }
    if(e.length===0){
      resetInsuranceForm()
    }
  };

  function onHandlePatientInsuranceChange(e) {
    if (
      e.target.name === "insuranceCopay" ||
      e.target.name === "insuranceCoInsurance" ||
      e.target.name === "insuranceDeductibles" ||
      e.target.name === "insuranceOutofPocket"
    ) {
      let re = /^\d+(\.\d{0,2})?$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        const name = e.target.name;
        let value =
          e.target.type === "checkbox" ? e.target.checked : e.target.value;
        let trimmedValue = "";
        let inputFields = [
          "addInsuranceRelation",
          "insuranceFirstName",
          "insuranceMiddleName",
          "insuranceLastName",
          "insuranceSuffix",
          "insuranceGender",
          "insuranceDOB",
          "insuranceStreet1",
          "insuranceStreet2",
          "insuranceCity",
          "insuranceState",
          "insuranceZip",
          "insuranceCountry",
          "insurancePhone",
          "insurancePriority",
          "insuranceName",
          "insurancePoilicyType",
          "insurancePolicyId",
          "insuranceGroupNumber",
          "insuranceEffectiveDate",
          "insuranceTerminationDate",
          "insuranceCopay",
          "insuranceCoInsurance",
          "insuranceDeductibles",
          "insuranceOutofPocket",
          "chkSameAsPatient",
        ];
        if (inputFields.includes(name)) trimmedValue = value;
        if (name === "addInsuranceRelation") {
          setAddInsuranceRelation(trimmedValue);
          let selId = e.target.value;
          let relSelfId = relationShipList.find(
            (element) => element.name.trim().toLowerCase() == "self"
          );
          if (selId == relSelfId.id) {
            setInsuranceFirstName(props.patientBasicData.first_name);
            setInsuranceMiddleName(props.patientBasicData.middle_name);
            setInsuranceLastName(props.patientBasicData.last_name);
            setInsuranceSuffix(props.patientBasicData.suffix);
            setInsuranceGender(props.patientBasicData.gender);
            setInsuranceDOB(
              props.patientBasicData.date_of_birth
                ? new Date(props.patientBasicData.date_of_birth)
                : ""
            );
            if (props.patientLocationData) {
              setInsuranceStreet1(props.patientLocationData.street1);
              setInsuranceStreet2(props.patientLocationData.street2);
              setInsuranceCity(props.patientLocationData.city);
              setInsuranceState(props.patientLocationData.state);
              setInsuranceZip(props.patientLocationData.zip_code);
              setInsuranceCountry(props.patientLocationData.country);
            }
            setInsurancePhone(props.patientBasicData.cell_phone);
            setContactAsPatient(true);
          } else {
            setInsuranceFirstName("");
            setInsuranceMiddleName("");
            setInsuranceLastName("");
            setInsuranceSuffix("");
            setInsuranceGender("");
            setInsuranceDOB("");
            setInsuranceStreet1("");
            setInsuranceStreet2("");
            setInsuranceCity("");
            setInsuranceState("");
            setInsuranceZip("");
            setInsuranceCountry(DEFAULT_COUNTRY);
            setInsurancePhone("");
            setContactAsPatient(false);
            setSameAsPatient(false);
          }
        } else if (name === "insuranceFirstName") {
          setInsuranceFirstName(trimmedValue);
        }
        else if (name === "insuranceMiddleName") {
          setInsuranceMiddleName(trimmedValue);
        }
        else if (name === "insuranceLastName") {
          setInsuranceLastName(trimmedValue);
        }
        else if (name === "insuranceSuffix") { setInsuranceSuffix(trimmedValue); }
        else if (name === "insuranceGender") { setInsuranceGender(trimmedValue); }
        else if (name === "chkSameAsPatient" && trimmedValue) {
          setSameAsPatient(trimmedValue);
          setInsuranceStreet1(props.patientLocationData.street1);
          setInsuranceStreet2(props.patientLocationData.street2);
          setInsuranceCity(props.patientLocationData.city);
          setInsuranceState(props.patientLocationData.state);
          setInsuranceZip(props.patientLocationData.zip_code);
          setInsuranceCountry(props.patientLocationData.country);
          setInsurancePhone(props.patientBasicData.cell_phone);
        } else if (name === "chkSameAsPatient" && !trimmedValue) {
          setSameAsPatient(trimmedValue);
          setInsuranceStreet1("");
          setInsuranceStreet2("");
          setInsuranceCity("");
          setInsuranceState("");
          setInsuranceZip("");
          setInsuranceCountry(DEFAULT_COUNTRY);
          setInsurancePhone("");
        } else if (name === "insuranceStreet1") {
          setInsuranceStreet1(trimmedValue);
        }
        else if (name === "insuranceStreet2") { setInsuranceStreet2(trimmedValue); }
        else if (name === "insuranceCity") { setInsuranceCity(trimmedValue); }
        else if (name === "insuranceState") { setInsuranceState(trimmedValue); }
        else if (name === "insuranceZip") { setInsuranceZip(trimmedValue); }
        else if (name === "insuranceCountry") { setInsuranceCountry(trimmedValue); }
        else if (name === "insurancePhone") { setInsurancePhone(trimmedValue); }
        else if (name === "insurancePriority") {
          setInsurancePriority(trimmedValue);
        }
        else if (name === "insurancePoilicyType") {
          setInsurancePoilicyType(trimmedValue);
        }
        else if (name === "insurancePolicyId") {
          trimmedValue = value.replace(/[^A-Za-z0-9.]/g, '')
          setInsurancePolicyId(trimmedValue);
        }
        else if (name === "insuranceGroupNumber") {
          setInsuranceGroupNumber(trimmedValue);
        }
        else if (name === "insuranceCopay") {
          setInsuranceCopay(trimmedValue);
        }
        else if (name === "insuranceCoInsurance") {
          setInsuranceCoInsurance(trimmedValue);
        }
        else if (name === "insuranceDeductibles") {
          setInsuranceDeductibles(trimmedValue);
        }
        else if (name === "insuranceOutofPocket") {
          setInsuranceOutofPocket(trimmedValue);
        }
      }
    } else {
      const name = e.target.name;
      let value =
        e.target.type === "checkbox" ? e.target.checked : e.target.value;
      let trimmedValue = "";
      let inputFields = [
        "addInsuranceRelation",
        "insuranceFirstName",
        "insuranceMiddleName",
        "insuranceLastName",
        "insuranceSuffix",
        "insuranceGender",
        "insuranceDOB",
        "insuranceStreet1",
        "insuranceStreet2",
        "insuranceCity",
        "insuranceState",
        "insuranceZip",
        "insuranceCountry",
        "insurancePhone",
        "insurancePriority",
        "insuranceName",
        "insurancePoilicyType",
        "insurancePolicyId",
        "insuranceGroupNumber",
        "insuranceEffectiveDate",
        "insuranceTerminationDate",
        "insuranceCopay",
        "insuranceCoInsurance",
        "insuranceDeductibles",
        "insuranceOutofPocket",
        "chkSameAsPatient",
      ];
      if (inputFields.includes(name)) trimmedValue = value;
      if (name === "addInsuranceRelation") {
        setAddInsuranceRelation(trimmedValue);
        let selId = e.target.value;
        let relSelfId = relationShipList.find(
          (element) => element.name.trim().toLowerCase() == "self"
        );
        if (selId == relSelfId.id) {
          setInsuranceFirstName(props.patientBasicData.first_name);
          setInsuranceMiddleName(props.patientBasicData.middle_name);
          setInsuranceLastName(props.patientBasicData.last_name);
          setInsuranceSuffix(props.patientBasicData.suffix);
          setInsuranceGender(props.patientBasicData.gender);
          setInsuranceDOB(
            props.patientBasicData.date_of_birth
              ? new Date(props.patientBasicData.date_of_birth)
              : ""
          );
          if (props.patientLocationData) {
            setInsuranceStreet1(props.patientLocationData.street1);
            setInsuranceStreet2(props.patientLocationData.street2);
            setInsuranceCity(props.patientLocationData.city);
            setInsuranceState(props.patientLocationData.state);
            setInsuranceZip(props.patientLocationData.zip_code);
            setInsuranceCountry(props.patientLocationData.country);
          }
          setInsurancePhone(props.patientBasicData.cell_phone);
          setContactAsPatient(true);
        } else {
          setInsuranceFirstName("");
          setInsuranceMiddleName("");
          setInsuranceLastName("");
          setInsuranceSuffix("");
          setInsuranceGender("");
          setInsuranceDOB("");
          setInsuranceStreet1("");
          setInsuranceStreet2("");
          setInsuranceCity("");
          setInsuranceState("");
          setInsuranceZip("");
          setInsuranceCountry(DEFAULT_COUNTRY);
          setInsurancePhone("");
          setContactAsPatient(false);
          setSameAsPatient(false);
        }
      } else if (name === "insuranceFirstName") {
        setInsuranceFirstName(trimmedValue);
      }
      else if (name === "insuranceMiddleName") {
        setInsuranceMiddleName(trimmedValue);
      }
      else if (name === "insuranceLastName") {
        setInsuranceLastName(trimmedValue);
      }
      else if (name === "insuranceSuffix") {
        setInsuranceSuffix(trimmedValue);
      }
      else if (name === "insuranceGender") {
        setInsuranceGender(trimmedValue);
      }
      else if (name === "chkSameAsPatient" && trimmedValue) {
        setSameAsPatient(trimmedValue);
        setInsuranceStreet1(props.patientLocationData.street1);
        setInsuranceStreet2(props.patientLocationData.street2);
        setInsuranceCity(props.patientLocationData.city);
        setInsuranceState(props.patientLocationData.state);
        setInsuranceZip(props.patientLocationData.zip_code);
        setInsuranceCountry(props.patientLocationData.country);
        setInsurancePhone(props.patientBasicData.cell_phone);
      } else if (name === "chkSameAsPatient" && !trimmedValue) {
        setSameAsPatient(trimmedValue);
        setInsuranceStreet1("");
        setInsuranceStreet2("");
        setInsuranceCity("");
        setInsuranceState("");
        setInsuranceZip("");
        setInsuranceCountry(DEFAULT_COUNTRY);
        setInsurancePhone("");
      } else if (name === "insuranceStreet1") {
        setInsuranceStreet1(trimmedValue);
      }
      else if (name === "insuranceStreet2") {
        setInsuranceStreet2(trimmedValue);
      }
      else if (name === "insuranceCity") {
        setInsuranceCity(trimmedValue);
      }
      else if (name === "insuranceState") {
        setInsuranceState(trimmedValue);
      }
      else if (name === "insuranceZip") {
        setInsuranceZip(trimmedValue);
      }
      else if (name === "insuranceCountry") {
        setInsuranceCountry(trimmedValue);
      }
      else if (name === "insurancePhone") {
        setInsurancePhone(trimmedValue);
      }
      else if (name === "insurancePriority") {
        setInsurancePriority(trimmedValue);
      }
      else if (name === "insurancePoilicyType") {
        setInsurancePoilicyType(trimmedValue);
      }
      else if (name === "insurancePolicyId") {
        trimmedValue = value.replace(/[^A-Za-z0-9.]/g, '')
        setInsurancePolicyId(trimmedValue);
      }
      else if (name === "insuranceGroupNumber") {
        setInsuranceGroupNumber(trimmedValue);
      }
      else if (name === "insuranceCopay") {
        setInsuranceCopay(trimmedValue);
      }
      else if (name === "insuranceCoInsurance") {
        setInsuranceCoInsurance(trimmedValue);
      }
      else if (name === "insuranceDeductibles") {
        setInsuranceDeductibles(trimmedValue);
      }
      else if (name === "insuranceOutofPocket") {
        setInsuranceOutofPocket(trimmedValue);
      }
    }
  }

  function onHandleInsuranceEffectiveDateChange(selected) {
    setInsuranceEffectiveDate(selected);
  }

  function onHandleInsuranceTerminationDateChange(selected) {
    setInsuranceTerminationDate(selected);
  }

  function onHandleInsuranceDOBDateChange(selected) {
    setInsuranceDOB(selected);
  }

  function getInsuranceInfosOfPatient(patient_pk) {
    setPrimaryInsurance([]);
    setSecondaryInsurance([]);
    setTertiaryInsurance([]);
    const result = service.ListInsuranceInfos(patient_pk);
    let primaryInsuranceTmp = [];
    let secondaryInsuranceTmp = [];
    let tertiaryInsuranceTmp = [];
    result.then((response) => {
      const node = "patientInsuranceInfo"; // For Redux Dispatch - Current Tab name
      const name = "insuranceDetails"; // For Redux Dispatch - Key to pass to Redux to indicate Dispatch is evoked from Patient-Insurance Info
      if (response.data && response.data.length > 0) {
        response.data.map((item) => {
          if (item.priority === 1) {
            primaryInsuranceTmp.push(item);
            setPrimaryInsurance(primaryInsuranceTmp);
          } else if (item.priority === 2) {
            secondaryInsuranceTmp.push(item);
            setSecondaryInsurance(secondaryInsuranceTmp);
          } else if (item.priority === 3) {
            tertiaryInsuranceTmp.push(item);
            setTertiaryInsurance(tertiaryInsuranceTmp);
          }
        });

        const value = "Patient has Insurance"; // When Patient has active policy then passing dummy value to Redux to remove form warning
        dispatch({ type: "patientInfo/setPatientFormWarnings", payload: { name, value, node, patientPK } });
        props.setFormWarningStatus(true);

      } else if (response?.data && response?.data?.length === 0) { // When Patient has no active insurance - passing empty string Form Warning Redux State 
        const value = "";
        dispatch({ type: "patientInfo/setPatientFormWarnings", payload: { name, value, node, patientPK } });
        props.setFormWarningStatus(true);

      }
    });

  }

  function onSaveInsuranceData(e) {
    if(!insuranceName){
      return;
    } else if (props.patientDod) {
      if (insuranceTerminationDate > props.patientDod ||  insuranceEffectiveDate > props.patientDod) {
        return
      }
    }
    e.preventDefault();
    let has_error = false;
    if (
      !addInsuranceRelation &&
      !insurancePriority &&
      !insurancePoilicyType &&
      !insuranceName
    ) {
      has_error = true;
    }
    try {
      if (insuranceDOB) format(new Date(insuranceDOB), "yyyy-MM-dd");
      if (insuranceEffectiveDate) format(new Date(insuranceEffectiveDate), "yyyy-MM-dd");
      if (insuranceTerminationDate) 
        format(new Date(insuranceTerminationDate), "yyyy-MM-dd");
    } catch (error) {
      has_error = true;
    }
    if (!has_error) {
      setShowLoadingBar(true);
      let result = null;
      let data = {
        patient: patientPK,
        relationship: addInsuranceRelation,
        priority: insurancePriority,
        policy_type: insurancePoilicyType,
        insurance: insuranceName,
        policy_id: insurancePolicyId,
        group_number: insuranceGroupNumber,
        effective_date: insuranceEffectiveDate
          ? format(new Date(insuranceEffectiveDate), "yyyy-MM-dd")
          : null,
        copay: insuranceCopay || '0.00',
        co_insurance: insuranceCoInsurance || '0.00',
        deductibles: insuranceDeductibles || '0.00',
        out_of_pocket: insuranceOutofPocket || '0.00',
        contact_patient_same: sameAsPatient,
        status: inactive ? INSURANCE_EXPIRED : INSURANCE_ACTIVE,   
      };
      if (insuranceTerminationDate) {
        try {
          data["termination_date"] = format(new Date
            (insuranceTerminationDate),
            "yyyy-MM-dd"
          );
        } catch (error) {
          data["termination_date"] = null;
        }
      } else data["termination_date"] = null;
      data["first_name"] = insuranceFirstName;
      data["middle_name"] = insuranceMiddleName;
      data["last_name"] = insuranceLastName;
      data["suffix"] = insuranceSuffix;
      data["gender"] = insuranceGender;
      data["date_of_birth"] = insuranceDOB
        ? format(insuranceDOB, "yyyy-MM-dd")
        : null;
      data["location"] = {
        street1: insuranceStreet1,
        street2: insuranceStreet2,
        city: insuranceCity,
        state: insuranceState,
        zip_code: insuranceZip,
        country: insuranceCountry,
      };
      data["phone"] = insurancePhone;
      if (editIncurancePK)
        result = service.UpdateInsuranceDetails(editIncurancePK, data);
      else result = service.SaveInsuranceDetails(data);
      result.then((response) => {
        setShowLoadingBar(false);
        if (response.status === 201 || response.status === 200) {
          if (response.status === 201)
            showNotifyWindow("show", "success", ADD_SUCCESS);
          else showNotifyWindow("show", "success", UPDATE_SUCCESS);
          getInsuranceInfosOfPatient(props.patientPK);
          resetInsuranceForm();
          setShowAddInsuranceModalWindow(false);
        } else if (response.status === 400) {
          if (response.data.message === "duplicate_insurance_invalid")
            showNotifyWindow(
              "show",
              "error",
              i18n.t("errorMessages.duplicate_insurance")
            );
          else if (response.data.message === "duplicate_policy_id_invalid")
            showNotifyWindow(
              "show",
              "error",
              i18n.t("errorMessages.duplicate_policy_id")
            );
          else if (response.data.message === "duplicate_policy_id_in_practice_invalid")
            showNotifyWindow(
              "show",
              "error",
              i18n.t("errorMessages.duplicate_policy_id_in_practice")
            );
        } else {
          showNotifyWindow("show", "error", ADD_ERROR);
        }
      });
    }
  }

  function onHideInsurance() {
  }

  function resetInsuranceForm() {
    setAddInsuranceRelation("");
    setInsuranceFirstName("");
    setInsuranceMiddleName("");
    setInsuranceLastName("");
    setInsuranceSuffix("");
    setInsuranceGender("");
    setInsuranceDOB("");
    setInsuranceStreet1("");
    setInsuranceStreet2("");
    setInsuranceCity();
    setInsuranceState("");
    setInsuranceZip("");
    setInsuranceCountry("");
    setInsurancePhone("");
    setInsurancePriority("");
    setInsurancePoilicyType("");
    setInsuranceName("");
    setInsurancePolicyId("");
    setInsuranceGroupNumber("");
    setInsuranceEffectiveDate(null);
    setInsuranceTerminationDate(null);
    setInsuranceCopay(0.00);
    setInsuranceCoInsurance(0.00);
    setInsuranceDeductibles(0.00);
    setInsuranceOutofPocket(0.00);
    setInactive(false);
    setSelectedInsurance([]);
  }

  function onHandleImageChange(e, insuranceInfoPK) {
    const name = e.target.name;
    let img_url = "";
    let img_obj = null;
    if (imageExtension(e.target.files[0].name)) {
      img_obj = e.target.files[0];
      img_url = URL.createObjectURL(e.target.files[0]);
    }
    setImgUrl(img_url);
    setImageName(img_obj.name);
    document.getElementById(name).value = "";
    setShowImageCropperModalWindow(true);
    setSelectedImage(name);
    setSelectedInsurancePK(insuranceInfoPK);
  }

  function onUpdateCardImage(insuranceInfoPK, data) {
    setShowLoadingBar(true);
    const result = service.UpdateInsuranceCardImage(insuranceInfoPK, data);
    result.then((response) => {
      setShowLoadingBar(false);
      if (response.status === 200) {
        showNotifyWindow("show", "success", UPDATE_SUCCESS);
        getInsuranceInfosOfPatient(props.patientPK);
      } else if (
        response.status === 400 &&
        response.data.user_already_exists !== undefined
      ) {
        showNotifyWindow("show", "error", i18n.t("errorMessages.user_exists"));
      } else {
        showNotifyWindow("show", "error", ADD_ERROR);
      }
    });
  }

  function resetImage(insurancePK, type) {
    if (checkPermission(permission_key_values_patient.can_delete_insurance_card)) {
    let result = service.DeleteInsuranceCardImage(insurancePK, type);
    result.then((response) => {
      if (response.status == 200 || response.status == 204) {
        getInsuranceInfosOfPatient(props.patientPK);
      }
    });
    }
    else {
      showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
    }
  }


  function showImageAddEdit(actionValue, divName) {
    let divField = document.getElementById(divName);
    if (actionValue == "show") divField.classList.remove("hidden");
    else divField.classList.add("hidden");
  }

  function callClickEvent(primaryInsuranceId, fileField) {
    document.getElementById(fileField)?.click();
  }

  function AddAuthorizationModal() {
    if (checkPermission(permission_key_values_patient.can_add_authorization)) {
      setShowAddAuthorizationModal(true);
      setAuthCpts([]);
      setHeader(
        i18n.t("patientPages.insuranceInfo.manageAuthorization.addAuthorization")
      );
    } else {
      showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
    }
  }

  function resetshowAddAuthorizationModal() {
    setHeader("");
    setEditForm(false);
    setIdentifier("");
    setAuthorizationType("");
    setAuthorizationProvider("");
    setUnitsApproved("");
    setAuthorizatioValidFrom("");
    setAuthorizatioValidTo("");
    setAuthorizationEditID("");
  }

  function handleAddAuthorization(e) {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    let trimmedValue = "";
    trimmedValue = value;
    if (name === "identifier") setIdentifier(trimmedValue);
    else if (name === "authorizationType") setAuthorizationType(trimmedValue);
    else if (name === "authorizationProvider")
      setAuthorizationProvider(trimmedValue);
    else if (name === "unitsApproved") {
      if (trimmedValue < 0) {
        setUnitsApproved(0);
      } else {
        setUnitsApproved(trimmedValue.replace(/[^\d.]/g, "")); // .replace(/[^\d.-]/g, '')
      }
    }
  }
  function onHandleAutorizationDateChange(selected, name) {
    if (name === "authorizatioValidFrom") setAuthorizatioValidFrom(selected);
    else if (name === "authorizatioValidTo") setAuthorizatioValidTo(selected);
  }

  function onSaveAuthorization(e) {
    e.preventDefault();
    let has_error = true;
    if (
      !identifier ||
      !authorizationType ||
      !authorizationProvider ||
      !unitsApproved ||
      !authorizatioValidFrom ||
      !authorizatioValidTo ||
      !authCpts.length
    ) {
      has_error = false;
    }
    if (has_error) {
      setShowLoadingBar(true);
      let data = {
        patient: patientPK,
        insurance_info: selectedAuthorizedInsurancePK,
        identifier: identifier,
        authorization_referral: authorizationType,
        provider: (authorizationType!=2)
        ? authorizationProvider: 
        "",
        referring_provider: (authorizationType==2)
          ? authorizationProvider: 
          "",
        units_approved: unitsApproved,
        valid_from: authorizatioValidFrom
          ? format(authorizatioValidFrom, "yyyy-MM-dd")
          : "",
        valid_to: authorizatioValidTo
          ? format(authorizatioValidTo, "yyyy-MM-dd")
          : "",
        cpt_codes: authCpts.map((item) => item.id) || [],
      };
      let result = null;
      if (editForm)
        result = service.UpdatePatientAuthorization(authorizationEditID, data);
      else result = service.SaveAutorizationDetails(data);
      result.then((response) => {
        setShowLoadingBar(false);
        if (response.status === 201 || response.status === 200) {
          if (response.status === 201)
            showNotifyWindow("show", "success", ADD_SUCCESS);
          else showNotifyWindow("show", "success", UPDATE_SUCCESS);
          setAuthCpts([]);
          setShowAddAuthorizationModal(false);
          resetshowAddAuthorizationModal();
          showAuthorizations(selectedAuthorizedInsurancePK);
        } else {
          showNotifyWindow("show", "error", ADD_ERROR);
        }
      });
    }
  }

  function showAuthorizations(Id) {
    setShowPanel(true);
 
    setSelectedAuthorizedInsurancePK(Id);
    setShowLoadingBar(true);
    let page = 0;
    const data = service.ListAuthorizations(DEFAULT_PAGING_SIZE, page, Id);
    data.then((response) => {
      setShowLoadingBar(false);
      setAuthorizedContactsList(response.data);
      if (response.data.length === 0)
        setInitialTableData(i18n.t("commons.noRecords"));
    });
  }

  function onShowActionButtons(event, rowId) {
    let dropdown = document.getElementById("dropdown-" + rowId);
    let dropdownMenu = document.getElementById("dropdown-menu-" + rowId);
    dropdown.classList.toggle("show");
    dropdownMenu.classList.toggle("show");
    if (activeRowId === 0) {
      setActiveRowId(rowId);
    } else if (activeRowId === rowId) {
      setActiveRowId(0);
    } else if (this.state.activeRowId !== rowId) {
      dropdown = document.getElementById("dropdown-" + activeRowId);
      dropdownMenu = document.getElementById("dropdown-menu-" + activeRowId);
      dropdown.classList.toggle("show");
      dropdownMenu.classList.toggle("show");
      setActiveRowId(rowId);
    }
  }

  function onHideActionButtons(event, rowId) {
    let dropdown = document.getElementById("dropdown-" + rowId);
    let dropdownMenu = document.getElementById("dropdown-menu-" + rowId);
    dropdown.classList.remove("show");
    dropdownMenu.classList.remove("show");
    setActiveRowId(0);
  }

  function onEditAuthorization(Id) {
    if (checkPermission(permission_key_values_patient.can_change_authorization)) {
    setHeader(
      i18n.t("patientPages.insuranceInfo.manageAuthorization.editAuthoriation")
    );
    setShowAddAuthorizationModal(true);
    const data = service.GetPatientAuthorization(Id);
    data.then((response) => {
      setIdentifier(response.data.identifier);
      setAuthorizationType(response.data.authorization_referral);
      setAuthorizationProvider((response.data.authorization_referral==2)?response.data.referring_provider:response.data.provider);
      setUnitsApproved(response.data.units_approved);
      setAuthorizatioValidFrom(
        response.data.valid_from ? new Date(response.data.valid_from) : ""
      );
      setAuthorizatioValidTo(
        response.data.valid_to ? new Date(response.data.valid_to) : ""
      );
      setAuthorizationEditID(response.data.id);
      setAuthCpts(response.data.cpt_codes || [])
      setEditForm(true);
    });
  }
    else {
      setTimeout(()=> {
        showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
      },300)
    }
  }

  function onDeleteAuthorization(authorizeId) {
    if (checkPermission(permission_key_values_patient.can_delete_authorization)) {
      authorizedContactsList.map((item) => {
        if (item.id == parseInt(authorizeId)) {
          setDeleteAuthorizeName(item.identifier);
          setDeleteAuthorizeId(item.id);
          setShowDeleteModalWindow(true);
        }
      });
    }
    else {
      setTimeout(()=> {
        showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
      },300)
    }
  }

  function onDeleteAction() {
    setShowLoadingBar(true);
    let data = null;
    data = service.DeletePatientAuthorization(deleteAuthorizeId, practicePK);
    data.then(() => {
      setShowLoadingBar(false);
      setDeleteAuthorizeName("");
      setDeleteAuthorizeId("");
      setShowDeleteModalWindow(false);
      showNotifyWindow("show", "success", DELETE_SUCCESS);
      showAuthorizations(selectedAuthorizedInsurancePK);
    });
  }

  function onDeleteHide() {
    setDeleteAuthorizeName("");
    setDeleteAuthorizeId("");
    setShowDeleteModalWindow(false);
  }

  function checkInsuranceEligibility(e, insuranceObj) {
    setShowLoadingBar(true);
    let data = null;
    data = {
      practice_pk: practicePK,
      insurance_company_id: insuranceObj.id,
      patient_id: props.patientPK,
    };
    let result = service.checkInsuranceEligibility(data);
    result.then((response) => {
      if (response.data.message === "failed") {
        let msg_text =
          "<span className='bold-font'>" +
          i18n.t("patientPages.insuranceInfo.providerNotAdded") +
          "</span>";
        setEligibilityResponse(msg_text);
        setCheckEligibilityHeader(i18n.t("commons.alert"));
      } else if (response.data.message === 'authentication_failed') {
        showNotifyWindow("show", "error", i18n.t('errorMessages.authentication_failed')); 
        setShowLoadingBar(false);
      } else if (response.data.message === 'practice_not_found') {
        showNotifyWindow("show", "error", i18n.t('errorMessages.practice_not_found')); 
        setShowLoadingBar(false);
      } else if (response.data.message === 'provider_not_added') {
        showNotifyWindow("show", "error", i18n.t('errorMessages.provider_not_added')); 
        setShowLoadingBar(false);
      } else if (response.status === 500) {
        setShowLoadingBar(false);
        showNotifyWindow("show", "error", i18n.t('errorMessages.commonError'));
      }
       else {
        setEligibilityResponse(response.data.display_text);
        setCheckEligibilityHeader(
          i18n.t("Eligibility.eligibilityTab1.window.eligibilitySummary")
        );
      }
      setShowLoadingBar(false);
      setOpenModal(true);
      getInsuranceInfosOfPatient(props.patientPK);
    });
  }
  function getInsuranceEligibility(e, insuranceObj) {
    setShowLoadingBar(true);
    let result = service.getInsuranceEligibility(
      practicePK,
      insuranceObj.id,
      props.patientPK
    );
    result.then((response) => {
      setEligibilityResponse(response.data.display_text);
      setShowLoadingBar(false);
      setCheckEligibilityHeader(
        i18n.t("Eligibility.eligibilityTab1.window.eligibilitySummary")
      );
      setOpenModal(true);
    });
  }
  function resetForm() {
    setOpenModal(false);
    setEligibilityResponse("");
  }
  function onHide() {
    setOpenModal(false);
  }
  function hideAlertModal() {
    setOpenAlertModal(false);
  }

  const onSearchCPTCodes = (query) => {
    let type = 'dropdown'
    const page = 0;
    const page_size = 0;
    let result = service.GetCPTCodeList(page_size, page, practicePK, type, query);
    result.then((response) => {
      setCptList(response.data)
    })
  }

  const handleCptAdd = (e) => {
    if (e.length === 0) {
      return;
    }
    setAuthCpts((prevState) => {
      if (prevState.find((item) => item.id === e[0]?.id)) {
        return prevState;
      } else {
        return [...prevState, e[0]];
      }
    });
  };

  const handleDeleteCPT = (itemToRemove) => {
    setAuthCpts((prevState) => {
      return prevState.filter((item) => item.id !== itemToRemove);
    });
  };

  return (
    <React.Fragment>
      <div>
        <Notify
          showNotify={showNotify}
          setShowNotify={setShowNotify}
          notifyDescription={notifyDescription}
          setNotifyType={setNotifyType}
          setNotifyDescription={setNotifyDescription}
          notifyType={notifyType}
        />
        <CustomizedSmallDialogs
          type="alert"
          header={checkEligibilityHeader}
          showModal={openModal}
          setShowModalWindow={setOpenModal}
          resetForm={resetForm}
          alertOK={onHide}
          onHide={onHide}
        >
          {ReactHtmlParser(eligibilityResponse)}
        </CustomizedSmallDialogs>
        {props.patientEditable && (
          <div className="row lh-0">
            <div className="">
              <div className="advancedSearch"></div>
              <RightSidePanelWithWidth
                width={"62%"}
                showPanel={showPanel}
                setShowPanel={setShowPanel}
                hideLabel={true}
                clickLabel={i18n.t(
                  "patientPages.buttons.manageAuthorization"
                )}
                title={i18n.t(
                  "patientPages.buttons.manageAuthorization"
                )}
              >
                <div className="">
                  <div className="row mt-2"> 
                    <div className="col ">
                      <label className="padding-top15 font-heading">
                        {i18n.t(
                          "patientPages.insuranceInfo.manageAuthorization.AuthorizationAndReferral"
                        )}
                      </label>
                    </div>
                    <div className="col justify-right ">
                        <CommonButton variant="contained" icon="add" label="Add New" onClick={AddAuthorizationModal}/>
                    </div>
                  </div>
                  <div 
                  // style={{padding:"12px"}}
                  >  
                  <PatientMangeAuthorInfoTable initialTableData={initialTableData} authorizedContactsList={authorizedContactsList} insuranceType={'primaryInsurance'}
                     onShowActionButtons={onShowActionButtons} onHideActionButtons={onHideActionButtons} onEditAuthorization={onEditAuthorization} onDeleteAuthorization={onDeleteAuthorization} />
                  </div>
                  
                  <div className="height-75">&nbsp;</div>
                </div>
              </RightSidePanelWithWidth>
            </div>
          </div>
        )}
        <Form id={"form_patient_info" + patientPK} autoComplete="off">
          <Form.Group>
            <div className="row padding-left17 padding-bottom15 mt-3">
              {checkPermission(permission_key_values_patient.can_add_insurance_info) && props.patientEditable && props.showInsuranceAddnew && (
            <CommonButton variant="contained" icon="add" label="Add New" onClick={addNewInsurance}/>
              )}

              <div className="ml-4">
              <button
                type="button"
                className="btn btn-secondary"
                style={{height: "37px"}}
              >
                <PatientPastCoverage patientPK={patientPK} onUpdateSuccess={() => getInsuranceInfosOfPatient(props.patientPK)} showNotifyWindow={showNotifyWindow}/>
              </button>
              </div>
  
              <div className="ml-4">
                <PatientsToAddFamily
                  practicePK={practicePK}
                  patientPK={patientPK}
                  patientBasicData={props.patientBasicData}
                  patientEditable={props.patientEditable}
                />
              </div>
            </div>
            <InsuranceDataTable insuraceList={primaryInsurance} insuranceName={"Primary Insurance"}  editInsurance={editInsurance} showAuthorizations={showAuthorizations}
            callClickEvent={callClickEvent} showImageAddEdit={showImageAddEdit} resetImage={resetImage} onHandleImageChange={onHandleImageChange}
            checkInsuranceEligibility={checkInsuranceEligibility} getInsuranceEligibility={getInsuranceEligibility}/>
            <InsuranceDataTable insuraceList={secondaryInsurance} insuranceName={"Secondary Insurance"} editInsurance={editInsurance} showAuthorizations={showAuthorizations}
            callClickEvent={callClickEvent} showImageAddEdit={showImageAddEdit} resetImage={resetImage} onHandleImageChange={onHandleImageChange}
            checkInsuranceEligibility={checkInsuranceEligibility} getInsuranceEligibility={getInsuranceEligibility} patientEditable={props.patientEditable}/> 
           <InsuranceDataTable insuraceList={tertiaryInsurance} insuranceName={"Tertiary Insurance"} editInsurance={editInsurance} showAuthorizations={showAuthorizations}
            callClickEvent={callClickEvent} showImageAddEdit={showImageAddEdit} resetImage={resetImage} onHandleImageChange={onHandleImageChange}
            checkInsuranceEligibility={checkInsuranceEligibility} getInsuranceEligibility={getInsuranceEligibility} patientEditable={props.patientEditable}/> 
          </Form.Group>
        </Form>
        <ImageCrop
          imgUrl={imgUrl}
          imageName={imageName}
          show={showImageCropperModalWindow}
          showModal={onChangeShowImageCropperModalWindow}
          changeCroppedImage={onCroppedImageChange}
          cardAspect={true}
        />

        <CustomizedDialogs
          showModal={showAddInsuranceModalWindow}
          type="save"
          chkInactive={editIncurancePK ? true : false}
          setInactive={setInactive}
          inactive={inactive}
          header={header}
          setShowModalWindow={setShowAddInsuranceModalWindow}
          resetForm={resetInsuranceForm}
          onHide={onHideInsurance}
        >
          <Form
            id="form_dataEntry"
            encType="multipart/form-data"
            autoComplete="off"
            onSubmit={(e) => onSaveInsuranceData(e)}
          >
            <Form.Group>
              <div className="row sub-heading padding-left-10">
                <div className=" div-inline">
                  <label className="margin-top-3">
                    {i18n.t(
                      "patientPages.insuranceInfo.addInsurance.labelSubscriberInfo"
                    )}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <SelectInput
                    data={relationShipList}
                    required={true}
                    name="addInsuranceRelation"
                    id="addInsuranceRelation"
                    value={addInsuranceRelation}
                    setValue={setAddInsuranceRelation}
                    onValueChange={(selected) =>
                      onHandlePatientInsuranceChange(selected)
                    }
                    label={i18n.t(
                      "patientPages.insuranceInfo.addInsurance.labelRelationShip"
                    )}
                  />
                </div>
                <div className="col"></div>
                <div className="col"></div>
              </div>
              {addInsuranceRelation && !contactAsPatient && (
                <TransitionGroup>
                  <CSSTransition
                    key={'relation-contact'}
                    timeout={700}
                    classNames="fade"
                    appear
                    exit
                    enter={false}
                  >
                <div className="relation_details">
                  <div className="row">
                    <div className="col-4">
                      <div className="form-group padding-top">
                        <TextInput
                          type="text"
                          id="insuranceLastName"
                          required={true}
                          name="insuranceLastName"
                          label={i18n.t(
                            "patientPages.insuranceInfo.addInsurance.labelLastName"
                          )}
                          onValueChange={onHandlePatientInsuranceChange}
                          value={insuranceLastName}
                        />
                      </div>
                    </div>
                    <div className="col-4 ">
                      <div className="form-group padding-top">
                        <TextInput
                          type="text"
                          id="insuranceFirstName"
                          required={true}
                          name="insuranceFirstName"
                          label={i18n.t(
                            "patientPages.insuranceInfo.addInsurance.labelFirstName"
                          )}
                          onValueChange={onHandlePatientInsuranceChange}
                          value={insuranceFirstName}
                        />
                      </div>
                    </div>
                    <div className="col-4 ">
                      <div className="form-group padding-top">
                        <TextInput
                          type="text"
                          id="insuranceMiddleName"
                          name="insuranceMiddleName"
                          label={i18n.t(
                            "patientPages.insuranceInfo.addInsurance.labelMiddleName"
                          )}
                          onValueChange={onHandlePatientInsuranceChange}
                          value={insuranceMiddleName}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <div className="form-group padding-top">
                        <TextInput
                          type="text"
                          id="insuranceSuffix"
                          name="insuranceSuffix"
                          label={i18n.t(
                            "patientPages.insuranceInfo.addInsurance.labelSuffix"
                          )}
                          onValueChange={onHandlePatientInsuranceChange}
                          value={insuranceSuffix}
                        />
                      </div>
                    </div>
                    <div className="col-4 ">
                      <SelectInput
                        data={genderList}
                        name="insuranceGender"
                        required={true}
                        id="insuranceGender"
                        value={insuranceGender}
                        setValue={setInsuranceGender}
                        onValueChange={(selected) =>
                          onHandlePatientInsuranceChange(selected)
                        }
                        label={i18n.t(
                          "patientPages.insuranceInfo.addInsurance.labelGender"
                        )}
                      />
                    </div>
                    <div className="col-4 ">
                      <CalendarInput
                        label={i18n.t(
                          "patientPages.insuranceInfo.addInsurance.labelDOB"
                        )}
                        selected={insuranceDOB}
                        required={true}
                        maxDate={new Date()}
                        onValueChange={(selected) =>
                          onHandleInsuranceDOBDateChange(selected)
                        }
                        name="insuranceDOB"
                      />
                    </div>
                  </div>
                  <div style={{ height: "34px" }} className="row">
                    <div className="col-1 pt-2 max-width-3">
                      <div className="form-groupp">
                        <div className="custom-checkbox">
                          <input
                            type="checkbox"
                            id="chkSameAsPatient"
                            checked={sameAsPatient}
                            name="chkSameAsPatient"
                            onChange={onHandlePatientInsuranceChange}
                          />
                          <label
                            className="checkbox"
                            htmlFor="chkSameAsPatient"
                          ></label>
                        </div>
                      </div>
                    </div>
                    <div className="input-content-box padding-top5">
                      <label className="margin-top-3px">
                        {i18n.t(
                          "patientPages.insuranceInfo.addInsurance.labelSameAsPatient"
                        )}
                      </label>
                    </div>
                  </div>
                      {!sameAsPatient &&
                        <TransitionGroup>
                          <CSSTransition
                            key={'relation-contact'}
                            timeout={700}
                            classNames="fade"
                            appear
                            exit
                            enter={false}
                          >
                            <div>
                              <div className="row pt-2">
                                <div className="col-4">
                                  <div className="form-group padding-top">
                                    <TextInput
                                      type="text"
                                      id="insuranceStreet1"
                                      required={true}
                                      name="insuranceStreet1"
                                      label={i18n.t(
                                        "patientPages.insuranceInfo.addInsurance.labelStreet1"
                                      )}
                                      onValueChange={onHandlePatientInsuranceChange}
                                      value={insuranceStreet1}
                                      disabled={sameAsPatient}
                                    />
                                  </div>
                                </div>
                                <div className="col-4 ">
                                  <div className="form-group padding-top">
                                    <TextInput
                                      type="text"
                                      id="insuranceStreet2"
                                      name="insuranceStreet2"
                                      label={i18n.t(
                                        "patientPages.insuranceInfo.addInsurance.labelStreet2"
                                      )}
                                      onValueChange={onHandlePatientInsuranceChange}
                                      value={insuranceStreet2}
                                      disabled={sameAsPatient}
                                    />
                                  </div>
                                </div>
                                <div className="col-4 ">
                                  <div className="form-group padding-top">
                                    <CityInput
                                      type="text"
                                      id="insuranceCity"
                                      required={true}
                                      name="insuranceCity"
                                      label={i18n.t(
                                        "patientPages.insuranceInfo.addInsurance.labelCity"
                                      )}
                                      onValueChange={onHandlePatientInsuranceChange}
                                      value={insuranceCity}
                                      setValue={setInsuranceCity}
                                      zip={insuranceZip}
                                      disabled={sameAsPatient}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-4">
                                  <div className="form-group padding-top">
                                    <TextInput
                                      type="text"
                                      id="insuranceState"
                                      required={true}
                                      name="insuranceState"
                                      label={i18n.t(
                                        "patientPages.insuranceInfo.addInsurance.labelState"
                                      )}
                                      onValueChange={onHandlePatientInsuranceChange}
                                      value={insuranceState}
                                      disabled={sameAsPatient}
                                    />
                                  </div>
                                </div>
                                <div className="col-4 ">
                                  <div className="form-group padding-top">
                                    <GeoCodeSearch
                                      id="insuranceZip"
                                      name="insuranceZip"
                                      required={true}
                                      label={i18n.t(
                                        "patientPages.insuranceInfo.addInsurance.labelZip"
                                      )}
                                      setlocState={setInsuranceState}
                                      setLocCountry={setInsuranceCountry}
                                      setLocZipCode={setInsuranceZip}
                                      setLocCity={setInsuranceCity}
                                      setValueChanged={false}
                                      defaultValue={insuranceZip}
                                      disabled={sameAsPatient}
                                    />
                                  </div>
                                </div>
                                <div className="col-4 ">
                                  <div className="form-group padding-top">
                                    <TextInput
                                      type="text"
                                      id="insuranceCountry"
                                      name="insuranceCountry"
                                      label={i18n.t(
                                        "patientPages.insuranceInfo.addInsurance.labelCountry"
                                      )}
                                      onValueChange={onHandlePatientInsuranceChange}
                                      value={insuranceCountry}
                                      disabled={sameAsPatient}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  <div className="form-group padding-top">
                                    <PhoneInput
                                      name="insurancePhone"
                                      label={i18n.t(
                                        "patientPages.insuranceInfo.addInsurance.labelPhone"
                                      )}
                                      onValueChange={setInsurancePhone}
                                      value={insurancePhone}
                                      disabled={sameAsPatient}
                                    />
                                  </div>
                                </div>
                                <div className="col"></div>
                                <div className="col"></div>
                              </div>
                            </div>
                          </CSSTransition>
                        </TransitionGroup>
                      }
                    </div>
                  </CSSTransition>
                </TransitionGroup>
              )}
              <div className="row padding-left-10 sub-heading">
                <div className="div-inline">
                  <label className="margin-top-3px">
                    {i18n.t(
                      "patientPages.insuranceInfo.addInsurance.labelInsuranceInfo"
                    )}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <SelectInput
                    data={priorityList}
                    required={true}
                    name="insurancePriority"
                    id="insurancePriority"
                    value={insurancePriority}
                    setValue={setInsurancePriority}
                    onValueChange={(selected) =>
                      onHandlePatientInsuranceChange(selected)
                    }
                    label={i18n.t(
                      "patientPages.insuranceInfo.addInsurance.labelPriority"
                    )}
                  />
                </div>
                <div className="col-4 ">
                  <SelectInput
                    data={poilicyTypeList}
                    name="insurancePoilicyType"
                    id="insurancePoilicyType"
                    value={insurancePoilicyType}
                    setValue={setInsurancePoilicyType}
                    onValueChange={(selected) =>
                      onHandlePatientInsuranceChange(selected)
                    }
                    label={i18n.t(
                      "patientPages.insuranceInfo.addInsurance.labelPolicyType"
                    )}
                  />
                </div>
                <div className="col-4  ">
                  <AsyncTypeInput
                    labelKey="name"
                    class_name={insuranceName == "" ? "input-error" : ""}
                    name="insuranceName"
                    minLength={1}
                    placeholder={""}
                    onValueChange={(e) =>
                      handleSearchInsurance(e, "insuranceName")
                    }
                    options={insuranceList}
                    onSearch={onSearchInsurance}
                    id={"insuranceName"}
                    label={i18n.t(
                      "patientPages.insuranceInfo.addInsurance.labelInsurance"
                    )}
                    // clearButton={true}
                    selected={selectedInsurance}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <div className="form-group padding-top">
                    <TextInput
                      type="text"
                      id="insurancePolicyId"
                      required={true}
                      name="insurancePolicyId"
                      label={i18n.t(
                        "patientPages.insuranceInfo.addInsurance.labelPolicyID"
                      )}
                      onValueChange={onHandlePatientInsuranceChange}
                      value={insurancePolicyId}
                    />
                  </div>
                </div>
                <div className="col-4 ">
                  <div className="form-group padding-top">
                    <TextInput
                      type="text"
                      id="insuranceGroupNumber"
                      name="insuranceGroupNumber"
                      label={i18n.t(
                        "patientPages.insuranceInfo.addInsurance.labelGroupNumber"
                      )}
                      onValueChange={onHandlePatientInsuranceChange}
                      value={insuranceGroupNumber}
                    />
                  </div>
                </div>
                <div className="col-4 ">
                  <CalendarInput
                    label={i18n.t(
                      "patientPages.insuranceInfo.addInsurance.labelEffectiveDate"
                    )}
                    placeholder="Effective Date"
                    selected={insuranceEffectiveDate}
                    minDate={props.patientDob}
                    maxDate={props.patientDod}
                    onValueChange={(selected) =>
                      onHandleInsuranceEffectiveDateChange(selected)
                    }
                    name="insuranceEffectiveDate"
                    readonly={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <CalendarInput
                    minDate={
                      insuranceEffectiveDate
                        ? new Date(insuranceEffectiveDate)
                        : props.patientDob
                    }
                    maxDate={props.patientDod}
                    label={i18n.t(
                      "patientPages.insuranceInfo.addInsurance.labelTerminationDate"
                    )}
                    selected={insuranceTerminationDate}
                    onValueChange={(selected) =>
                      onHandleInsuranceTerminationDateChange(selected)
                    }
                    name="insuranceTerminationDate"
                    placeholder="Termination Date"
                  />
                </div>
                <div className="col"></div>
                <div className="col"></div>
              </div>

              <div className="row">
                <div className="col-4 input_right_align">
                  <CurrencyInputs
                    id="insuranceCopay"
                    name="insuranceCopay"

                    value={insuranceCopay}
                    onValueChange={onHandlePatientInsuranceChange}
                    label={i18n.t(
                      "patientPages.insuranceInfo.addInsurance.labelCopay"
                    )}
                  />
                </div>
                <div className="col-4  input_right_align">
                  <CurrencyInputs
                    id="insuranceCoInsurance"
                    name="insuranceCoInsurance"
                    value={insuranceCoInsurance}
                    onValueChange={onHandlePatientInsuranceChange}
                    label={i18n.t(
                      "patientPages.insuranceInfo.addInsurance.labelCoInsurance"
                    )}
                  />
                </div>
                <div className="col-4  input_right_align">
                  <CurrencyInputs
                    id="insuranceDeductibles"
                    name="insuranceDeductibles"
                    value={insuranceDeductibles}
                    onValueChange={onHandlePatientInsuranceChange}
                    label={i18n.t(
                      "patientPages.insuranceInfo.addInsurance.labelDeductible"
                    )}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4 input_right_align">
                      <TextInput
                        type="number"
                        id="insuranceOutofPocket"
                        name="insuranceOutofPocket"
                        placeholder=""
                        label={i18n.t(
                          "patientPages.insuranceInfo.addInsurance.labelOutOfPocket"
                        )}
                        onValueChange={onHandlePatientInsuranceChange}
                        value={insuranceOutofPocket}
                      />
                </div>
                <div className="col-4"></div>
                <div className="col-4"></div>
              </div>
            </Form.Group>
          </Form>
        </CustomizedDialogs>
        <CustomizedDialogs
          showModal={showAddAuthorizationModal}
          type="save"
          header={header}
          setShowModalWindow={setShowAddAuthorizationModal}
          resetForm={resetshowAddAuthorizationModal}
        >
          <Form
            id="form_dataEntry"
            encType="multipart/form-data"
            autoComplete="off"
            onSubmit={(e) => onSaveAuthorization(e)}
          >
            <div className="row">
              <div className="col">
                <TextInput
                  type="text"
                  id="identifier"
                  name="identifier"
                  value={identifier}
                  onValueChange={handleAddAuthorization}
                  label={i18n.t(
                    "patientPages.insuranceInfo.manageAuthorization.identifier"
                  )}
                  required={true}
                />
              </div>
              <div className="col">
                <SelectInput
                  id="authorizationType"
                  data={authorizationTypeList}
                  name="authorizationType"
                  value={authorizationType}
                  setValue={setAuthorizationType}
                  onValueChange={handleAddAuthorization}
                  label={i18n.t(
                    "patientPages.insuranceInfo.manageAuthorization.type"
                  )}
                  required={true}
                />
              </div>
              <div className="col">
                <SelectInput
                  id="authorizationProvider"
                  data={authorizationType === 1 ? providerList : authorizationType === 2 ? referringProvidersList : []}
                  name="authorizationProvider"
                  value={authorizationProvider}
                  setValue={setAuthorizationProvider}
                  onValueChange={handleAddAuthorization}
                  label={i18n.t(
                    "patientPages.insuranceInfo.manageAuthorization.provider"
                  )}
                  required={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <TextInput
                  type="number"
                  id="unitsApproved"
                  name="unitsApproved"
                  placeholder=""
                  value={unitsApproved}
                  onValueChange={handleAddAuthorization}
                  label={i18n.t(
                    "patientPages.insuranceInfo.manageAuthorization.unitsApproved"
                  )}
                  required={true}
                />
              </div>
              <div className="col">

                <CalendarInput
                  selected={authorizatioValidFrom}
                  id="authorizatioValidFrom"
                  name="authorizatioValidFrom"
                  placeholder="authorizatioValidFrom"
                  value={authorizatioValidFrom}
                  onValueChange={(selected) =>
                    onHandleAutorizationDateChange(
                      selected,
                      "authorizatioValidFrom"
                    )
                  }
                  label={i18n.t(
                    "patientPages.insuranceInfo.manageAuthorization.validFrom"
                  )}
                  minDate={patientDOB? new Date(patientDOB):null}
                  maxDate={authorizatioValidTo ? authorizatioValidTo : undefined}
                  required={true}
                />
              </div>
              <div className="col">

                <CalendarInput
                  selected={authorizatioValidTo}
                  id="authorizatioValidTo"
                  name="authorizatioValidTo"
                  placeholder="authorizatioValidTo"
                  value={authorizatioValidTo}
                  onValueChange={(selected) =>
                    onHandleAutorizationDateChange(
                      selected,
                      "authorizatioValidTo"
                    )
                  }
                  label={i18n.t(
                    "patientPages.insuranceInfo.manageAuthorization.validTo"
                  )}
                  minDate={authorizatioValidFrom}
                  required={true}
                />
              </div>
            </div>
            <div className="row" style={{ borderRadius: '8px', marginLeft: '4px', marginRight: '4px', border: '1px solid #e6e6e6', padding: '10px' }}>
              <div className="col-12" style={{ marginBottom: '5px' }}>
                <div className="row">
                  <div className="col-4">
                    <AsyncTypeInput
                      class_name={!authCpts.length ? "input-error" : ""}
                      labelKey="name"
                      name="Search CPT Code"
                      minLength={1}
                      onValueChange={(e) => handleCptAdd(e)}
                      options={cptList}
                      functionInvoke={true}
                      dropup={true}
                      selected={[]}
                      placeholder={'Search CPT Code'}
                      onSearch={onSearchCPTCodes}
                      id={"auth-cpt-code"}
                      label={'CPT Code'}
                      isMenuToggleCallbackNeeded={true}
                    />
                  </div>
                  <div className="col-12" style={{ marginTop: '15px' }}>
                    {
                      authCpts.length > 0 &&
                      <div className="row">
                        {
                          authCpts.map((item, index) => {
                            return (
                              <Chip
                                size="small"
                                sx={{ marginRight: '5px !important', borderRadius: '8px !important', flexWrap: 'wrap !important' }}
                                key={index}
                                label={item.name}
                                onDelete={() => handleDeleteCPT(item?.id)}
                                className="chip-label-mui"
                              />
                            );
                          })
                        }
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div className="col-4">

              </div>
            </div>
          </Form>
        </CustomizedDialogs>
      </div>
      <CustomizedSmallDialogs
        showModal={showDeleteModalWindow}
        header={i18n.t("commons.confirmDelete")}
        type="delete"
        deleteItem={onDeleteAction}
        resetForm={resetshowAddAuthorizationModal}
        onHide={onDeleteHide}
        setShowModalWindow={setShowDeleteModalWindow}
      >
        {i18n.t(
          "patientPages.insuranceInfo.manageAuthorization.deletConfirmation"
        ) +
          "'" +
          deleteAuthorizeName +
          "'?"}
      </CustomizedSmallDialogs>
      <CustomizedSmallDialogs
        showModal={openAlertModal}
        setShowModalWindow={setOpenAlertModal}
        alertOK={hideAlertModal}
        header={i18n.t("commons.alert")}
        type="alert"
        onHide={hideAlertModal}
        resetForm={hideAlertModal}
      >
        {alertMsg}
      </CustomizedSmallDialogs>
    </React.Fragment>
  );
};
export default PatientInsuranceInfo;
