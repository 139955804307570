import React, { useContext, useEffect, useState } from "react";
import i18n from "../../../utilities/i18n";
import { getStorage, setStorage } from "../../../utilities/browserStorage";
import { ActivePaymentPlanAdd } from "./ActivePaymentPlanAdd";
import { checkPermission, currencyFormat } from "../../../utilities/commonUtilities";
import LoadingContext from "../../../container/loadingContext";
import service from "./service";
import CustomizedDialogs from "../../modalWindowComponent/CustomizedDialogs";
import { Delete, Edit } from "@material-ui/icons";
import CustomizedSmallDialogs from "../../modalWindowComponent/CustomisedSmallDialog";
import EditPaymentPlan from "./EditPaymentPlan";
import { permission_key_values_patient } from "../../../utilities/permissions";
import Notify from "../../commons/notify";
import BackArrowWithLabel from "../../commons/Back";
import { useHistory} from 'react-router-dom'
import { ROUTE_PAYMENT_PLAN } from "../../../utilities/staticConfigs";


function ActivePayment() {
  const practicePK = getStorage("practice");
  const patientData = JSON.parse(getStorage("patient-data"));
  const setShowLoadingBar = useContext(LoadingContext);
  const history = useHistory();


  const [patient, setPatient] = useState();
  const [editPlanId, setEditPlanId] = useState();
  const [editModalShow, setEditModalShow] = useState(false);
  const [deletePatientId, setDeletePatientId] = useState();
  const [deleteModalShow, setDeleteModalShow] = useState(false);

  // show rightside panel
  const [showPanel, setShowPanel] = useState(false);

  const [showNotify, setShowNotify] = useState('hide');
  const [notifyDescription, setNotifyDescription] = useState('');
  const [notifyType, setNotifyType] = useState('success');

  const getPatientDetials = () => {
    setShowLoadingBar(true);
    service
      .getPaymentPlansSummary(patientData.id)
      .then((response) => {
        setShowLoadingBar(false);
        if (response.status === 200) {
          setPatient(response.data);
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch(() => {
        setShowLoadingBar(false);
      });
  };

  useEffect(() => {
    getPatientDetials();
  }, []);

  
  const onDeleteAction = () => {
    setDeleteModalShow(false);
    setShowLoadingBar(true);
    service
      .deletePaymentPlan(deletePatientId)
      .then((response) => {
        setShowLoadingBar(false);
        if (
          (response.status === 200) || (response.status === 201) ||
          response.status === 204
        ) {
          setDeletePatientId(undefined);
          getPatientDetials();
        }
      })
      .catch(() => {
        setShowLoadingBar(false);
      });
  };
  const onDeleteButtonClickHandle = (item) => {
    setDeletePatientId(item.id);
    setDeleteModalShow(true);
  };

  const closeEditWindow = () => {
    setEditModalShow(false);
  };
  function showNotifyWindow(action, type, desc, age = 3000) {
    if (action === 'show') {
      setTimeout(() => {
        setShowNotify('hide');
      }, age)
    }
    setShowNotify(action);
    setNotifyType(type);
    setNotifyDescription(desc);
  }

  const onEditButtonClickHandle = (item) => {
    setStorage("edit-payment-id", item.id);
    setEditPlanId(item.id);
    setEditModalShow(true);
  };

  function backToPayments(){
    history.push(ROUTE_PAYMENT_PLAN)
  }

  return (
    <>
    <div className="link dictionaries-back pl-0 mb-3 margin-left30" onClick={backToPayments}>
        <BackArrowWithLabel label={i18n.t("menuNames.patientsTab.payment-plan")} />
      </div>
      {patient && (
        <div className="box basic-info-padding bordering p-4 border-radius-8 margin-left12">
            <div className="col-md-12 justify-space-between pl-0 pr-0" >
              <div className="pl-0 col-3" >
                <table className="table">
                  <tbody>
                    <tr>
                      <td colSpan="2" style={{whiteSpace:'pre-line'}}>{patient.name}</td>
                    </tr>
                    <tr>
                      <td>{i18n.t('paymentPlan.activePaymentPlan.primaryInsurance')}</td>
                      <td className="word-wrap">{patient.primary_insurance}</td>
                    </tr>
                    <tr>
                      <td>{i18n.t('paymentPlan.activePaymentPlan.secondaryInsurance')}</td>
                      <td><div className="word-wrap">{patient.secondary_insurance}</div></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="pl-0 col-3">
                <table className="table">
                  <tbody>
                    <tr>
                      <td>{i18n.t('paymentPlan.activePaymentPlan.accountBalance')}</td>
                      <td align="right">{currencyFormat(patient.balance)}</td>
                    </tr>
                    <tr>
                      <td>{i18n.t('paymentPlan.activePaymentPlan.insurancePayments')}</td>
                      <td align="right">
                        {currencyFormat(patient.insurance_pays)}
                      </td>
                    </tr>
                    <tr>
                      <td>{i18n.t('paymentPlan.activePaymentPlan.insuranceAdjustments')}</td>
                      <td align="right">
                        {currencyFormat(patient.insurance_adjustments)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="pl-0 col-3">
                <table className="table">
                  <tbody>
                    <tr>
                      <td>{i18n.t('paymentPlan.activePaymentPlan.insuranceBalance')}</td>
                      <td align="right">
                        {currencyFormat(patient.insurance_balance)}
                      </td>
                    </tr>
                    <tr>
                      <td>{i18n.t('paymentPlan.activePaymentPlan.patientPayments')}</td>
                      <td align="right">
                        {currencyFormat(patient.patient_pays)}
                      </td>
                    </tr>
                    <tr>
                      <td>{i18n.t('paymentPlan.activePaymentPlan.patientWriteOffs')}</td>
                      <td align="right">
                        {currencyFormat(patient.patient_adjustments)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="pl-0 pr-0 col-3">
                <table className="table">
                  <tbody>
                    <tr>
                      <td>{i18n.t('paymentPlan.activePaymentPlan.patientBalance')}</td>
                      <td align="right">
                        {currencyFormat(patient.patient_balance)}
                      </td>
                    </tr>
                    <tr>
                      <td>{i18n.t('paymentPlan.activePaymentPlan.insuranceCredits')}</td>
                      <td align="right">
                        {currencyFormat(patient.insurance_credits)}
                      </td>
                    </tr>
                    <tr>
                      <td>{i18n.t('paymentPlan.activePaymentPlan.patientCredits')}</td>
                      <td align="right">
                        {currencyFormat(patient.patient_credits)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            
            </div>

            <div className=" justify-right">
              {/* permission checking */}
              {checkPermission(permission_key_values_patient.payment_plans_sub_module_add) &&
                <button
                  type="button"
                  onClick={() => setShowPanel(true)}
                  className="btn btn-primary-blue common-height35 mb-4"
                >
                  {i18n.t("paymentPlan.newPaymentPlan.addPaymentPlan")}
                </button>
              }
              <CustomizedDialogs
                showModal={showPanel}
                header={i18n.t("paymentPlan.newPaymentPlan.newPaymentPlan")}
                type="save"
                hideSave={true}
                minWidth={0}
                setShowModalWindow={setShowPanel}
                addNoOverflowXClass={true}
              >
                <ActivePaymentPlanAdd
                  fetchPatientDetails={getPatientDetials}
                  patientId={patientData.id}
                  practicePK={practicePK}
                  closeModal={setShowPanel}
                  isEdit={false}
                />
              </CustomizedDialogs>
            </div>
            <table className="table" id={"overflow_auto"}>
              <thead>
                <tr>
                  <th>Payment Plan Start Date</th>
                  <th>Total Installments</th>
                  <th>Installment Interval</th>
                  <th>Amount</th>
                  <th>Due Status</th>
                  {/* <th>Over Due</th> */}
                  {checkPermission(permission_key_values_patient.payment_plans_sub_module_modify) &&
                    <th>Actions</th>
                  }
                </tr>
              </thead>
              <tbody>
                {patient.payment_plan.map((plan, index) => (
                  <React.Fragment key={index}>
                    <Notify
                      showNotify={showNotify}
                      setShowNotify={setShowNotify}
                      notifyDescription={notifyDescription}
                      setNotifyType={setNotifyType}
                      setNotifyDescription={setNotifyDescription}
                      notifyType={notifyType}
                    />
                    <tr>
                      <td align="center">{plan.payment_start_date}</td>
                      <td align="center">{plan.total_installments}</td>
                      <td align="right">{plan.payment_type_name ? plan.payment_type_name : ""}</td>
                      <td align="right">{currencyFormat(plan.total_amount)}</td>
                      <td align="right">
                        {plan.due_status === 1 ? "Paid" : "Unpaid"}
                      </td>
                      {/* <td align="right">{currencyFormat(232.34)}</td> */}
                      {checkPermission(permission_key_values_patient.payment_plans_sub_module_modify) &&
                        <td align="center">
                          <Edit
                            onClick={() => onEditButtonClickHandle(plan)}
                            style={{ cursor: "pointer" }}
                          />
                          <Delete
                            style={{ cursor: "pointer" }}
                            onClick={() => onDeleteButtonClickHandle(plan)}
                          />
                        </td>
                      }
                    </tr>
                    {plan.installment_detail.length !== 0 ? (
                      <tr>
                        <td colSpan={6}>
                          <table className="table" id={"overflow_auto"}>
                            <thead>
                              <tr>
                                <th>Due Date</th>
                                <th>Installment Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {plan.installment_detail.map((emi, i) => (
                                <tr key={i}>
                                  <td align="center">{emi.due_date}</td>
                                  <td align="center">
                                    {currencyFormat(emi.installment_amount)}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>

            <CustomizedDialogs
              showModal={editModalShow}
              header={i18n.t("paymentPlan.newPaymentPlan.newPaymentPlan")}
              type="save"
              hideSave={true}
              setShowModalWindow={setEditModalShow}
              addNoOverflowXClass={true}
            >
              <EditPaymentPlan
                showNotifyWindow={showNotifyWindow}
                fetchPatientDetails={getPatientDetials}
                closeModal={closeEditWindow}
                paymentId={editPlanId}
              />
            </CustomizedDialogs>

            <CustomizedSmallDialogs
              showModal={deleteModalShow}
              header={i18n.t("commons.confirmDelete")}
              type="delete"
              deleteItem={onDeleteAction}
              setShowModalWindow={setDeleteModalShow}
            >
              Do you really want to delete?
            </CustomizedSmallDialogs>
        </div>
      )}
    </>
  );
}

export default ActivePayment;
