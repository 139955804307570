import React, { useContext, useEffect, useState } from "react";
import Notify from "../../commons/notify";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import PatientDropDownSearch from "../../patientManagement/patients/patientDropDownSearch";
import RightSidePanel from "../../commons/RightSidePanel/RightSidePanel";
import i18n from "../../../utilities/i18n";
import PatientsAdvancedSearch from "../../patientManagement/patients/PatientsAdvancedSearch";
import CalendarInput from "../../commons/input/CalendarInput";
import SelectInput from "../../commons/input/SelectInput";
import { CREDIT_CARD_TYPES, PAYEMNT_MODES, POST_PAYMENT_SECONDARY_SUB_TYPES, POST_PAYMENT_SECONDARY_TYPES } from "../../../utilities/dictionaryConstants";
import { PAYEMNT_MODES_CREDITCARD_ID, PAYMENT_MODE_CASH_ID, ROUTE_VIEW_RECEIPTS } from "../../../utilities/staticConfigs";
import CurrencyInputs from "../../commons/input/CurrencyInput";
import TextInput from "../../commons/input/input";
import TextAreaInput from "../../commons/textarea/textarea";
import CommonButton from "../../commons/Buttons";
import { useDispatch } from "react-redux";
import { removeAllPaymentDetails, resetAlertMessage, setFormError, setPatientPostDetails, showModalWindow, updateDate, updateValueChange } from "./StateManagement/paymentSlice";
import { checkPermission, currencyFormat } from "../../../utilities/commonUtilities";
import { permission_key_values_payments } from "../../../utilities/permissions";
import PatientPaymentDetails from "./PatientPaymentDetails";
import CustomizedSmallDialogs from "../../modalWindowComponent/CustomisedSmallDialog";
import CustomizedDialogs from "../../modalWindowComponent/CustomizedDialogs";
import { CheckForPaymentExists, onSavePatientPayment, onSavePatientPaymentProcedure } from "./StateManagement/asyncThunkAPI";
import LoadingContext from "../../../container/loadingContext";
import { useAutoSavePayment, useFetchPatientPaymentPlans, useGetPaymentData, useLoadingBar, useNotify, useSetPatientName } from "./Hooks";
import PatientClaimList from "./ClaimList/PatientClaimList";

const PatientPayment = (props) => {
    const dispatch = useDispatch();
    const { paymentPK, patientPaymentPostType, patientPaymentPatientPK } = useSelector((state) => state.paymentData.postPaymentDetails);
    const { form_error, paymentPlanList, showAlertMessage, alertMessage, header, showPostAsCreditModal,
        paymentPostLoading, statusTag, notifyMessage, showNotification, payerListLoading, saveTriggered, redirectToReceipt
    } = useSelector((state) => state.paymentData);
    const { paymentMode, paymentType, paymentSubType, paymentAmount, paymentReference,
        selectedPaymentPlan, creditCard, paymentNote, PrintReceiptonsaving, payment_date
    } = useSelector((state) => state.paymentData.patientPaymentVariables)
    const { autoPost, postManually, showContinuePosting, patientName
    } = useSelector((state) => state.paymentData.patientPostDetails);
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");
    const [patientPK, setPatientPK] = useState("");
    const [patientSelected, setPatientSelected] = useState([]);
    const [patientAdvSearchData, setPatientAdvSearchData] = useState([]);
    const [selectPatient, setSelectPatient] = useState("");
    const setShowLoadingBar = useContext(LoadingContext);

    const convertDate = (dateString) => {
        const date = new Date(dateString);
        date.setHours(21, 34, 0, 0);
        return date.toString();
    };


    const onHandleDate = (value, name) => {
        if (name === "paymentDate") dispatch(updateDate({ value: value, name: name, key: "patientPaymentVariables" }));
    }

    //hook for get patient payment plans
    useFetchPatientPaymentPlans(patientPK);


    useEffect(() => {
        if (redirectToReceipt) {
            window.open(ROUTE_VIEW_RECEIPTS);
        }
    }, [redirectToReceipt])

    //hook for save patient payment
    useAutoSavePayment(postManually, autoPost, patientPK, paymentPK);

    //to set patient payment details
    useSetPatientName(selectPatient);

    //hook to show loading bar
    useLoadingBar(paymentPostLoading, setShowLoadingBar);
    useLoadingBar(payerListLoading, setShowLoadingBar);

    //show notification
    useNotify(showNotification, notifyMessage, statusTag, showNotifyWindow, props.updatePayment, saveTriggered, props);

    //to get payment details of patient
    useGetPaymentData(paymentPK, props.updatePayment, setPatientPK, patientPaymentPatientPK, patientPaymentPostType);

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    const onHandleChange = (e) => {
        if (e.target) {
            let name = e.target.name;
            let trimmedValue = "";
            let value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
            let inputFields = ["paymentType", "postPaymentType", "paymentReference", "paymentType", "paymentSubType", "paymentMode", "creditCard", "paymentNote", "paymentAmount", "PrintReceiptonsaving", "includeFamilyMemberBalances", "showPreview", "selectedPaymentPlan", "selectCharge"];
            if (inputFields.includes(name)) {
                trimmedValue = value;
            }
            let mandatoryFields = [
                "patientId",
                "paymentDate",
                "paymentReference",
                "paymentAmount",
                "paymentType",
                "paymentMode",
            ];

            if (!("" + trimmedValue).trim() && mandatoryFields.includes(name)) {
                dispatch(setFormError({ name: name, value: "error" }));
            } else {
                dispatch(setFormError({ name: name, value: "" }));
            }

            dispatch(updateValueChange({ name: name, value: value, key: "patientPaymentVariables" }))
        }
    }

    const onResetPayment = () => {
        dispatch(removeAllPaymentDetails("patient"));
        setPatientPK("");
        setPatientAdvSearchData([]);
        setSelectPatient("");
        setPatientSelected([]);
    }

    const paymentPostAsCredit = () => {
        const patientDob = convertDate(patientSelected[0]?.dob);
        let paymentDate = convertDate(payment_date);
        if (paymentDate != "") {
            if (new Date(paymentDate) >= new Date(patientDob)) {
                dispatch(CheckForPaymentExists({ type: "credit", selectPatient: selectPatient, patientPK: patientPK }));
                dispatch(setPatientPostDetails({ name: "postType", value: 3, key: "credit" }));
            } else {
                dispatch(showNotification({ message: i18n.t("errorMessages.dobError"), tag: "error" }))
            }
        }
    }

    //eslint-disable-next-line
    const paymentPostManually = () => {
        const patientDob = convertDate(patientSelected[0]?.dob);
        let paymentDate = convertDate(payment_date);
        if (paymentDate != "") {
            if (new Date(paymentDate) >= new Date(patientDob)) {
                dispatch(CheckForPaymentExists({ type: "manually", selectPatient: selectPatient, patientPK: patientPK }));
                dispatch(setPatientPostDetails({ name: "postType", value: 1, key: "manually" }));
            }
        }
    }

    //eslint-disable-next-line
    const paymentAutoPost = (e) => {
        const patientDob = convertDate(patientSelected[0]?.dob);
        let paymentDate = convertDate(payment_date);
        if (paymentDate != "") {
            if (new Date(paymentDate) >= new Date(patientDob)) {
                dispatch(CheckForPaymentExists({ type: "auto", selectPatient: selectPatient, patientPK: patientPK }));
                dispatch(setPatientPostDetails({ name: "postType", value: 2, key: "auto" }));
            }
        }
    }

    const onCancelProcedures = () => {
        dispatch(removeAllPaymentDetails("patient"));
        if (props.setSelectedEditPaymentPK) {
            props.setSelectedEditPaymentPK("");
        }
    }

    const onSavePaymentProcedures = () => {
        dispatch(onSavePatientPaymentProcedure({ paymentPK: paymentPK, updatePayment: props.updatePayment }))
    }

    const onAlertOk = () => {
        dispatch(resetAlertMessage())
    }

    const resetForm = () => {
        dispatch(showModalWindow({ key: "showPostAsCreditModal", open: false, header: '' }))
    }

    //eslint-disable-next-line
    const onSavePostAsCreidt = (e) => {
        e.preventDefault();
        dispatch(onSavePatientPayment(patientPK))
    }

    return (
        <React.Fragment>
            <Notify
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            />
            <div className="col-md-12">
                <div className={props.updatePayment ? "postPayment" : ""}>
                    <div className="">
                        <Form autoComplete="off">
                            {!paymentPK && (
                                <div className="patientPayment">
                                    <div className="row col pl-0 padding-top20 padding-bottom10">
                                        <PatientDropDownSearch
                                            setSelectPatient={setSelectPatient}
                                            setPatientPK={setPatientPK}
                                            patientSelected={patientSelected}
                                            setPatientSelected={setPatientSelected}
                                            required={true}
                                            patientAdvSearchData={patientAdvSearchData}
                                        ></PatientDropDownSearch>
                                        <div className="col pl-0 margin-top25 margin-rightM20">
                                            <div className="justify-right">
                                                <RightSidePanel
                                                    title={i18n.t("commons.advancedSearch")}
                                                    onclickLabel={i18n.t("commons.advancedSearch")}
                                                >
                                                    <PatientsAdvancedSearch
                                                        setSelectPatient={setSelectPatient}
                                                        setPatientPK={setPatientPK}
                                                        setPatientSelected={setPatientSelected}
                                                        setPatientAdvSearchData={setPatientAdvSearchData}
                                                    ></PatientsAdvancedSearch>
                                                </RightSidePanel>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row margin-top20 margin-right10">
                                        <div className="col-3">
                                            <CalendarInput
                                                name="paymentDate"
                                                maxDate={new Date()}
                                                selected={payment_date}
                                                onValueChange={(e) => onHandleDate(e, "paymentDate")}
                                                label={i18n.t(
                                                    "payments.post_payments.deposit/payment_date"
                                                )}
                                                className={form_error.payment_date ? "input-error" : ""}
                                                required={true}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <SelectInput
                                                name="paymentMode"
                                                data={PAYEMNT_MODES}
                                                value={paymentMode}
                                                onValueChange={(e) => onHandleChange(e)}
                                                label={i18n.t("payments.post_payments.payment_mode")}
                                                className={form_error.paymentMode ? "input-error" : ""}
                                                required={true}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <SelectInput
                                                name="paymentType"
                                                data={POST_PAYMENT_SECONDARY_TYPES}
                                                value={paymentType}
                                                onValueChange={(e) => onHandleChange(e)}
                                                label={i18n.t("payments.post_payments.payment_type")}
                                                className={form_error.paymentType ? "input-error" : ""}
                                                required={true}
                                            />
                                        </div>
                                        {paymentType == 2 && (
                                            <div className="col-3">
                                                <SelectInput
                                                    name="paymentSubType"
                                                    data={POST_PAYMENT_SECONDARY_SUB_TYPES}
                                                    value={paymentSubType}
                                                    onValueChange={(e) => onHandleChange(e)}
                                                    label={i18n.t("payments.post_payments.payment_sub_type")}
                                                    className={form_error.paymentSubType ? "input-error" : ""}
                                                    required={true}
                                                />
                                            </div>)}

                                    </div>
                                    <div className="row margin-right10">
                                        <div className="col-3">
                                            <CurrencyInputs
                                                name="paymentAmount"
                                                value={paymentAmount}
                                                onValueChange={(e) => onHandleChange(e)}
                                                label={i18n.t(
                                                    "payments.post_payments.payment_amount"
                                                )}
                                                defaultClassName={
                                                    paymentAmount === "" ? "input-error" : ""
                                                }
                                                required={true}
                                            />
                                        </div>
                                        {paymentMode !== PAYMENT_MODE_CASH_ID && (
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <TextInput
                                                        name="paymentReference"
                                                        value={paymentReference}
                                                        onValueChange={(e) => onHandleChange(e)}
                                                        label={(paymentMode === 2) ? i18n.t(
                                                            "payments.post_payments.payment_refrencecheck"
                                                        ) :
                                                            i18n.t(
                                                                "payments.post_payments.payment_reference")
                                                        }
                                                        className={
                                                            form_error.paymentReference ? "input-error" : ""
                                                        }
                                                        required={true}
                                                    />
                                                </div>
                                            </div>)}
                                        {paymentPlanList && (
                                            <div className={paymentMode == PAYMENT_MODE_CASH_ID ? "col-3" : "col-3"}>
                                                <SelectInput
                                                    name="selectedPaymentPlan"
                                                    data={paymentPlanList}
                                                    value={selectedPaymentPlan}
                                                    onValueChange={(e) => onHandleChange(e)}
                                                    label={"Payment Plan"}
                                                    className={form_error.paymentMode ? "input-error" : ""}
                                                // required={true}
                                                />
                                            </div>)}
                                        {paymentMode === PAYEMNT_MODES_CREDITCARD_ID && (
                                            <div className="col-3">
                                                <SelectInput
                                                    name="creditCard"
                                                    data={CREDIT_CARD_TYPES}
                                                    value={creditCard}
                                                    onValueChange={(e) => onHandleChange(e)}
                                                    label={i18n.t("payments.post_payments.credit_card")}
                                                    showCreditCardIcons={true}
                                                    className={form_error.creditCard ? "input-error" : ""}
                                                    required={true}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                            <TextAreaInput
                                                name="paymentNote"
                                                value={paymentNote}
                                                onValueChange={(e) => onHandleChange(e)}
                                                rows={3}
                                                label={i18n.t("payments.post_payments.payment_note")}
                                            />
                                        </div>
                                        <div className="col-1 max-width-3 lh-1 margin-top85">
                                            <div className="form-group">
                                                <div className="custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        id="PrintReceiptonsaving"
                                                        name="PrintReceiptonsaving"
                                                        checked={PrintReceiptonsaving}
                                                        onChange={(e) => onHandleChange(e)}
                                                    />
                                                    <label
                                                        className="checkbox"
                                                        htmlFor="PrintReceiptonsaving"
                                                    ></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col margin-top85">
                                            <label className="margin-top-3-px">
                                                {i18n.t("payments.post_payments.printReceiptOnSaving")}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row padding-top15 justify-right margin-right25">
                                        {!paymentPK && (
                                            <div className="padding-left15">
                                                <CommonButton
                                                    variant="outlined"
                                                    onClick={() => onResetPayment()}
                                                    label={i18n.t("payments.post_payments.buttons.reset")}
                                                />
                                            </div>
                                        )}
                                        <div className="padding-left15">
                                            <CommonButton
                                                variant="contained"
                                                onClick={() => paymentPostAsCredit()}
                                                label={i18n.t("payments.post_payments.buttons.postAsCredit")}
                                            />
                                        </div>
                                        <div className="padding-left15">
                                            <CommonButton
                                                variant="contained"
                                                onClick={() => paymentPostManually()}
                                                label={i18n.t("payments.post_payments.buttons.postManually")}
                                            />
                                        </div>
                                        <div className="padding-left15">
                                            <CommonButton
                                                variant="contained"
                                                onClick={() => paymentAutoPost()}
                                                label={i18n.t("payments.post_payments.buttons.autoPost")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            <PatientPaymentDetails updatePayment={props.updatePayment} patientPK={patientPK} />
                            <PatientClaimList patientPK={patientPK} updatePayment={props.updatePayment} />
                            {(postManually || autoPost) && showContinuePosting ? (
                                <div className="row div-float-right margin-right25">
                                    <div className="div-float-right">
                                        <button
                                            type="button"
                                            onClick={() => onCancelProcedures()}
                                            className="btn btn-common-height35 px-4 btn-primary-blue margin-right10"
                                        >
                                            {i18n.t("payments.post_payments.buttons.cancel")}
                                        </button>
                                        {
                                            checkPermission(permission_key_values_payments.payments_view_payments_modify,
                                                permission_key_values_payments.post_payments) &&
                                            <button
                                                type="button"
                                                onClick={() => onSavePaymentProcedures()}
                                                className="btn btn-common-height35 px-4 btn-primary-blue"
                                            >
                                                {i18n.t("payments.post_payments.buttons.save")}
                                            </button>
                                        }
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                        </Form>
                    </div>
                </div>
            </div>
            <div className="">
                <CustomizedDialogs
                    header={header}
                    showModal={showPostAsCreditModal}
                    type="save"
                    setShowModalWindow={() => dispatch(showModalWindow({ key: "showPostAsCreditModal", open: false, header: '' }))}
                    resetForm={() => resetForm()}
                >
                    <Form
                        id="form_dataEntry"
                        autoComplete="off" onSavePostAsCreidt
                        onSubmit={(e) => onSavePostAsCreidt(e)}
                        encType="multipart/form-data"
                    >
                        Payment Has Been Posted To Patient - {patientName} <br />
                        <br />
                        Posted Credit - {currencyFormat(paymentAmount)}
                        <br />
                        Total Credits - {currencyFormat(150)}
                        <br />
                        Patient Due - {currencyFormat(150)}
                        <br />
                        Total Patient Balance - {currencyFormat(0)}
                    </Form>
                </CustomizedDialogs>
            </div>
            <CustomizedSmallDialogs
                showModal={showAlertMessage}
                header={i18n.t("commons.alert")}
                alertOK={() => onAlertOk()}
                type="alert"
                setShowModalWindow={() => dispatch(resetAlertMessage())}
            >
                {alertMessage}
            </CustomizedSmallDialogs>
        </React.Fragment>
    )
}

export default PatientPayment;