import React, { useState, useContext, useEffect } from 'react'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import i18n from '../../../utilities/i18n';
import service from '../patients/service';
import Notify from '../../commons/notify';
import LoadingContext from '../../../container/loadingContext';
import { getStorage } from '../../../utilities/browserStorage';
import { PAGING_END_INDEX, DEFAULT_PAGING_SIZE } from '../../../utilities/staticConfigs';
import Pagination from '../../commons/pagination';
import { ResizableTable } from '../../commons/resizabletable/ResizableTable';
import Moment from 'react-moment';
import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';
import './style.css'
import CommonButton from '../../commons/Buttons';
import { Typography, Grid } from '@mui/material';
import ExportIconButton from '../../commons/exportIconButton';
import { useDispatch } from 'react-redux';
import { SelectAllEvent, oncheckEvent, removeTableDataAndSelectedIDs, getImportBatchEligibiliity, runImportBatchEligibiliity } from './importBatchSlice';
import { useSelector } from 'react-redux';
import SelectInput from "../../commons/input/SelectInput";
import Chips from '../../commons/Chips';
import SummaryView from './summaryView';
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import UpdateInsuranceDetails from './UpdateInsuranceDetails';

export const ImportBatch = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.importBatch.isLoading);
    const { isError, isSuccess, message, importBatchError,
        importBatchErrorFlag } = useSelector((state) => state.importBatch);
    const previewData = useSelector((state) => state.importBatch.previewData);
    const selectedImportedFilename = useSelector((state)=>state.importBatch.selectedImportedFilename)
    const isCheckedAll = useSelector((state) => state.importBatch.isCheckedAll);
    const selectedInsurances = useSelector((state) => state.importBatch.selectedInsurances);
    const isImportBatchSuccessFlag = useSelector((state) => state.importBatch.isImportBatchSuccess);
    const setShowLoadingBar = useContext(LoadingContext);
    const [batchId, setBatchId] = useState('');
    const header = i18n.t("patientPages.insuranceInfo.addInsurance.labelInsurance");
    const [eraOpenModal, setEraOpenModal] = useState(false);
    //start ==== Alert message properties
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    const [checkEvent, setCheckEvent] = useState(false)
    const [showAlertModalWindow, setShowAlertModalWindow] = useState(false);
    const [batchImportEligibilityHistoryList, setBatchImportEligibilityHistoryList] = useState([]);
    const alertMessage = i18n.t("patientPages.insuranceInfo.alertMessageForMinimumRows");
    const practicePK = getStorage("practice") ? parseInt(getStorage("practice")) : '';
    const [importBatchErrors, setImportBatchErrors] = useState(null);
    const [showImportErrorModalWindow, setShowImportErrorModalWindow] = useState(false);
    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);
    const [batchList, setBatchList] = useState([]);
    const [htmlResponse, setHtmlResponse] = useState({});
    const [insuranceName, setInsuranceName] = useState("");

    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const updateHeader = i18n.t("Eligibility.eligibilityTab1.window.eligibilitySummaryUpdate");

    const [coPay, setCoPay] = useState("");
    const [coPayType, setCoPayType] = useState("");
    const [coInsurance, setCoInsurance] = useState("");
    const [coInsuranceType, setCoInsuranceType] = useState("");
    const [subscriberName, setSubscriberName] = useState("");
    const [deductible, setDeductible] = useState("");
    const [deductibleType, setDeductibleType] = useState("");
    const [address, setAddress] = useState("");
    const [pocketExpenseType, setPocketExpenseType] = useState("");
    const [outOfPocketMax, setOutOfPocketMax] = useState("");
    const [claimPolicyID, setClaimPolicyID] = useState("");
    const [groupNumber, setGroupNumber] = useState("");
    const [effDate, setEffDate] = useState("");
    const [effDateTrue, setEffDateTrue] = useState(false);
    const [memberName, setMemberName] = useState("");
    const [coPayTypeList, setCoPayTypeList] = useState([]);
    const [coInsuranceTypeList, setCoInsuranceTypeList] = useState([]);
    const [deductibleTypeList, setDeductibleTypeList] = useState([]);
    const [outOfPocketTypeList, setOutOfPocketTypeList] = useState([]);
    const [coPayTrue, setCoPayTrue] = useState(false);
    const [coInsuranceTrue, setCoInsuranceTrue] = useState(false);
    const [deductibleTrue, setDeductibleTrue] = useState(false);
    const [outOfPocketMaxTrue, setOutOfPocketMaxTrue] = useState(false);
    const [groupNumberTrue, setGroupNumberTrue] = useState(false);
    const [patientPk, setPatientPk] = useState("");
    // eslint-disable-next-line
    const [insuranceId, setInsuranceId] = useState("");

   // eslint-disable-next-line no-undef
    var fileDownload = require('js-file-download');
    /**
     * calling batch eligibility history data using new batch id
     */
    useEffect(() => {
        if (batchId) {
            getImportBatchHistory(DEFAULT_PAGING_SIZE, 1);
        }   
    }, [batchId])


    /**
     * show loader on api call
     */
    useEffect(() => {
        if (isLoading) {
            setShowLoadingBar(true);
        } 
        else {
            setShowLoadingBar(false);
        }
    }, [isLoading])
    /**
     * show error or sucess message after api call
     */
    
    useEffect(() => {
        if (isSuccess) {
            showNotifyWindow('show', 'success', message);
            dispatch({ type: 'importBatch/updateFieldValues', payload: { field: 'isSuccess', value: false, } });
        }
        if (isError) {
            showNotifyWindow('show', 'error', message);
            dispatch({ type: 'importBatch/updateFieldValues', payload: { field: 'isError', value: false, } });
        }
    },[isError,isSuccess])

    /**
     * getting batch id is in initial call
     */
    useEffect(() => {
        getBatchIds();
    }, [])
    
    /**
     * call all new batch ids api if the run eligibility api is a success
     */
    useEffect(() => {
        if (isImportBatchSuccessFlag) {
            getBatchIds()
            dispatch({ type: 'importBatch/updateFieldValues', payload: { field: 'isImportBatchSuccess', value: false, } });
       } 
    }, [isImportBatchSuccessFlag])

    /**import batch error will handled here */
    useEffect(() => {
        if (importBatchErrorFlag) {
            setImportBatchErrors(importBatchError);
            setShowImportErrorModalWindow(true);
            dispatch({ type: 'importBatch/updateFieldValues', payload: { field: 'importBatchErrorFlag', value: false, } });
        }
    },[importBatchErrorFlag])

    /**
     * calling batch id apis
     */

    const getBatchIds=()=>{
        const result = service.GetBatchIds(practicePK, true);
        setShowLoadingBar(true);
        result.then(response => {
            setShowLoadingBar(false);
            if (response.data.length > 0) {
                setBatchId(response.data[0].id);
                setBatchList(response.data);
            }
        })
    }
    



    function onPagePrevious() {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        setActivePage(previousPage);
        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        getImportBatchHistory(DEFAULT_PAGING_SIZE, previousPage);

    }

    function onPageUp(e) {
        let page = Number(e.target.id)
        setActivePage(page);
        getImportBatchHistory(DEFAULT_PAGING_SIZE, page);
    }
    

    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);
        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);
        }
        getImportBatchHistory(DEFAULT_PAGING_SIZE, nextPage);
    }
    //Pagination ends

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === 'show') {
            setShowNotify(action);
            setNotifyType(type);
            setNotifyDescription(desc);
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }

    }
    //End ====

    async function onHandleCSVChange(e) {
      
        setShowLoadingBar(true);
        dispatch(removeTableDataAndSelectedIDs());
        const value = e.target.type === "file" ? e.target.files[0] : e.target.value;
        dispatch(getImportBatchEligibiliity({ value: value, type: e.target.type, e: e, practice_pk: practicePK }));
       
    }

    function onHandleCsvImport(e) {
        e.preventDefault();
        document.getElementById('file').value = null;
        document.getElementById('file').click();
    }

    // Function to download import batch error rows details
    function onDownloadCSV(e) {

        const csvContent = [];

        csvContent.push('Row,Error Messages'); // CSV Headers

        // Mapping through error object and passing error strings saperated by comma
        Object.keys(importBatchErrors).forEach((key) => {
            const errorMessages = importBatchErrors[key].join(';');
            csvContent.push(`${key},"${errorMessages}"`);
        });

        // Convert the CSV content to a Blob
        const csvBlob = new Blob([csvContent.join('\n')], { type: 'text/csv;charset=utf-8' });
        var contentType = "text/csv";
        e.target.href = window.URL.createObjectURL(csvBlob);
        // Download the csv
        e.target.dataset.downloadurl = [
            contentType,
            e.target.download,
            e.target.href,
        ].join(":");
    }

    const oncheck = (e, item) => {    
        dispatch(oncheckEvent({ item: item, checked: e.target.checked }))
        setCheckEvent(!checkEvent)
    }
    const onSelectAll = (e) => {
        dispatch(SelectAllEvent({ checked: e.target.checked }));
        setCheckEvent(!checkEvent)
    }
    useEffect(() => {
        if (previewData && previewData.length > 0) {
            setCheckEvent(!checkEvent)
        }
        setCheckEvent(!checkEvent)
    }, [JSON.stringify(previewData)]);

    const runBatchEligibility = () => {
        setShowLoadingBar(true);
        if (selectedInsurances.length < 4) {
            setShowAlertModalWindow(true);
            setShowLoadingBar(false);
        } else {
            setBatchId('')
            setBatchList([]);
            dispatch(runImportBatchEligibiliity({ practice_id: practicePK }))
            document.getElementById('file').value = '';
        }

    }
    function onAlertOk() {
        setShowAlertModalWindow(false);
    }

    function getImportBatchHistory(page_size, page) {
        setShowLoadingBar(true);
        const result = service.GetImportBatchHistory(page_size, page, batchId);
        result.then(response => {
            if (response.data.results !== undefined) {
                setTotalPage(Math.ceil(response.data.count / response.data.page_size)); 
            }
            setBatchImportEligibilityHistoryList(response.data.results)
            setShowLoadingBar(false);
        })
    }

    const onHandleBatchIdChange = (e) => {
        setBatchId(e.target.value);
    }

    function onHandleCSVExportBatch() {
        setShowLoadingBar(true);
        let query = '&export=true&export_type=csv';
        const result = service.BatchEligibilityHistory(0, 0, batchId, query);
        result.then(response => {
            fileDownload(response.data, response.headers['content-disposition']);
            setShowLoadingBar(false);
        });
    }

    function onHandleXLSExportBatch() {
        setShowLoadingBar(true);
        let query = '&export=true&export_type=xls';
        const result = service.BatchEligibilityHistory(0, 0, batchId, query, 'xls');
        result.then(response => {
            fileDownload(response.data, response.headers['content-disposition']);
            setShowLoadingBar(false);
        });
    }

    function showEBResponseSummary(e, id, patient_id, insurance_name) {
        // eslint-disable-next-line
        setInsuranceName(insurance_name)
        setShowLoadingBar(true);
        const result = service.ShowBatchResponseHtml(id);
        result.then(response => {
            setShowLoadingBar(false);
            setHtmlResponse(response.data.html_response);
            setPatientPk(patient_id)
            setInsuranceId(batchImportEligibilityHistoryList.find(x => x.id === id).insurance_info_id);
            setEraOpenModal(true);
        })
    }

    function getExtractedData() {
        resetUpdateForm();
        setShowLoadingBar(true);
        let path = 'patient/extract-elg-txt-db/?id=' + htmlResponse.id + '&checking_type=batch' + '&subscriber_pk=' + htmlResponse.subscriber_pk;
        const result = service.GetExtractedData(path);
        result.then(response => {
            setShowLoadingBar(false);
            if (Object.keys(response.data).length > 0) {
                let coPayTypeArr = [];
                response.data && response.data.co_payment?.map((item, index) => {
                    coPayTypeArr.push({ "id": (index + 1).toString(), "name": item.name, "co_payment": item.co_payment })
                })
                setCoPayTypeList(coPayTypeArr);
                let coInsuTypeList = [];
                response.data && response.data.co_insurance?.map((item, index) => {
                    coInsuTypeList.push({ "id": (index + 1).toString(), "name": item.name, "co_insurance": item.co_insurance })
                })
                setCoInsuranceTypeList(coInsuTypeList)
                let deductibleList = [];
                response.data && response.data.deductible?.map((item, index) => {
                    deductibleList.push({ "id": (index + 1).toString(), "name": item.name, "deductible": item.deductible })
                })
                setDeductibleTypeList(deductibleList);
                let outOfPocketList = [];
                response.data && response.data.out_of_pocket?.map((item, index) => {
                    outOfPocketList.push({ "id": (index + 1).toString(), "name": item.name, "out_of_pocket": item.out_of_pocket })
                })

                setOutOfPocketTypeList(outOfPocketList);
                if (response.data) {
                    setSubscriberName(response.data.subscriber);
                    setClaimPolicyID(response.data.member_id);
                    setGroupNumber(response.data.group_number);
                    setMemberName(response.data.member_name);
                    setAddress(response.data.address);
                    setEffDate(response.data.plan_date ? new Date(response.data.plan_date) : "");
                }
                setOpenUpdateModal(true);
            }

        })
    }

    function onSaveFormData() {
        setShowLoadingBar(true);
        let data = {}
        if (groupNumberTrue)
            data["group_number"] = groupNumber;
        if (coPayTrue)
            data["copay"] = coPay
        if (coInsuranceTrue)
            data["co_insurance"] = coInsurance;
        if (outOfPocketMaxTrue)
            data["out_of_pocket"] = outOfPocketMax;
        if (deductibleTrue) {
            data["deductibles"] = deductible;
        }
        if (Object.keys(data).length > 0) {
            data["patient_pk"] = patientPk
            data["policy_id"] = claimPolicyID
            const result = service.UpdateInsuranceInfo(data);
            result.then(response => {
                setShowLoadingBar(false);
                setOpenUpdateModal(false);
                if (response.status === 200) {
                    showNotifyWindow('show', 'success', i18n.t("Eligibility.eligibilityTab1.InsuranceDataUpdated"));
                } else {
                    showNotifyWindow('show', 'error', i18n.t("Eligibility.eligibilityTab1.InsuranceDataUpdateFailed"));
                }

            })
        }
        setShowLoadingBar(false);
    }

    function resetUpdateForm() {
        setMemberName("");
        setCoPayTypeList([]);
        setCoInsuranceTypeList([])
        setDeductibleTypeList([]);
        setOutOfPocketTypeList([]);
        setSubscriberName("");
        setClaimPolicyID("");
        setGroupNumber("");
        setEffDate("");
        setMemberName("");
        setCoPay("");
        setCoInsurance("");
        setSubscriberName("");
        setDeductible("");
        setAddress("");
        setOutOfPocketMax("");
        setGroupNumber("");
        setCoPayTrue(false);
        setCoPayType(false);
        setCoPay("")
        setCoInsuranceTrue(false);
        setCoInsuranceType(false);
        setCoInsurance("")
        setDeductibleTrue(false);
        setDeductibleType("");
        setDeductible("")
        setOutOfPocketMaxTrue(false);
        setPocketExpenseType("");
        setOutOfPocketMax("")
        setClaimPolicyID("");
        setGroupNumberTrue(false);
        setEffDateTrue(false);
        setEffDateTrue(false);
        setOpenUpdateModal(false);
    }

    function onUpdateHide() {
        setOpenUpdateModal(false);
    }

    function onHandleChangeEligibilityUpdate(e) {
        if (e.target) {
            let name = e.target.name;
            let value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
            let inputFields = ["insuranceName", "policyId", "serviceType", "provider", "memberName", "coPay", "coInsurance", "subscriberName", "deductible", "eligiblityDate", "address", "outOfPocketMax", "groupNumber", "coPayTrue", "coPayType", "coInsuranceTrue", "coInsuranceType", "deductibleTrue",
                "deductibleType",
                "outOfPocketMaxTrue",
                "pocketExpenseType",
                "claimPolicyID",
                "groupNumberTrue",
                "effDateTrue",
                "outOfPockedTrue"];

            const trimmedValue = inputFields.includes(name) ? value : "";
            switch (name) {
                case "insuranceName":
                    setInsuranceName(trimmedValue);
                    break;
                case "memberName":
                    setMemberName(trimmedValue);
                    break;
                case "coPay":
                    setCoPay(trimmedValue);
                    break;
                case "coInsurance":
                    setCoInsurance(trimmedValue);
                    break;
                case "subscriberName":
                    setSubscriberName(trimmedValue);
                    break;
                case "deductible":
                    setDeductible(trimmedValue);
                    break;
                case "address":
                    setAddress(trimmedValue);
                    break;
                case "outOfPocketMax":
                    setOutOfPocketMax(trimmedValue);
                    break;
                case "groupNumber":
                    setGroupNumber(trimmedValue);
                    break;
                case "coPayTrue":
                    setCoPayTrue(trimmedValue);
                    break;
                case "coInsuranceTrue":
                    setCoInsuranceTrue(trimmedValue);
                    break;

                case "coPayType":
                    setCoPayType(trimmedValue);
                    if (trimmedValue !== "")
                        setCoPay(coPayTypeList[parseInt(trimmedValue) - 1].co_payment)
                    break;
                case "coInsuranceType":
                    setCoInsuranceType(trimmedValue);
                    if (trimmedValue !== "")
                        setCoInsurance(coInsuranceTypeList[parseInt(trimmedValue) - 1].co_insurance)
                    break;

                case "deductibleTrue":
                    setDeductibleTrue(trimmedValue)
                    break;
                case "deductibleType":
                    setDeductibleType(trimmedValue);
                    if (trimmedValue !== "")
                        setDeductible(deductibleTypeList[parseInt(trimmedValue) - 1].deductible)
                    break;
                case "outOfPocketMaxTrue":
                    setOutOfPocketMaxTrue(trimmedValue)
                    break;
                case "pocketExpenseType":
                    setPocketExpenseType(trimmedValue);
                    if (trimmedValue !== "")
                        setOutOfPocketMax(outOfPocketTypeList[parseInt(trimmedValue) - 1].out_of_pocket)
                    break;

                case "claimPolicyID":
                    setClaimPolicyID(trimmedValue);
                    break;
                case "groupNumberTrue":
                    setGroupNumberTrue(trimmedValue);
                    break;
                case "effDateTrue":
                    setEffDateTrue(trimmedValue);
                    break;
                case "outOfPockedTrue":
                    setEffDateTrue(trimmedValue);
                    break;
                default:
                    break;
            }

        }
    }

    function onHandleEffDate(selected) {
        setEffDate(selected);
    }

    return (
        <React.Fragment>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <Form id="form_patient_info" className='batch-eligibility-box' autoComplete="off" style={{ paddingBottom: "100px" }}>
                <div className="row">
                    <div className="col-4" style={{ display: 'flex', alignItems: 'flex-end' }}>
                        
                        <Typography
                            sx={{
                                color: '#212121',
                                fontFeatureSettings: "'clig' off, 'liga' off",
                                fontFamily: 'Lato',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '20px',
                            }}
                        >
                           {selectedImportedFilename ? 'List Imported from ' : 'List Imported'}
                           {selectedImportedFilename?
                             <span style={{fontSize:'15px',color:'#1479bb',fontStyle: 'normal'}}>
                               {selectedImportedFilename}
                             </span>
                           :""}
                            
                        </Typography>
                       
                    </div>
                    <div className="col-8">
                        <div className="div-float-right margin-top3">
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="download-tooltip" style={{
                                    opacity: 0.4,
                                }}>{i18n.t('commons.importCSV')}
                                </Tooltip>}
                            >
                                <CommonButton icon="upload" variant="outlined" label="Import" onClick={(e) => onHandleCsvImport(e)} />
                            </OverlayTrigger>
                            <input name="csvFile" type="file" accept=".csv" id="file" onChange={onHandleCSVChange} style={{ display: "none" }} />
                        </div>
                    </div>
                </div>
                <div className="form-group margin-top10">
                    <ResizableTable className="table" id={"importBatchTab1"}
                        tableHeadings={
                            <tr>
                                <th className='longTableHead'>{i18n.t("Eligibility.importBatch.patientName")}<span className="resize-handle"></span></th>
                                <th className='shortTableHead'>{i18n.t("Eligibility.importBatch.date")}<span className="resize-handle"></span></th>
                                <th className='shortTableHead'>{i18n.t("Eligibility.importBatch.insurance")}<span className="resize-handle"></span></th>
                                <th className='shortTableHead'>{i18n.t("Eligibility.importBatch.policyId")}<span className="resize-handle"></span></th>
                                <th className='shortTableHead'>{i18n.t("Eligibility.importBatch.provider")}<span className="resize-handle"></span></th>
                                <th className='max-width_21percent'>{""}<span className="resize-handle"></span>
                                    {previewData && previewData.length > 0 &&
                                        <div className="form-groupp lh-0">
                                            <div className="custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    id={'selectAll'}
                                                    name={'selectAll'}
                                                    checked={isCheckedAll}
                                                    onChange={(e) => onSelectAll(e)}
                                                />
                                                <label
                                                    className="checkbox"
                                                    htmlFor={'selectAll'}
                                                ></label>
                                            </div>
                                        </div>}
                                </th>
                            </tr>
                        }
                        tableRows={
                            previewData && previewData.length === 0 ?
                                <tr>
                                    <td align="center" colSpan={6}>{i18n.t('commons.noRecords')}</td>
                                </tr>
                                :
                                previewData && previewData.map((item, index) => {
                                    return (<tr key={item.id}>
                                        <td>
                                            <Typography
                                                sx={{
                                                    color: '#1479BB',
                                                    fontFeatureSettings: "'clig' off, 'liga' off",
                                                    lineHeight: 'normal',
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                {item.last_name + ", " + item.first_name}
                                            </Typography>
                                        </td>
                                        <td align="center"><Moment format='MM/DD/YYYY'>{item.dob}</Moment></td>
                                        <td>{item.payer_name}</td>
                                        <td align="center">{item.member_id}</td>
                                        <td>{item.provider_name}</td>
                                        <td align="center">
                                            {item.provider_name && item.payer_name && item.member_id &&
                                                <div className="form-groupp lh-0">
                                                    <div className="custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            id={'chk_' + index}
                                                            name={'chk_' + index}
                                                            checked={item.isChecked ? item.isChecked : false}
                                                            onChange={(e) => oncheck(e, item)}
                                                        />
                                                        <label
                                                            className="checkbox"
                                                            htmlFor={'chk_' + index}
                                                        ></label>
                                                    </div>
                                                </div>}
                                        </td>
                                    </tr>)
                                })
                        }
                    />
                </div>
                <div className="form-group">
                    <button type="button" onClick={runBatchEligibility} className="btn btn-primary-blue">{i18n.t("Eligibility.importBatch.runBatchElgblty")}</button>
                </div>
                    <Grid container spacing={2} sx={{ marginBottom: '5px' }}>
                        <Grid item xs={9} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-start' }}>
                            <Typography
                                sx={{
                                    color: '#212121',
                                    fontFeatureSettings: "'clig' off, 'liga' off",
                                    fontFamily: 'Lato',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: '20px',
                                }}
                            >
                                {i18n.t("Eligibility.importBatch.batchElgbltyRslt")}
                            </Typography>
                        </Grid>
                        <Grid item xs={3} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                            <div style={{ flex: '0 0 90%', marginRight: '10px', marginBottom: '-13px', display: 'flex', justifyContent: 'flex-end' }}>
                                <SelectInput selectOptionRemove={true} data={batchList} required={true} name="batchIdList" id="batchIdList" value={batchId} setValue={setBatchId} onValueChange={(selected) => onHandleBatchIdChange(selected)} label={i18n.t("Eligibility.batchEligibility.batchId")} />
                            </div>
                            <div className="dropdown" id="grid-export-dropdown" style={{ flex: '0 0 5%', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                                <ExportIconButton
                                    disabled={batchImportEligibilityHistoryList.length <= 0 ? true : false}
                                    onExportCsv={onHandleCSVExportBatch}
                                    onExportXls={onHandleXLSExportBatch}
                                />
                            </div>
                        </Grid>
                </Grid>
                <div className="table-responsive">
                    <ResizableTable id={"importBatchTab2"} className=' fixed_head'
                        tableHeadings={
                            <tr>
                                <th className='longTableHead'>{i18n.t("Eligibility.importBatch.patientName")}<span className="resize-handle"></span></th>
                                <th className='shortTableHead'>{i18n.t("Eligibility.importBatch.dob")}<span className="resize-handle"></span></th>
                                <th className='shortTableHead'>{i18n.t("Eligibility.importBatch.insurance")}<span className="resize-handle"></span></th>
                                <th className='shortTableHead'>{i18n.t("Eligibility.importBatch.policyId")}<span className="resize-handle"></span></th>
                                <th className='shortTableHead'>{i18n.t("Eligibility.importBatch.summary")}<span className="resize-handle"></span></th>
                            </tr>
                        }
                        tableRows={
                            (batchImportEligibilityHistoryList && batchImportEligibilityHistoryList.length) ? batchImportEligibilityHistoryList.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <Typography
                                                sx={{
                                                    color: '#1479BB',
                                                    fontFeatureSettings: "'clig' off, 'liga' off",
                                                    lineHeight: 'normal',
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                {item.patient_name}
                                            </Typography>
                                        </td>
                                        <td align="center"><Moment format='MM/DD/YYYY'>{item.dob}</Moment></td>
                                        <td>{item.insurance_name}</td>
                                        <td>{item.policy_id}</td>
                                        <td style={{
                                            textAlign: 'center',
                                            verticalAlign: 'middle',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            {item.status === 1 ?
                                                <Chips type="processing" /> :
                                                item.status === 2 ? <Chips type="summary" onClick={(e) => showEBResponseSummary(e, item.id, item.patient_id, item.insurance_name)} />
                                                    : <Chips type="noPayer" />
                                            }
                                        </td>
                                    </tr>
                                )
                            }) : <tr>
                                <td align="center" colSpan={7}>{i18n.t('commons.noRecords')}</td>
                            </tr>
                        }
                    />

                </div>
                <div>
                    <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                    {totalPage <= 1 && <div className="clear-b-scroll-sector">&nbsp;</div>}
                </div>
                

            </Form>
            <CustomizedDialogs header={header} update={getExtractedData} eligibility={(htmlResponse?.status_info?.request_status === "active" && htmlResponse?.subscriber_pk) ? true : false} showModal={eraOpenModal} setShowModalWindow={setEraOpenModal}>
                <SummaryView htmlResponse={htmlResponse} insuranceName={insuranceName} />
            </CustomizedDialogs>
            <CustomizedSmallDialogs showModal={showAlertModalWindow} header={i18n.t("commons.alert")} alertOK={onAlertOk}  type="alert"  setShowModalWindow={setShowAlertModalWindow}>
                {alertMessage}
            </CustomizedSmallDialogs>
            <UpdateInsuranceDetails
                openUpdateModal={openUpdateModal}
                setOpenUpdateModal={setOpenUpdateModal}
                onSaveFormData={onSaveFormData}
                updateHeader={updateHeader}
                onUpdateHide={onUpdateHide}
                resetUpdateForm={resetUpdateForm}
                onHandleChange={onHandleChangeEligibilityUpdate}
                memberName={memberName}
                setCoPayType={setCoPayType}
                coPayTrue={coPayTrue}
                coPayTypeList={coPayTypeList}
                subscriberName={subscriberName}
                coInsuranceTrue={coInsuranceTrue}
                coPay={coPay}
                coPayType={coPayType}
                coInsurance={coInsurance}
                coInsuranceType={coInsuranceType}
                coInsuranceTypeList={coInsuranceTypeList}
                setCoInsuranceType={setCoInsuranceType}
                deductibleTypeList={deductibleTypeList}
                address={address}
                deductibleType={deductibleType}
                setDeductibleType={setDeductibleType}
                deductibleTrue={deductibleTrue}
                deductible={deductible}
                claimPolicyID={claimPolicyID}
                outOfPocketTypeList={outOfPocketTypeList}
                pocketExpenseType={pocketExpenseType}
                setPocketExpenseType={setPocketExpenseType}
                outOfPocketMaxTrue={outOfPocketMaxTrue}
                outOfPocketMax={outOfPocketMax}
                groupNumberTrue={groupNumberTrue}
                groupNumber={groupNumber}
                effDateTrue={effDateTrue}
                onHandleEffDate={onHandleEffDate}
                effDate={effDate}
            />
            {/* Batch CSV Import Error Message Modal */}
            <CustomizedSmallDialogs
                showModal={showImportErrorModalWindow}
                header={i18n.t("commons.importErrorHeader")}
                alertOK={() => setShowImportErrorModalWindow(false)}
                type="alert"
                setShowModalWindow={setShowImportErrorModalWindow}
                onDownload={onDownloadCSV}
            >
                {importBatchErrors && Object.keys(importBatchErrors).length > 0 && (
                    <>
                        <div>{i18n.t("errorMessages.row_not_imported")}</div>
                        <div>&nbsp;</div>
                        <table style={{ margin: '0 auto' }}>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center' }}>Row</th>
                                    <th style={{ textAlign: 'center' }}>Error Messages</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(importBatchErrors).map((key) => (
                                    <tr key={key}>
                                        <td style={{ textAlign: 'center' }}>{key}</td>
                                        <td style={{ textAlign: 'center' }}>
                                            <div style={{ padding: '10px', textAlign: 'left' }}>
                                                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                                    {importBatchErrors[key].map((errorMessage) => (
                                                        <li key={errorMessage}>{errorMessage}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>
                )}
            </CustomizedSmallDialogs>

        </React.Fragment >
    )
}


