import React, { useState, useEffect, useContext } from 'react';
import { Form } from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs'
import service from './service'
import { ROUTE_SUPER_DICTIONARIES, PAGING_END_INDEX, DEFAULT_PAGING_SIZE, REVENUE_CODE_CSV_NAME } from '../../../utilities/staticConfigs';
import { ADD_SUCCESS, ADD_ERROR, UPDATE_SUCCESS, DELETE_SUCCESS } from '../../../utilities/labelConfigs';
import Notify from '../../commons/notify';
import Pagination from '../../commons/pagination';
import LoadingContext from '../../../container/loadingContext'
import i18n from '../../../utilities/i18n';
import TextInput from '../../commons/input/input';
import { csvFileCheck } from '../../../utilities/validations';
import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';
import { checkPermission, commonTableBody } from '../../../utilities/commonUtilities';
import { RevenueCodeTableData } from './RevenueCodeTable';
import Table from '../../commons/Table/Table';
import BackArrowWithLabel from "../../commons/Back"; 
import CommonButton from "../../commons/Buttons";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { super_admin_permission_key_value, super_admin_privileges } from '../../../utilities/permissions';

const RevenueCode = (props) => {
	const [showModalWindow, setShowModalWindow] = useState(false);
	const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
	const [revenueCode, setRevenueCode] = useState('');
	const [description, setDescription] = useState('');
	const [deleteRevenueCode, setDeleteRevenueCode] = useState('');
	const [deleteRevenueCodeId, setDeleteRevenueCodeId] = useState('');
	const [editRevenueCodeId, setEditRevenueCodeId] = useState('');
	const [revenueCodeList, setRevenueCodeList] = useState([]);
	const [form_error, setFormError] = useState({ 'revenueCode': '', 'description': '' });
	const [editForm, setEditForm] = useState(false);
	const [header, setHeader] = useState('')
	const [searchValue, setSearchValue] = useState('');
	const [searchValueClose, setSearchValueClose] = useState(0);
	const [orderType, setOrderType] = useState('');
	const [orderingField, setOrderingField] = useState('name'); /* show the triangle only in clicked header */
	const [permission, setPermission] = useState(false);



	//start ==== Alert message properties
	const [showNotify, setShowNotify] = useState('hide');
	const [notifyDescription, setNotifyDescription] = useState('');
	const [notifyType, setNotifyType] = useState('success');
	const setShowLoadingBar = useContext(LoadingContext);
	const [initialOrdering, setInitialOrdering] = useState(true);
  
	RevenueCodeTableData.tableHeadings[0].initialOrdering  = initialOrdering;
	RevenueCodeTableData.tableHeadings[0].orderType = orderType;

	function showNotifyWindow(action, type, desc, age = 3000) {
		if (action === 'show') {
			setTimeout(() => {
				setShowNotify('hide');
			}, age)
		}
		setShowNotify(action);
		setNotifyType(type);
		setNotifyDescription(desc);

	}
	// end

	//Pagination start
	const [totalPage, setTotalPage] = useState(1);
	const [activePage, setActivePage] = useState(1);
	const [startIndex, setStartIndex] = useState(0);
	const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

	function onPagePrevious() {
		let previousPage = startIndex + 1 - PAGING_END_INDEX;
		setActivePage(previousPage);

		if (startIndex !== 0) {
			setStartIndex(startIndex - PAGING_END_INDEX);
			setEndIndex(endIndex - PAGING_END_INDEX);
		}
		getRevenueCodeData(DEFAULT_PAGING_SIZE, previousPage, orderingField);
	}

	function onPageUp(e) {
		let page = Number(e.target.id)
		setActivePage(page);
		getRevenueCodeData(DEFAULT_PAGING_SIZE, page, orderingField);
	}

	function onPageNext() {
		let nextPage = startIndex + 1 + PAGING_END_INDEX;
		if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
			setActivePage(nextPage);
			setStartIndex(startIndex);
			setStartIndex(endIndex);

		} else {
			setActivePage(nextPage);
			setStartIndex(startIndex + PAGING_END_INDEX);
			setEndIndex(endIndex + PAGING_END_INDEX);

		}
		getRevenueCodeData(DEFAULT_PAGING_SIZE, nextPage, orderingField);

	}
	//Pagination ends

	function addNew() {
		if (!permission) {
			showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'));
			return;
		}
		setHeader(i18n.t("dictionariesPages.dictRevenueCode.addHeader"));
		setShowModalWindow(true);
	}

	function onHide() {
		setEditForm(false);
	}
	function onDeleteHide() {
		setDeleteRevenueCode('');
		setDeleteRevenueCodeId('');
		setShowDeleteModalWindow(false);
	}

	function getRevenueCodeData(pageSize, page, fieldName, sorting) {
		let fieldOrderType = '';
		if (sorting) {
			if (!orderType) fieldOrderType = '-';
		} else {
			fieldOrderType = orderType;
		}
		setShowLoadingBar(true);
		const result = service.GetListRevenueCode(pageSize, page, searchValue, fieldName, fieldOrderType);
		result.then(response => {
			if (response.data.results !== undefined) {
				setTotalPage(Math.ceil(response.data.count / response.data.page_size));
			}
			const rowArray = commonTableBody(response.data.results, RevenueCodeTableData.tableBodyData[0]);
			RevenueCodeTableData.tableBodyData = rowArray;
			setRevenueCodeList(response.data.results);
			setShowLoadingBar(false);

		});
	}

	useEffect(() => {
		getRevenueCodeData(DEFAULT_PAGING_SIZE, activePage);
		setPermission(checkPermission(super_admin_privileges.super_admin_full_privilege,super_admin_permission_key_value.dictionary_add));
	}, [searchValueClose]);

	function onEditRevenueCode(id) {
		setShowLoadingBar(true);
		const data = service.GetRevenueCode(id);
		data.then(response => {
			setShowLoadingBar(false);
			setHeader(i18n.t("dictionariesPages.dictRevenueCode.editHeader"));
			setShowModalWindow(true);
			setRevenueCode(response.data.name);
			setDescription(response.data.description);
			setEditForm(true);
			setEditRevenueCodeId(id);
		});
	}
	function onDeleteRevenueCode(id) {
		revenueCodeList.map((item) => {
			if (item.id === parseInt(id)) {
				setDeleteRevenueCode(item.name);
				setDeleteRevenueCodeId(item.id);
				setShowDeleteModalWindow(true);
			}

		});
	}

	function onDeleteAction() {
		setShowLoadingBar(true);
		const data = service.DeleteRevenueCode(deleteRevenueCodeId);
		data.then(() => {
			setShowLoadingBar(false);
			setDeleteRevenueCode('');
			setDeleteRevenueCodeId('');
			setShowDeleteModalWindow(false);
			showNotifyWindow('show', 'success', DELETE_SUCCESS);
			getRevenueCodeData(DEFAULT_PAGING_SIZE, activePage);
		});
	}

	function resetForm() {
		setRevenueCode('');
		setDescription('');
		setEditForm(false);
		setFormError({
			...form_error,
			['revenueCode']: '',
			['description']: ''
		});
		setSearchValueClose(0)
	}
	function onSaveFormData(e) {
		e.preventDefault();
		setFormError({
			...form_error,
			['revenueCode']: !revenueCode.trim() ? 'error' : '',
			['description']: !description ? 'error' : ''
		});
		if (revenueCode && description) {
			setShowLoadingBar(true);
			let result = null;
			result=editForm ? service.UpdateRevenueCode(editRevenueCodeId, { name: revenueCode, description: description }):
			service.AddRevenueCode({ name: revenueCode, description: description });

			result.then(response => {
				setShowLoadingBar(false);
				if (response.status === 201 || response.status === 200) {
					if (response.status === 201)
						showNotifyWindow('show', 'success', ADD_SUCCESS);
					else showNotifyWindow('show', 'success', UPDATE_SUCCESS);

					getRevenueCodeData(DEFAULT_PAGING_SIZE, activePage);
					resetForm();
					setShowModalWindow(false);

				} else if (response.status === 400 && response.data.record_already_exists !== undefined) {
					showNotifyWindow('show', 'error', i18n.t('errorMessages.record_exists'));
				} else {
					showNotifyWindow('show', 'error', ADD_ERROR);
				}


			});
		}

	}
	function onHandleChange(e) {
		const { name } = e.target;
		let value = e.target.value;
		let trimmedValue = '';
		if (e.target.type === "text") {
			if (value === "" || e.target.validity.valid)
				trimmedValue = value;
			else {
				trimmedValue = revenueCode;
			}
		} else {
			trimmedValue = value;
		}

		if (!trimmedValue) {
			setFormError({
				...form_error,
				[name]: 'error'
			});
		} else {
			setFormError({
				...form_error,
				[name]: ''
			});
		}
		if (name === 'revenueCode')
			setRevenueCode(trimmedValue);
		else if (name === 'searchValue') setSearchValue(trimmedValue);
		else { setDescription(trimmedValue) }
	}

	function backToDictionaryPage() {
		props.history.push(ROUTE_SUPER_DICTIONARIES)
	}

	function onHandleCsvImport(e) {
		e.preventDefault();
		document.getElementById('file').click();
	}

	function onHandleCSVChange(e) {
		const value =
			e.target.type === "file" ? e.target.files[0] : e.target.value;

		if (e.target.type === "file") {
			if (e.target.files[0].name !== REVENUE_CODE_CSV_NAME) {
				showNotifyWindow('show', 'error', i18n.t('errorMessages.invalid_file_name'));
			} else if (csvFileCheck(e.target.files[0].name)) {
				const data = new FormData();
				data.append('file', value);
				let result = service.ImportCSVData(data);
				result.then(response => {
					setShowLoadingBar(false);
					if (response.data.message !== undefined) {
						if (response.data.message === 'no_file_records') {
							showNotifyWindow('show', 'error', i18n.t('errorMessages.empty_file'));
						} else if (response.data.message === 'invalid_headings') {
							showNotifyWindow('show', 'error', i18n.t('errorMessages.invalid_headings'));
						} else if (response.data.message === 'data_imported') {
							if (response.data.existing_name && response.data.existing_name.length > 0) {
								showNotifyWindow('show', 'warning', i18n.t('errorMessages.record_not_imported') + ' \n ' + response.data.existing_name.join(',\n'));
							} else {
								showNotifyWindow('show', 'success', i18n.t('validations.success.import_success'));
							}
							getRevenueCodeData(DEFAULT_PAGING_SIZE, activePage);
						}
					} else {
						showNotifyWindow('show', 'error', i18n.t('errorMessages.import_failed'));
					}
				});
			} else {
				showNotifyWindow('show', 'error', i18n.t('errorMessages.invalid_file_format'));
			}
		}
		document.getElementById('file').value = '';
	}

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			onSearchRevenue(e);
		}
	}


	function onSearchRevenue(e, fieldName) {
		e.preventDefault();
		setActivePage(1)
		setStartIndex(0);
		setEndIndex(PAGING_END_INDEX);
		if (!fieldName) { // if not field name then call from ,search button click
			setInitialOrdering(true);
			setOrderType('');
		}
		setTotalPage(0);
		getRevenueCodeData(DEFAULT_PAGING_SIZE, 1, fieldName, true);
	}

	function onSortingRevenue(e, fieldName) {
		e.preventDefault();
		setStartIndex(0);
		setEndIndex(PAGING_END_INDEX);
		if (!fieldName) { // if not field name then call from ,search button click
			setInitialOrdering(true);
			setOrderType('');
		}
		setTotalPage(0);
		getRevenueCodeData(DEFAULT_PAGING_SIZE, activePage, fieldName, true);
	}

	const tableSorting = (e) => {
		setInitialOrdering(false);
		RevenueCodeTableData.tableHeadings[0].initialOrdering = false;
		let name = 'name';
		setOrderingField(name);
		if (!orderType) {
			RevenueCodeTableData.tableHeadings[0].orderType = '-';
			setOrderType('-');
		}
		else {
			RevenueCodeTableData.tableHeadings[0].orderType = '';
			setOrderType('');
		}
		onSortingRevenue(e, name);
	}

	function dropDownFunction(id, name) {
		if (!permission) {
			showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'));
			return;
		}
		if (name.toLowerCase() == 'edit') {
			onEditRevenueCode(Number(id));
		}
		if (name.toLowerCase() == 'delete') {
			onDeleteRevenueCode(Number(id));
		}
	}

	return (
		<React.Fragment>
			<Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
			<div className="col-md-12">
				<div className="box box-content-white">
                    <div className="row div-backto-dictionaties">
                        <div className={'dataTables_filter'}>
                            <div className="link dictionaries-back pl-0" onClick={backToDictionaryPage} >
                                <BackArrowWithLabel label={i18n.t("dictionariesPages.dictionaries")} /> 
                            </div>
                        </div>
                    </div>
				
                    <div className="box-content">
                        <div className="common-search-wrapper-style">
                            <div className="input-content-box">
                                <div className="form-group padding-top15">
                                    <TextInput type="text" name="searchValue" 
									value={searchValue}
									onValueChange={onHandleChange} 
									label={i18n.t("dictionariesPages.dictRevenueCode.codeOrDescription")} 
									onKeyDown={handleKeyDown} />
									{(searchValue.length !== 0) && <IconButton
                                     className="templateIconBtn_close"
										onClick={() => {
										setSearchValue('');
										setActivePage(1);
										setSearchValueClose(searchValueClose + 1)
										}} >
										<CloseIcon sx={{ fontSize: "small", color: '#9093a4' }} />
									</IconButton>
									}
                                </div>
                            </div>
                            <div className="padding-top25">
                                <div className="input-content-box padding-top15">
                                    <button type="button" onClick={(e) => onSearchRevenue(e)} className="btn btn-primary-blue">{i18n.t("dictionariesPages.dictRevenueCode.search")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
				</div>
				
				<div className="box box-content-white margin-top10">	
					<div className="width-100-perc">
                        <div className="alignRight ">
                            <div className="alignRight padding-top5 padding-bottom10 width-100-perc ">
                                <OverlayTrigger
									placement="top"
									delay={{ show: 250, hide: 400 }}
									overlay={<Tooltip id="download-tooltip" style={{
										opacity: 0.4,
									}}>{i18n.t('commons.importCSV')}
									</Tooltip>}
								>
									<CommonButton icon="download" variant="outlined" label="Import" onClick={(e) => onHandleCsvImport(e)}  />
								</OverlayTrigger>
								<input name="csvFile" type="file" accept=".csv" id="file" data-testid='csv-upload-input' onChange={onHandleCSVChange} style={{ display: "none" }} /> 
								<span className='ml-3'> <CommonButton variant='contained' onClick={addNew} label={i18n.t("buttons.addNew")}/></span>
                            </div>
                        </div>
                    </div>  

                    <div className="width-100-perc">          
                        <div className="" style={{ overflowX: "auto", width: "100%"}}>
							<Table tableObject={RevenueCodeTableData} dropDownFunction={dropDownFunction} sortingFunction={tableSorting} />
                        </div>  

                        <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                        <div className="clear-b-scroll-sector">&nbsp;</div>
                        <div className="clear-b-scroll-sector">&nbsp;</div>
                    </div>  
                </div>
			</div>
			<CustomizedDialogs showModal={showModalWindow} type="save" header={header} setShowModalWindow={setShowModalWindow} resetForm={resetForm} onHide={onHide}>
				<Form id="form_dataEntry" onSubmit={(e) => onSaveFormData(e)} encType="multipart/form-data">
					<Form.Group>
						<div className="row">
							<div className="col-md-12">
								<div className="form-group padding-top15">
									<TextInput type="text" pattern="[0-9]*" id="revenueCode" name="revenueCode" required={true} label={i18n.t('dictionariesPages.dictRevenueCode.lblRevenueCode')} onValueChange={onHandleChange} className={form_error.revenueCode ? 'input-error' : ""} value={revenueCode} />
								</div>
								<div className="form-group">
									<label>{i18n.t('dictionariesPages.dictRevenueCode.lblDescription')}</label>
									<div className={'text-input'}>
										<textarea placeholder={i18n.t('dictionariesPages.dictRevenueCode.lblDescription')} name="description" required={true} className={!description ? 'textarea-100 input-error' : "textarea-100"} value={description} onChange={(e) => onHandleChange(e)}></textarea>
									</div>
								</div>
							</div>
						</div>
					</Form.Group>
				</Form>
			</CustomizedDialogs>
			<CustomizedSmallDialogs showModal={showDeleteModalWindow} header={i18n.t("commons.confirmDelete")} type="delete" deleteItem={onDeleteAction} resetForm={resetForm} onHide={onDeleteHide} setShowModalWindow={setShowDeleteModalWindow}>
				{i18n.t("dictionariesPages.dictRevenueCode.deletConfirmation") + "'" + deleteRevenueCode + "'?"}
			</CustomizedSmallDialogs>
		</React.Fragment>
	)
}

export default RevenueCode