import React, { useState, useContext } from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import service from '../service'
import LoadingContext from "../../../container/loadingContext";
import '../style.css'
import Notify from '../../commons/notify';
import Table from '../../commons/Table/Table';
import i18n from "../../../utilities/i18n";
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import { ERAPostingTableDataSearch } from '../ERAPostingTable';
import { getStorage } from '../../../utilities/browserStorage';
import Pagination from '../../commons/pagination';
// eslint-disable-next-line no-undef
var fileDownload = require('js-file-download');


function ERASearchTable({
    selectedEraList,
    exportERAList,
    openLink,
    onClickGridCheckBox,
    selectAll,
    totalPage,
    activePage,
    startIndex,
    endIndex,
    onPagePrevious,
    onPageUp,
    onPageNext,
    lastOpenedEraRowItem
}) {

    const setShowLoadingBar = useContext(LoadingContext);
    const [columnModalHeader, setColumnModalHeader] = useState('');
    const [columnModalOpen, setColumnModalOpen] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [selectedPK, setSelectedPK] = useState('');
    const [isSelected, setIsSelected] = useState(false);
    const [isFieldsChecked, setIsFieldsChecked] = useState(false);


    //Excel Columns
    const optionalColumnsList = i18n.t("payments.eraSearchTable");

    let columnsNameList1 = [
        'payer_name', //default selected 1
        'payee_name', //default selected 2
        'payment_number', //default selected 3
        'payment_date', //default selected 4
        'era_total_amount', //default selected 5
        'payment_mode', //default selected 6
        'era_received_date', //default selected 7
        'claim_id', //default selected 8
        'payer_icn', //default selected 9
        'patient_last_name', //default selected 10
        'patient_first_name', //default selected 11
        'date_of_service', //default selected 12
        'procedure_code', //default selected 13
        'procedure_modifier_code', //default selected 14
        'procedure_charge_amount', //default selected 15
        'procedure_payment_amount', //default selected 16
        'service_provider_name', //default selected 17
        'procedure_adj_type', //default selected 18
        'procedure_adj_amount', //default selected 19
        'procedure_adj_type2' //default selected 20
    ]

    let columnsNameList2 = [
        'procedure_adj_amount2', //default selected 21
        'procedure_adj_type3', //default selected 22
        'procedure_adj_amount3', //default selected 23
        'payer_account_number',//1
        'receiver_account_number',//2
        'account_number_qualifier',//3
        'additional_adjustment_amount',//4
        'additional_adjustment_type',//5
        'allowed_amount',//6
        'claim_adjustment_amount_1',//7
        'claim_adjustment_code_1',//8
        'claim_adjustment_amount_2',//9
        'claim_ adjustment_type',//10
        'claim_adjustment_type_2',//11
        'claim_frequency_type',//12
        'claim_received_date',//13
        'claim_status',//14
        'cob_payer_name',//15
        'cob_status',//16
        'corrected_procedure_code'//17
    ]

    let columnsNameList3 = [
        'corrected_procedure_modifier_code',//18
        'corrected_procedure_modifier_code_2',//19
        'corrected_procedure_modifier_code_3',//20
        'corrected_procedure_modifier_code_4',//21
        'credit_debit_flag_code',//22
        'currency_code',//23
        'entity_identifier_code',//24
        'id_number_qualifier',//25
        'identification_number',//26
        'npi_ein_provider_id',//27
        'npi_provider_id',//28 NPI
        'ein_provider_id', // 28 EIN
        'originating_company_identifier',//29
        'originating_company_supplemental_code',//30
        'patient_middle_name',//31
        'payee_address',//32
        'payee_address2',//33
        'payee_city',//34
        'payee_state',//35
        'payee_zip'//36

    ]

    let columnsNameList4 = [
        'payer_account_number_qualifier',//37
        'payer_address',//38
        'payer_address2',//39
        'payer_city',//40
        'payer_id',//41
        'payer_id_number_qualifier',//42
        'payer_plan_type',//43
        'payer_state',//44
        'payer_zip',//45
        'payment_format_code',//46
        'plb_amount_1',//47
        'plb_amount_2',//48
        'plb_amount_3',//49
        'plb_amount_4',//50
        'plb_amount_5',//51
        'plb_amount_6',//52
        'plb_fiscal_period',//53
        'plb_reason_1',//54
        'plb_reason_2',//55
        'plb_reason_3'//56

    ]

    let columnsNameList5 = [
        'plb_reason_4',//57
        'plb_reason_5',//58
        'plb_reason_6',//59
        'plb_rerference_1',//60
        'plb_rerference_2',//61
        'plb_rerference_3',//62
        'plb_rerference_4',//63
        'plb_rerference_5',//64
        'plb_rerference_6',//65
        'policy_term_date',//66
        'primary_payer_status',//67
        'primary_payer_name',//68
        'procedure_modifier_code_2',//69
        'procedure_modifier_code_3',//70
        'procedure_modifier_code_4',//71
        'procedure_units',//72
        'reference_identification',//73
        'remark_codes',//74
        'subscriber_first_name',//75
        'subscriber_last_name',//76
        'subscriber_middle_name',//77
        'total_charges_in_era',//78
        'total_claim_charge',//79
        'total_claim_payment',//80
        'total_claims_in_era',//81
        'total_patient_resp_on_claim',//82
        'transaction_type'//83
    ]

    let defaultSelectedColumnsList = [
        'payer_name', //default selected 1
        'payee_name', //default selected 2
        'payment_number', //default selected 3
        'payment_date', //default selected 4
        'era_total_amount', //default selected 5
        'payment_mode', //default selected 6
        'era_received_date', //default selected 7
        'claim_id', //default selected 8
        'payer_icn', //default selected 9
        'patient_last_name', //default selected 10
        'patient_first_name', //default selected 11
        'date_of_service', //default selected 12
        'procedure_code', //default selected 13
        'procedure_modifier_code', //default selected 14
        'procedure_charge_amount', //default selected 15
        'procedure_payment_amount', //default selected 16
        'service_provider_name', //default selected 17
        'procedure_adj_type', //default selected 18
        'procedure_adj_amount', //default selected 19
        'procedure_adj_type2', //default selected 20
        'procedure_adj_amount2', //default selected 21
        'procedure_adj_type3', //default selected 22
        'procedure_adj_amount3', //default selected 23
    ]

    // Notify States
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');

    /** Notify Config */
    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === 'show') {
            setShowNotify(action);
            setNotifyType(type);
            setNotifyDescription(desc);
            setTimeout(() => {
                setShowNotify('hide');
            }, age);
        }
    }
    /****************************/


    function exportData() {
        setShowLoadingBar(true);
        let query = '';
        let fields = JSON.stringify(selectedColumns);
        query += '?export_type=xls&payment_header_pk=' + selectedPK + '&fields=' + fields
        const result = service.GetERAPaymentExport(query);
        result.then(response => {
            if (response.data) {
                fileDownload(response.data, response.headers['content-disposition']);
                setSelectedPK('');
                setSelectedColumns([]);
                setColumnModalOpen(false);
            }
            setShowLoadingBar(false);
        });
    }   

    function onSelectAllColumns(e) {
        setIsFieldsChecked(!isFieldsChecked);
        let selected = e.target.checked;
        if (selected) {
            let fullFields = [];
            fullFields = fullFields.concat(columnsNameList1);
            fullFields = fullFields.concat(columnsNameList2);
            fullFields = fullFields.concat(columnsNameList3);
            fullFields = fullFields.concat(columnsNameList4);
            fullFields = fullFields.concat(columnsNameList5);
            setSelectedColumns(fullFields);
        } else {
            setSelectedColumns([]);
        }
    }

    function onSelectOptionalColumn(e, item) {
        let tmp = selectedColumns;
        if (tmp.indexOf(item) >= 0) {
            tmp.splice(tmp.indexOf(item), 1);
        } else {
            tmp.push(item)
        }
        setSelectedColumns(tmp);
        setIsSelected(!isSelected)
    }


    async function exportFunction(e, id, exportType, exportLabel) {
        if (exportType == 'xls') {
            loadExcelColumnModals(e, id);
        }
        else if (exportType == 'pdf') {
            exportERAPDF(e, id)
        } else if (exportLabel === "Download ERA") {
            setShowLoadingBar(true);
            try {
                const response = await service.GetPresignedEraURL({
                    "era_id": id,
                    "practice_id": getStorage('practice')
                });
                if (response?.data?.url) {
                    const link = document.createElement('a');
                    link.href = response.data.url;
                    link.download = 'ERA.pdf';
                    link.target = '_blank'; 
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            } catch (error) {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.era_download_err'));
            }
            setShowLoadingBar(false);
        }
    }


    function exportERAPDF(e, id) {
        setShowLoadingBar(true);
        let query = '';
        query += '?export_type=pdf&payment_header_pk=' + id;
        const result = service.exportERAPDF(query);
        result.then(response => {
            fileDownload(response.data, response.headers['content-disposition']);
            setShowLoadingBar(false);
        });
    }

    function loadExcelColumnModals(e, id) {
        setIsFieldsChecked(false);
        setSelectedPK(id);
        setSelectedColumns(defaultSelectedColumnsList);
        setColumnModalOpen(true);
        setColumnModalHeader(i18n.t('payments.eraPage.addOptionalColumns'));
    }



    return (
        <>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <div className='margin-top0'>
                <div className='row justify-right margin-bottom10 margin-right0'>
                    {selectedEraList && selectedEraList.length > 0 ? <><div className='padding-left15'>
                    </div>
                        <div style={{ marginTop: '7px' }} className='padding-left15'>
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="download-tooltip" style={{
                                    opacity: 0.4,
                                }}>{i18n.t('commons.exportCSV')}
                                </Tooltip>}
                            >
                                <button className="header-btn border-none bg-white p-0" type="button" onClick={exportERAList}>
                                    <i className="las la-file-upload navBarIcons"></i>
                                </button>
                            </OverlayTrigger>
                        </div> </> : <div>&nbsp;</div>}
                </div>
                <div style={{ maxWidth: '100%'}}>
                    <Table tableObject={ERAPostingTableDataSearch} rowBackgroundChange={lastOpenedEraRowItem} onLinkClick={openLink} onClickGridCheckBox={onClickGridCheckBox} exportFunction={exportFunction} checkedHeaderCheckBox={selectAll} />
                </div>
                <Pagination
                    totalPage={totalPage}
                    activePage={activePage}
                    startIndex={startIndex}
                    endIndex={endIndex}
                    onPagePrevious={onPagePrevious}
                    onPageUp={onPageUp}
                    onPageNext={onPageNext}
                />
            </div>
            {/* Modal to export custom ERA List as CSV */}
            <CustomizedDialogs type={'export'} exportData={exportData} modalBodyId={'columnModalBody'} modalId={'columnModal'} header={columnModalHeader} showModal={columnModalOpen} setShowModalWindow={setColumnModalOpen} onCheckBoxChange={onSelectAllColumns} fieldsChecked={isFieldsChecked}>
                <div className='row'>
                    <div className='col-2 max-width-none'>
                        {columnsNameList1.map((item) => {
                            return (
                                <div className='row' key={item}>
                                    <div className="form-groupp lh-0 justify-right">
                                        <div className="custom-checkbox lh-0">
                                            <input
                                                type="checkbox"
                                                id={item}
                                                name={item}
                                                checked={selectedColumns.indexOf(item) !== -1 ? true : false}
                                                onChange={(e) => onSelectOptionalColumn(e, item)}
                                            />
                                            <label
                                                className="checkbox top-14"
                                                htmlFor={item}
                                            ></label>
                                        </div>
                                        <div className="padding-top5 padding-left-5">
                                            <label className="chk-column margin-top-3px">{optionalColumnsList[item]}</label>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className='col-2 max-width-none'>
                        {columnsNameList2.map((item) => {
                            return (
                                <div className='row' key={item}>
                                    <div className="form-groupp lh-0 justify-right">
                                        <div className="custom-checkbox lh-0">
                                            <input
                                                type="checkbox"
                                                id={item}
                                                name={item}
                                                checked={selectedColumns.indexOf(item) !== -1 ? true : false}
                                                onChange={(e) => onSelectOptionalColumn(e, item)}
                                            />
                                            <label
                                                className="checkbox top-14"
                                                htmlFor={item}
                                            ></label>
                                        </div>
                                        <div className="padding-top5 padding-left-5">
                                            <label className="chk-column margin-top-3px">{optionalColumnsList[item]}</label>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className='col-2 max-width-none'>
                        {columnsNameList3.map((item) => {
                            return (
                                <div className='row' key={item}>
                                    <div className="form-groupp lh-0 justify-right">
                                        <div className="custom-checkbox lh-0">
                                            <input
                                                type="checkbox"
                                                id={item}
                                                name={item}
                                                checked={selectedColumns.indexOf(item) !== -1 ? true : false}
                                                onChange={(e) => onSelectOptionalColumn(e, item)}
                                            />
                                            <label
                                                className="checkbox top-14"
                                                htmlFor={item}
                                            ></label>
                                        </div>
                                        <div className="padding-top5 padding-left-5">
                                            <label className="margin-top-3px" style={{ paddingTop: '12px', width: '300px' }}>{optionalColumnsList[item]}</label>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className='col-2 max-width-none'>
                        {columnsNameList4.map((item) => {
                            return (
                                <div className='row' key={item}>
                                    <div className="form-groupp lh-0 justify-right">
                                        <div className="custom-checkbox lh-0">
                                            <input
                                                type="checkbox"
                                                id={item}
                                                name={item}
                                                checked={selectedColumns.indexOf(item) !== -1 ? true : false}
                                                onChange={(e) => onSelectOptionalColumn(e, item)}
                                            />
                                            <label
                                                className="checkbox top-14"
                                                htmlFor={item}
                                            ></label>
                                        </div>
                                        <div className="padding-top5 padding-left-5">
                                            <label className="chk-column margin-top-3px">{optionalColumnsList[item]}</label>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className='col-2 max-width-none'>
                        {columnsNameList5.map((item) => {
                            return (
                                <div className='row' key={item}>
                                    <div className="form-groupp lh-0 justify-right">
                                        <div className="custom-checkbox lh-0">
                                            <input
                                                type="checkbox"
                                                id={item}
                                                name={item}
                                                checked={selectedColumns.indexOf(item) !== -1 ? true : false}
                                                onChange={(e) => onSelectOptionalColumn(e, item)}
                                            />
                                            <label
                                                className="checkbox top-14"
                                                htmlFor={item}
                                            ></label>
                                        </div>
                                        <div className="padding-top5 padding-left-5">
                                            <label className="chk-column margin-top-3px">{optionalColumnsList[item]}</label>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </CustomizedDialogs>
        </>
    )
}

export default ERASearchTable