import React from 'react';
import aimaLogo from '../../../assets/images/AIMA_Logo.png';

const Logo = () => {
    return (
            <img src={aimaLogo} style={{
                width:'auto',
                height:65,
            }} />
    );
}

export default Logo;