import React, { memo } from 'react';
import { styled } from '@mui/material/styles';
import Logo from './Logo';
import NavItems from './NavItems';
import './style.css'
import UserProfile from './UserProfile';
import ChatBotButton from '../../ChatBot/ChatBotButton';
import { checkPermission } from '../../../utilities/commonUtilities';
import { permission_key_values_chatbot } from '../../../utilities/permissions';
import ResponsiveDialog from '../../ChatBot/DialogComponent';

const MemoizedChatBotButton = memo(() => {
    return (
        // Render the Chat Bot only if user has access to the dashboard
        checkPermission(permission_key_values_chatbot) ?
            <ChatBotButton /> : <></>
    )
})

MemoizedChatBotButton.displayName = "MemoizedChatBotButton";

const HeaderContainer = styled('div')(() => ({
    width: '100%',
    height: '80px',
    backgroundColor: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex:60,
    position:'fixed',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
}))

const LogoContainer = styled('div')(({theme}) => ({
    paddingLeft: '45px',
    [theme.breakpoints.between('sm','md')]:{
        display:'none'
    },
    [theme.breakpoints.down('sm')]:{
        display:'none'
    }
}))

const NavItemsContainer = styled('div')(({theme}) => ({
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    paddingLeft:40,
    paddingRight:40,
    [theme.breakpoints.between('sm','md')]:{
        paddingLeft:10,
    },
    [theme.breakpoints.down('sm')]:{
        paddingLeft:10,
    }
}));


const StickyAppBar = () => {

    return (
        <>
            <HeaderContainer>
                <LogoContainer>
                    <Logo />
                </LogoContainer>
                <NavItemsContainer>
                    <NavItems />
                </NavItemsContainer>
                <UserProfile />
            </HeaderContainer>
            <MemoizedChatBotButton />
            <ResponsiveDialog />
        </>
    );
};

export default StickyAppBar;