
import React, { memo } from 'react'
import { styled } from "@mui/material/styles";
import UserProfileBox from '../headerComponent/UserProfileBox';
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import ImageCrop from '../../commons/image-cropper/ImageCrop';

const StyledUserProfileBox = styled(Toolbar)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderLeft: "1px solid #E0E0E0",
    background: "#FAFAFA",
    boxShadow: "none",
    [theme.breakpoints.down('sm')]: {
        minHeight: '80px',
        marginLeft:'auto'
    },
    [theme.breakpoints.up('sm')]: {
        minHeight: '80px',
        marginLeft:'auto'
    }
}));

// Caching the ImageCropper Component to avoid un-necessary render
const MemoizedImgCropper = memo(({ imgUrl, imageName, show, showModal, changeCroppedImage }) => {
    return < ImageCrop imgUrl={imgUrl} imageName={imageName} show={show} showModal={showModal} changeCroppedImage={changeCroppedImage} />;
});

MemoizedImgCropper.displayName = 'MemoizedImgCropper';

const UserProfile = () => {

    return (
            <StyledUserProfileBox>
                    <Link to="#" className="right-menu-icon margin-top5 mr-0">
                        <UserProfileBox />
                    </Link>
            </StyledUserProfileBox>
    );
}


export default UserProfile;