import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import LoadingContext from '../../../container/loadingContext';
import i18n from '../../../utilities/i18n';
import { DEFAULT_PAGING_SIZE, MAX_OPEN_TABS_PATIENTS, PAGING_END_INDEX, ROUTE_PATIENTS_LIST } from '../../../utilities/staticConfigs';
import CalendarInput from '../../commons/input/CalendarInput';
import TextInput from '../../commons/input/input';
import SelectInput from '../../commons/input/SelectInput';
import service from '../patients/service';
import service1 from '../../dictionaries/serviceTypes/service';
import service2 from '../../customerSetup/providerManagement/service';
import { getStorage } from '../../../utilities/browserStorage';
import PatientDropDownSearch from '../patients/patientDropDownSearch';
import PatientsAdvancedSearch from '../patients/PatientsAdvancedSearch';
import RightSidePanel from '../../commons/RightSidePanel/RightSidePanel';
import Notify from '../../commons/notify';
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import Table from '../../commons/Table/Table';
import { EligibilityTabOneTable } from './RunEligibilityTable';
import { checkPermission, commonTableBody } from '../../../utilities/commonUtilities';
import SummaryView from './summaryView';
import searchClaimService from '../../ClaimsModule/SearchClaims/service'
import { permission_key_values_patient } from '../../../utilities/permissions';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import Pagination from '../../commons/pagination';
import "./style.css"
import CommonButton from '../../commons/Buttons';
import { Grid } from '@mui/material';
import UpdateInsuranceDetails from './UpdateInsuranceDetails';

function EligibilityTab1() {
    const [serviceTypesList, setServiceTypesList] = useState([]);
    const [defaultIdForServiceType, setDefaultIdForServiceType] = useState("");
    const [defaultValueForProvider, setDefaultValueForProvider] = useState("");
    const [patientPK, setPatientPK] = useState("");
    const [redirectToPatient, setRedirectToPatient] = useState(false);
    const [patientDod, setPatientDod] = useState("");


    const [insuranceName, setInsuranceName] = useState("");
    const [showInsurancename, setShowInsurancename] = useState(false);
    const [policyId, setPolicyId] = useState("");
    const [serviceType, setServiceType] = useState("");
    const [provider, setProvider] = useState("");
    const [eligiblityDate, setEligiblityDate] = useState(new Date());
    const [memberName, setMemberName] = useState("");
    const [coPay, setCoPay] = useState("");
    const [coPayType, setCoPayType] = useState("");
    const [coInsurance, setCoInsurance] = useState("");
    const [coInsuranceType, setCoInsuranceType] = useState("");
    const [subscriberName, setSubscriberName] = useState("");
    const [deductible, setDeductible] = useState("");
    const [deductibleType, setDeductibleType] = useState("");
    const [address, setAddress] = useState("");
    const [pocketExpenseType, setPocketExpenseType] = useState("");
    const [outOfPocketMax, setOutOfPocketMax] = useState("");
    const [claimPolicyID, setClaimPolicyID] = useState("");
    const [groupNumber, setGroupNumber] = useState("");
    const [effDate, setEffDate] = useState("");
    //
    const [coPayTrue, setCoPayTrue] = useState(false);
    const [coInsuranceTrue, setCoInsuranceTrue] = useState(false);
    const [deductibleTrue, setDeductibleTrue] = useState(false);
    const [outOfPocketMaxTrue, setOutOfPocketMaxTrue] = useState(false);
    const [groupNumberTrue, setGroupNumberTrue] = useState(false);
    const [effDateTrue, setEffDateTrue] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const header = i18n.t("Eligibility.eligibilityTab1.window.eligibilitySummary");
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const updateHeader = i18n.t("Eligibility.eligibilityTab1.window.eligibilitySummaryUpdate");
    const [insuranceNameList, setInsuranceNameList] = useState([]);
    const currentActivePage = 0;
    const setShowLoadingBar = useContext(LoadingContext);
    const [providerGroupList, setProviderGroupList] = useState([]);

    const practicePK = getStorage('practice');
    const [patientSelected, setPatientSelected] = useState([]);
    const [eligibilityId, setEligibilityId] = useState('');
    const [insuranceInfo, setInsuranceInfo] = useState([]);
    const [eligibilityHistoryList, setEligibilityHistoryList] = useState([]);
    const [coInsuranceTypeList, setCoInsuranceTypeList] = useState([]);
    const [coPayTypeList, setCoPayTypeList] = useState([]);
    const [deductibleTypeList, setDeductibleTypeList] = useState([]);
    const [outOfPocketTypeList, setOutOfPocketTypeList] = useState([]);
    const [patientAdvSearchData, setPatientAdvSearchData] = useState([]);
    const [clearSelectedPatient, setClearSelectedPatient] = useState(0);
    const [htmlResponse, setHtmlResponse] = useState({});

    //start ==== Alert message properties
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === 'show') {
            setShowNotify(action);
            setNotifyType(type);
            setNotifyDescription(desc);
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
    }

    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

    function onPagePrevious() {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        setActivePage(previousPage);
        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        getEligibilityList(DEFAULT_PAGING_SIZE, previousPage)
    }

    function onPageUp(e) {
        let page = Number(e.target.id)
        setActivePage(page);
        getEligibilityList(DEFAULT_PAGING_SIZE, page)
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);
        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);
        }
        getEligibilityList(DEFAULT_PAGING_SIZE, nextPage)
    }


    function resetSearch() {
        setInsuranceName("");
        setInsuranceNameList([]);
        setServiceType(defaultIdForServiceType)
        setPolicyId("");
        setProvider(defaultValueForProvider);
        setEligiblityDate(new Date());
        setPatientAdvSearchData([])
        setPatientSelected([])
        setPatientPK("")
        setClearSelectedPatient((clearSelectedPatient + 1));
    }
    
    
    function eligibilityCheckModal() {
        let formattedEligibilityDate = format(eligiblityDate, 'yyyy-MM-dd', { locale: enUS });
        if ( formattedEligibilityDate > patientDod) {
            showNotifyWindow("show", "error", i18n.t("errorMessages.dodValidation"));
            return;
        }
        if (patientPK && provider && insuranceName && policyId) {
            setShowLoadingBar(true);
            let data = {
                'patient_id': patientPK,
                'provider_id': provider,
                'insurance_company_id': insuranceName,
                'checking_date':formattedEligibilityDate,
                'policy_id': policyId,
                'service_type': serviceType,
                'practice_pk': practicePK
            }
            let result = service.RunEligibility(data)
            result.then((response) => {
               if (response.data.message === "no_payer_clearinghouse") {
                    showNotifyWindow("show", "error", `${i18n.t('errorMessages.payer_not_setup_at_clearinghouse').substring(0, 6)} ${response.data.data.insurance} ${i18n.t('errorMessages.payer_not_setup_at_clearinghouse').substring(6)}`);
                    setShowLoadingBar(false);
                } else if (response.data.message === 'authentication_failed') {
                    showNotifyWindow("show", "error", i18n.t('errorMessages.authentication_failed')); 
                    setShowLoadingBar(false);
                } else if (response.data.message === 'practice_not_found') {
                    showNotifyWindow("show", "error", i18n.t('errorMessages.practice_not_found')); 
                    setShowLoadingBar(false);
                } else if (response.data.message === 'provider_not_added') {
                    showNotifyWindow("show", "error", i18n.t('errorMessages.provider_not_added')); 
                    setShowLoadingBar(false);
                } else if (response.status === 500) {
                    setShowLoadingBar(false);
                    showNotifyWindow("show", "error", i18n.t('errorMessages.commonError'));
                }
                else if (response.status === 400) {
                    setShowLoadingBar(false);
                    showNotifyWindow("show", "error", response.data.message);
                } else {
                    setHtmlResponse(response.data.html_response);
                    setEligibilityId(response.data.id)
                    setShowLoadingBar(false);
                    setOpenModal(true);
                }
            }).catch(() => {
                setShowLoadingBar(false);
                showNotifyWindow("show", "error", i18n.t('errorMessages.commonError'));
            })
        }
    }
    function resetForm() {
        setOpenModal(false);
        setCoPayTrue(false);
        setCoInsuranceTrue(false);
        setDeductibleTrue(false);
        setOutOfPocketMaxTrue(false);
        setGroupNumberTrue(false);
        setEffDateTrue(false);
    }
    function onHide() {
        setOpenModal(false);
        if (showInsurancename) {
            setInsuranceName("");
            setInsuranceNameList([]);
            setPolicyId("");
            setShowInsurancename(false);
            setPatientAdvSearchData([]);
            setPatientSelected([]);
            setPatientPK("")
            setClearSelectedPatient((clearSelectedPatient + 1));
        }
        getEligibilityList(DEFAULT_PAGING_SIZE, activePage);
    }
    function onUpdateHide() {
        setOpenUpdateModal(false);
    }
    function resetUpdateForm() {
        setMemberName("");
        setCoPayTypeList([]);
        setCoInsuranceTypeList([])
        setDeductibleTypeList([]);
        setOutOfPocketTypeList([]);
        setSubscriberName("");
        setClaimPolicyID("");
        setGroupNumber("");
        setEffDate("");
        setMemberName("");
        setCoPay("");
        setCoInsurance("");
        setSubscriberName("");
        setDeductible("");
        setAddress("");
        setOutOfPocketMax("");
        setGroupNumber("");
        setCoPayTrue(false);
        setCoPayType(false);
        setCoPay("")
        setCoInsuranceTrue(false);
        setCoInsuranceType(false);
        setCoInsurance("")
        setDeductibleTrue(false);
        setDeductibleType("");
        setDeductible("")
        setOutOfPocketMaxTrue(false);
        setPocketExpenseType("");
        setOutOfPocketMax("")
        setClaimPolicyID("");
        setGroupNumberTrue(false);
        setEffDateTrue(false);
        setEffDateTrue(false);
        setOpenUpdateModal(false);
    }

    function onHandleChange(e) {

        if (e.target) {
            let name = e.target.name;
            let value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
            let inputFields = ["insuranceName", "policyId", "serviceType", "provider", "memberName", "coPay", "coInsurance", "subscriberName", "deductible", "eligiblityDate", "address", "outOfPocketMax", "groupNumber", "coPayTrue", "coPayType", "coInsuranceTrue", "coInsuranceType", "deductibleTrue",
                "deductibleType",
                "outOfPocketMaxTrue",
                "pocketExpenseType",
                "claimPolicyID",
                "groupNumberTrue",
                "effDateTrue",
                "outOfPockedTrue"];

            const trimmedValue = inputFields.includes(name) ? value : "";
            switch (name) {
                case "insuranceName":
                    setInsuranceName(trimmedValue);
                    break;
                case "policyId":
                    setPolicyId(trimmedValue);
                    break;
                case "serviceType":
                    setServiceType(trimmedValue);
                    break;
                case "provider":
                    setProvider(trimmedValue);
                    break;
                case "memberName":
                    setMemberName(trimmedValue);
                    break;
                case "coPay":
                    setCoPay(trimmedValue);
                    break;
                case "coInsurance":
                    setCoInsurance(trimmedValue);
                    break;
                case "subscriberName":
                    setSubscriberName(trimmedValue);
                    break;
                case "deductible":
                    setDeductible(trimmedValue);
                    break;
                case "address":
                    setAddress(trimmedValue);
                    break;
                case "outOfPocketMax":
                    setOutOfPocketMax(trimmedValue);
                    break;
                case "groupNumber":
                    setGroupNumber(trimmedValue);
                    break;
                case "coPayTrue":
                    setCoPayTrue(trimmedValue);
                    break;
                case "coInsuranceTrue":
                    setCoInsuranceTrue(trimmedValue);
                    break;

                case "coPayType":
                    setCoPayType(trimmedValue);
                    if (trimmedValue !== "")
                        setCoPay(coPayTypeList[parseInt(trimmedValue) - 1].co_payment)
                    break;
                case "coInsuranceType":
                    setCoInsuranceType(trimmedValue);
                    if (trimmedValue !== "")
                        setCoInsurance(coInsuranceTypeList[parseInt(trimmedValue) - 1].co_insurance)
                    break;

                case "deductibleTrue":
                    setDeductibleTrue(trimmedValue)
                    break;
                case "deductibleType":
                    setDeductibleType(trimmedValue);
                    if (trimmedValue !== "")
                        setDeductible(deductibleTypeList[parseInt(trimmedValue) - 1].deductible)
                    break;
                case "outOfPocketMaxTrue":
                    setOutOfPocketMaxTrue(trimmedValue)
                    break;
                case "pocketExpenseType":
                    setPocketExpenseType(trimmedValue);
                    if (trimmedValue !== "")
                        setOutOfPocketMax(outOfPocketTypeList[parseInt(trimmedValue) - 1].out_of_pocket)
                    break;

                case "claimPolicyID":
                    setClaimPolicyID(trimmedValue);
                    break;
                case "groupNumberTrue":
                    setGroupNumberTrue(trimmedValue);
                    break;
                case "effDateTrue":
                    setEffDateTrue(trimmedValue);
                    break;
                case "outOfPockedTrue":
                    setEffDateTrue(trimmedValue);
                    break;
                default:
                    break;
            }

        }
        else {
            if (e[0]) {
                setPatientPK(e[0].id);
            } else if (!e[0]) {
                setPatientPK("");
            }
        }
    }

    function onHandleDate(selected) {
        setEligiblityDate(selected);
    }
    function onHandleEffDate(selected) {
        setEffDate(selected);
    }
    function getInsuranceNames() {
        const result = service.ListInsuranceInfos(patientPK);
        result.then((response) => {
            let dataList = [];
            const sortedData = response.data.sort((a, b) => a.priority - b.priority);
            if (sortedData) {
                dataList = sortedData.map((item) => ({
                    name: item.insurance_name,
                    id: item.id
                }));
            }
            setInsuranceInfo(response.data);
            setPatientDod(response?.data[0]?.date_of_death);
            setInsuranceNameList(dataList);
            setInsuranceName(dataList[0]?.id);
            if (dataList.length == 0) {
                showNotifyWindow('show', 'error', i18n.t("Eligibility.eligibilityTab1.noInsuranceFound"));
            }
        });
    }
   async function getProvidersData(DEFAULT_PAGING_SIZE, currentActivePage) {
        setShowLoadingBar(true);

        try {
            const response = await service2.getProvidersData(DEFAULT_PAGING_SIZE, currentActivePage, practicePK);
                let providerList = [];
    
                if (Array.isArray(response?.data)) {
                    const default_Provider = response.data.find(i => i.default_provider === true);
                    if (default_Provider) {
                        let tempData = { fullname: default_Provider.full_name, id: default_Provider.id };
                        providerList.push(tempData);
                        setDefaultValueForProvider(tempData.id)
                        setProvider(tempData.id)
                    }
                    setProviderGroupList(response.data);
                    setShowLoadingBar(false);
                }
        } catch (error) {
            console.error('Error fetching provider data:', error);
            setShowLoadingBar(false);
        }
    }

    async function getServiceTypeList(pageSize) {
        let pageNum = 0;
        setShowLoadingBar(true);
        try {
            const response = await service1.ListServiceType(pageSize, pageNum);
            if (response.data?.length) {
                setServiceTypesList(response.data);
                let obj = null;
                let array = response.data;
                for (const item of array) {
                    if (item.name === "30-Plan Coverage and General Benefits") {
                        obj = item;
                        break;  // exit loop early if found
                    }
                }
                if (obj?.id) {
                    setServiceType(obj.id);
                    setDefaultIdForServiceType(obj.id);
                }
            }
        } catch (error) {
            console.error("Error fetching service type:", error)
        } finally {
            setShowLoadingBar(false)
        }
    }

    const getPatientDefaultValues = async () => {
        const response = await service.GetPatientDefaults(patientPK, '["all"]');
        if (response?.default_provider?.active) {
            setProvider(response?.default_provider.id);
        }
    };


    useEffect(() => {
        if (patientPK) {
            getInsuranceNames();
            getPatientDefaultValues();
        }
        else {
            setInsuranceNameList([]);
            resetSearch();
        }
    }, [patientPK]);


    useEffect(() => {
        getProvidersData(DEFAULT_PAGING_SIZE, currentActivePage);
        getServiceTypeList(DEFAULT_PAGING_SIZE);
    }, [currentActivePage]);

    useEffect(() => {
        getEligibilityList(DEFAULT_PAGING_SIZE, activePage);
    }, []);

    useEffect(() => {
        if (insuranceName) {
            let insuranceObj = insuranceInfo.find(function (element) {
                return element.id === insuranceName;
            });
            setPolicyId(insuranceObj.policy_id)
        } else {
            setInsuranceName('');
        }
    }, [insuranceName]);

    function getEligibilityList(pageSize, page) {
        setShowLoadingBar(true);
        const result = service.GetEligibilityList(practicePK, pageSize, page);
        result.then(response => {
            if (response?.data?.results !== undefined) {
                setTotalPage(Math.ceil(response.data.count / response.data.page_size));
            }
            const rowArray = commonTableBody(response.data.results, EligibilityTabOneTable.tableBodyData[0]);
            EligibilityTabOneTable.tableBodyData = rowArray;
            setEligibilityHistoryList(response.data.results);
            setShowLoadingBar(false);
        })
    }
    function showEBResponseSummary(id, patient_id) {
        setShowLoadingBar(true);
        const result = service.ShowResponseHtml(id);
        result.then(response => {
            if (response.data?.html_response && Object.keys(response.data.html_response).length > 0) {
                setShowLoadingBar(false);
                setEligibilityId(id);
                setPatientPK(patient_id);
                setShowInsurancename(true);
                setHtmlResponse(response.data.html_response);
                setOpenModal(true);
            } else {
                setShowLoadingBar(false);
                showNotifyWindow("show", "error", i18n.t('errorMessages.commonError'));
            } 
        }).catch(() => {
            setShowLoadingBar(false);
            showNotifyWindow("show", "error", i18n.t('errorMessages.commonError'));
        })
    }
    function getExtractedData() {
        resetUpdateForm();
        setShowLoadingBar(true);
        let path = 'patient/extract-elg-txt-db/?id=' + eligibilityId + '&checking_type=rte' + '&subscriber_pk=' + htmlResponse.subscriber_pk;
        const result = service.GetExtractedData(path);
        result.then(response => {
            setShowLoadingBar(false);
            if (Object.keys(response.data).length > 0) {
                let coPayTypeArr = [];
                response.data && response.data.co_payment?.map((item, index) => {
                    coPayTypeArr.push({ "id": (index + 1).toString(), "name": item.name, "co_payment": item.co_payment })
                })
                setCoPayTypeList(coPayTypeArr);
                let coInsuTypeList = [];
                response.data && response.data.co_insurance?.map((item, index) => {
                    coInsuTypeList.push({ "id": (index + 1).toString(), "name": item.name, "co_insurance": item.co_insurance })
                })
                setCoInsuranceTypeList(coInsuTypeList)
                let deductibleList = [];
                response.data && response.data.deductible?.map((item, index) => {
                    deductibleList.push({ "id": (index + 1).toString(), "name": item.name, "deductible": item.deductible })
                })
                setDeductibleTypeList(deductibleList);
                let outOfPocketList = [];
                response.data && response.data.out_of_pocket?.map((item, index) => {
                    outOfPocketList.push({ "id": (index + 1).toString(), "name": item.name, "out_of_pocket": item.out_of_pocket })
                })

                setOutOfPocketTypeList(outOfPocketList);
                if (response.data) {
                    setSubscriberName(response.data.subscriber);
                    setClaimPolicyID(response.data.member_id);
                    setGroupNumber(response.data.group_number);
                    setMemberName(response.data.member_name);
                    setAddress(response.data.address);
                    setEffDate(response.data.plan_date ? new Date(response.data.plan_date) : "");
                }
                setOpenUpdateModal(true);
            }

        })
    }

    function onSaveFormData() {
        setShowLoadingBar(true);
        let data = {}
        if (groupNumberTrue)
            data["group_number"] = groupNumber
        if (coPayTrue)
            data["copay"] = coPay
        if (coInsuranceTrue)
            data["co_insurance"] = coInsurance
        if (outOfPocketMaxTrue)
            data["out_of_pocket"] = outOfPocketMax
        if (Object.keys(data).length > 0) {
            data["patient_pk"] = patientPK
            data["policy_id"] = claimPolicyID
            const result = service.UpdateInsuranceInfo(data);
            result.then(response => {
                setShowLoadingBar(false);
                setOpenUpdateModal(false);
                if (response.status === 200) {
                    showNotifyWindow('show', 'success', i18n.t("Eligibility.eligibilityTab1.InsuranceDataUpdated"));
                } else {
                    showNotifyWindow('show', 'error', i18n.t("Eligibility.eligibilityTab1.InsuranceDataUpdateFailed"));
                }

            })
        }
        setShowLoadingBar(false);
    }

    function onPatientNameClick(patientID) {
        const result = searchClaimService.GetSelectedTabs('patients', getStorage("practice"));
        result.then(response => {
            let openedPKs = response.data && response.data.opened_tab_pks ? response.data.opened_tab_pks.filter(item => parseInt(item, 10) > 0) : [];
            if (openedPKs && Array.isArray(openedPKs) && openedPKs.length >= MAX_OPEN_TABS_PATIENTS && !openedPKs.includes(patientID)) {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.max_patient_tabs'));
            }
            else {
                let item = { pk: String(patientID), type: 'patients', action: 'add', practice_pk: getStorage("practice") }
                addRemoveFromSelectedTab(item);
            }
        });
    }

    function addRemoveFromSelectedTab(item) {
        const result = searchClaimService.AddRemoveSelectedTab(item);
        result.then(response => {
            if (response.data.code === 404) {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.not_found'))
            } else {
                if (item && item.type === 'patients') {
                    setRedirectToPatient(true);
                }
            }
        });
    }

    if (redirectToPatient) {
        return <Redirect to={{ pathname: ROUTE_PATIENTS_LIST }} />;

    }

    function dropDownFunction(id, name) {
        let obj = eligibilityHistoryList.find((item) => item.id === id);
        if (name === 'summary') {
            // if (checkPermission(permission_key_values_patient.can_view_response_html)) {
            showEBResponseSummary(id, obj.patient_id);
            // }
            // else {
            //     showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            // }
        }
        else if (name === 'patient_name') {
            if (checkPermission(permission_key_values_patient.patient_list_sub_module_view)) {
                onPatientNameClick(obj.patient_id)
            }
            else {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            }
        }
    }

    return (
        <React.Fragment>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <div className=''>

                <Form id="form_patient_info" autoComplete="off" className="eligibility-search-box mb-4 pl-4" >
                    <div className="margin-rightM10">
                        <Grid container spacing={2} >
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <div className='row'>
                                        <PatientDropDownSearch fullWidth={true} inputClass={patientPK ? "" : 'input-error'} setPatientPK={setPatientPK} patientSelected={patientSelected} setPatientSelected={setPatientSelected} patientAdvSearchData={patientAdvSearchData} clearSelectedPatient={clearSelectedPatient} dateFieldClass={"margin-right5"}  />
                                        </div>
                                    </Grid>
                                    <Grid item xs={3.8} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '30px !important', marginLeft: '15px !important' }} >
                                        <RightSidePanel title={i18n.t("commons.advancedSearch")} onclickLabel={i18n.t("commons.advancedSearch")} >
                                            <PatientsAdvancedSearch setPatientPK={setPatientPK} setPatientSelected={setPatientSelected} setPatientAdvSearchData={setPatientAdvSearchData}>
                                            </PatientsAdvancedSearch>
                                        </RightSidePanel>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={4}>
                                    <Grid item xs={4}>
                                        <SelectInput messageForNoRecords={insuranceNameList.length === 0 && patientPK ? i18n.t("Eligibility.eligibilityTab1.emptyInsuranceList") : i18n.t("commons.select")} data={insuranceNameList} name="insuranceName" required={true} value={insuranceName} setValue={setInsuranceName} onValueChange={onHandleChange} label={i18n.t("Eligibility.eligibilityTab1.insuranceName")} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div className='margin-left3'>
                                            <TextInput type="text" name="policyId" required={true} label={i18n.t("Eligibility.eligibilityTab1.policyId")} value={policyId} onValueChange={onHandleChange} />
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SelectInput name="serviceType" data={serviceTypesList} required={true} defaultValues={defaultIdForServiceType} value={serviceType} setValue={setServiceType} onValueChange={onHandleChange} label={i18n.t("Eligibility.eligibilityTab1.serviceType")} />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: '-1rem !important'}}>
                                <Grid container spacing={4}>
                                    <Grid item xs={4}>
                                        <SelectInput name="provider" messageForNoRecords={providerGroupList.length === 0 ? i18n.t("Eligibility.eligibilityTab1.emptyProviderList") : i18n.t("commons.select")}
                                            defaultValues={defaultValueForProvider}
                                            data={providerGroupList} required={true} value={provider} setValue={setProvider} onValueChange={onHandleChange} label={i18n.t("Eligibility.eligibilityTab1.provider")} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div className='margin-left3'>
                                            <CalendarInput label={i18n.t("Eligibility.eligibilityTab1.checkEligibility")} selected={eligiblityDate}
                                                name="eligiblityDate"
                                                minDate={patientSelected[0]?.dob ? patientSelected[0]?.dob : eligiblityDate}
                                                required={true} onValueChange={(selected) => onHandleDate(selected)} />
                                        </div>    
                                    </Grid>
                                    <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                        <div className='margin-right15 margin-top4'>
                                            <CommonButton variant="outlined" onClick={resetSearch} label={i18n.t("buttons.reset")} />
                                        </div>
                                        <div className='margin-top4'>
                                        <CommonButton variant="contained" onClick={eligibilityCheckModal} label={i18n.t("Eligibility.eligibilityTab1.button.checkEligibility")} />
                                        </div>
                                    </Grid>
                                </Grid>
                            
                                {/* <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '-1rem !important', margin: '0 8px' }}>
                                    <RightSidePanel title={i18n.t("commons.advancedSearch")} onclickLabel={i18n.t("commons.advancedSearch")} >
                                        <PatientsAdvancedSearch setPatientPK={setPatientPK} setPatientSelected={setPatientSelected} setPatientAdvSearchData={setPatientAdvSearchData}>
                                        </PatientsAdvancedSearch>
                                    </RightSidePanel>
                                </Grid> */}
                            </Grid>
                        </Grid>
                    </div>
                </Form>

                <CustomizedDialogs header={header} update={getExtractedData} eligibility={(htmlResponse?.status_info?.request_status === "active" && htmlResponse?.subscriber_pk) ? true : false} showModal={openModal} setShowModalWindow={setOpenModal} resetForm={resetForm} alertOK={onHide} onHide={onHide} >
                    <SummaryView htmlResponse={htmlResponse} insuranceName={insuranceInfo[0]?.insurance_name} />
                </CustomizedDialogs>

                <UpdateInsuranceDetails
                    openUpdateModal={openUpdateModal}
                    setOpenUpdateModal={setOpenUpdateModal}
                    onSaveFormData={onSaveFormData}
                    updateHeader={updateHeader}
                    onUpdateHide={onUpdateHide}
                    resetUpdateForm={resetUpdateForm}
                    onHandleChange={onHandleChange}
                    memberName={memberName}
                    setCoPayType={setCoPayType}
                    coPayTrue={coPayTrue}
                    coPayTypeList={coPayTypeList}
                    subscriberName={subscriberName}
                    coInsuranceTrue={coInsuranceTrue}
                    coPay={coPay}
                    coPayType={coPayType}
                    coInsurance={coInsurance}
                    coInsuranceType={coInsuranceType}
                    coInsuranceTypeList={coInsuranceTypeList}
                    setCoInsuranceType={setCoInsuranceType}
                    deductibleTypeList={deductibleTypeList}
                    address={address}
                    deductibleType={deductibleType}
                    setDeductibleType={setDeductibleType}
                    deductibleTrue={deductibleTrue}
                    deductible={deductible}
                    claimPolicyID={claimPolicyID}
                    outOfPocketTypeList={outOfPocketTypeList}
                    pocketExpenseType={pocketExpenseType}
                    setPocketExpenseType={setPocketExpenseType}
                    outOfPocketMaxTrue={outOfPocketMaxTrue}
                    outOfPocketMax={outOfPocketMax}
                    groupNumberTrue={groupNumberTrue}
                    groupNumber={groupNumber}
                    effDateTrue={effDateTrue}
                    onHandleEffDate={onHandleEffDate}
                    effDate={effDate}
                />

                <div style={{ overflowX: "auto", width: "100%"}}>
                    <Table tableObject={EligibilityTabOneTable} onLinkClick={dropDownFunction} tblRadiusClass={' table-border-radius15 '} />
                </div>
                <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                <div style={{height: '30px'}}></div>
            </div>
        </React.Fragment>
    )
}

export default EligibilityTab1;
