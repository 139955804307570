import i18n from "../../utilities/i18n";

export const colVisitID = i18n.t("reports.visit_id");
export const colClaimID = i18n.t("reports.claim_id");
export const colAccessionNumber = i18n.t("reports.accession_number");
export const colPatientID = i18n.t("reports.patient_id");
export const colPatientFirstName = i18n.t("reports.firstName");
export const colPatientLastName = i18n.t("reports.lastName");
export const colPatientMiddleName = i18n.t("reports.middleName");
export const colPatientGender = i18n.t("reports.gender");
export const colDateOfBirth = i18n.t("reports.DOB");
export const coldateOfEntry = i18n.t("reports.dateOfEntry");
export const colfirstBilledDate = i18n.t("reports.firstBilledDate");
export const collastBilledDate = i18n.t("reports.lastBilledDate");
export const coldateOfServiceFrom = i18n.t("reports.dateOfServiceFrom");
export const coldateOfServiceTo = i18n.t("reports.dateOfServiceTo");
export const colrefferingProvider = i18n.t("reports.refferingProvider");
export const colrefferingProviderNPI = i18n.t("reports.refferingProviderNPI");
export const colrenderingProvider = i18n.t("reports.renderingProvider");
export const colrenderingProviderNPI = i18n.t("reports.renderingProviderNPI");
export const colbillingProvider = i18n.t("reports.billingProvider");
export const colbillingProviderNPI = i18n.t("reports.billingProviderNPI");
export const colCPTCODE= i18n.t("reports.cptcode");
export const colUnits= i18n.t("reports.units");
export const colmodifier1= i18n.t("reports.modifier1");
export const colmodifier2= i18n.t("reports.modifier2");
export const colmodifier3= i18n.t("reports.modifier3");
export const colmodifier4= i18n.t("reports.modifier4");
export const colICD_list= i18n.t("reports.ICD_list");
export const colcpt_charge_amount= i18n.t("reports.cpt_charge_amount");
export const colcpt_debit_amount= i18n.t("reports.cpt_debit_amount");
export const colbilled_to_payer_name= i18n.t("reports.billed_to_payer_name");
export const colbilled_to_payer_id= i18n.t("reports.billed_to_payer_id");
export const colbilled_to_policy_id= i18n.t("reports.billed_to_policy_id");
export const colbilled_to_group_no= i18n.t("reports.billed_to_group_no");
export const colcurrent_payer_name= i18n.t("reports.current_payer_name");
export const colcurrent_payer_id= i18n.t("reports.current_payer_id");
export const colcurrent_payer_policy_id= i18n.t("reports.current_payer_policy_id");
export const colcurrent_group_no= i18n.t("reports.current_group_no");
export const colprimary_payer_name= i18n.t("reports.primary_payer_name");
export const colprimary_payer_id= i18n.t("reports.primary_payer_id");
export const colprimary_policy_id= i18n.t("reports.primary_policy_id");
export const colprimary_group_no= i18n.t("reports.primary_group_no");
export const colsecondary_payer_name= i18n.t("reports.secondary_payer_name");
export const colsecondary_payer_id= i18n.t("reports.secondary_payer_id");
export const colsecondary_policy_id= i18n.t("reports.secondary_policy_id");
export const colsecondary_group_no= i18n.t("reports.secondary_group_no");
export const colEnteredBy= i18n.t("reports.entered_by");
export const depositDate = i18n.t("reports.deposit_date");
export const checkNumber = i18n.t("reports.check_number");
export const checkDate = i18n.t("reports.check_date");
export const cptPaidAmount = i18n.t("reports.cpt_paid_amount");
export const cptAdjAmount = i18n.t("reports.cpt_adj_amount");
export const paidPayerName = i18n.t("reports.paid_payer_name");
export const paidPayerID = i18n.t("reports.paid_payer_id");
export const paidPolicyID = i18n.t("reports.paid_policy_id");
export const paidGroupNo = i18n.t("reports.paid_group_no");
export const EobPayername = i18n.t("reports.eob_payer_name");
export const EobPayerId = i18n.t("reports.eob_payer_id");
export const serviceLocation = i18n.t("reports.serviceLocation");
export const colclaimStatus = i18n.t("reports.claimStatus");
export const colclaimSubStatus = i18n.t("reports.claimSubStatus");
export const collabSalesReport = i18n.t("reports.lab_sales_report");
export const colclinicSiteName = i18n.t("reports.clinic_site_name");
export const colclaimServiceType = i18n.t("reports.claim_service_type");
export const colclaimPos = i18n.t("reports.pos");
export const colclaimTos = i18n.t("reports.tos");
export const billedAmount = i18n.t("reports.billed_amount");
export const allowedAmount = i18n.t("reports.allowed_amount");

export   const ReportTableHead = [
    colClaimID,
    colAccessionNumber,
    colPatientID,
    colVisitID,
    colPatientFirstName,
    colPatientLastName,
    colPatientMiddleName,
    colPatientGender,
    colDateOfBirth,
    coldateOfEntry,
    coldateOfServiceFrom,
    coldateOfServiceTo,
    collastBilledDate,
    colfirstBilledDate,
    colrefferingProvider,
    colrefferingProviderNPI,
    colrenderingProvider,
    colrenderingProviderNPI,
    colbillingProvider,
    colbillingProviderNPI,
    colCPTCODE,
    colUnits,
    colmodifier1,
    colmodifier2,
    colmodifier3,
    colmodifier4,
    colICD_list,
    colcpt_charge_amount,
    colcpt_debit_amount,
    colbilled_to_payer_name,
    colbilled_to_payer_id,
    colbilled_to_policy_id,
    colbilled_to_group_no,
    colcurrent_payer_name,
    colcurrent_payer_id,
    colcurrent_payer_policy_id,
    colcurrent_group_no,
    colprimary_payer_name,
    colprimary_payer_id,
    colprimary_policy_id,
    colprimary_group_no,
    colsecondary_payer_name,
    colsecondary_payer_id,
    colsecondary_policy_id,
    colsecondary_group_no,
    colEnteredBy,
    EobPayername,
    EobPayerId,
    serviceLocation,
    colclaimStatus,
    colclaimSubStatus,
    collabSalesReport,
    colclinicSiteName,
    colclaimServiceType,
    colclaimPos,
    colclaimTos
];

export const PAYER_TYPE_LIST = [{ 'id': 1, 'name': 'Primary' }, { 'id': 2, 'name': 'Secondary' }, { 'id': 3, 'name': 'Tertiary' }, { 'id': 4, 'name': 'Patient' }];
export const AGING_LIST = [{ 'id': 1, 'name': '0-30' }, { 'id': 2, 'name': '31-60' }, { 'id': 3, 'name': '61-90' }, { 'id': 4, 'name': '91-120' },{ 'id': 5, 'name': '121-150' },
    { 'id': 6, 'name': '151-180' }, { 'id': 7, 'name': '181-365' }, { 'id': 8, 'name': '365+' }];

export const TRANSACTION_TYPES = [{ 'id': 1, 'name': 'Charge' }, { 'id': 2, 'name': 'Payment' }, { 'id': 3, 'name': 'Adjustment' }, { 'id': 4, 'name': 'Patient Responsibility' }, { 'id': 5, 'name': 'Patient Payment' }, { 'id': 6, 'name': 'Patient Adjustment' }]
export const reportFieldArray=[
    colClaimID,
    colAccessionNumber,
    colPatientID,
    colVisitID,
    colPatientFirstName,
    colPatientLastName,
    colPatientMiddleName,
    colPatientGender,
    colDateOfBirth,
    coldateOfEntry,
    coldateOfServiceFrom,
    coldateOfServiceTo,
    collastBilledDate,
    colfirstBilledDate,
    colrefferingProvider,
    colrefferingProviderNPI,
    colrenderingProvider,
    colrenderingProviderNPI,
    colbillingProvider,
    colbillingProviderNPI,
    colCPTCODE,
    colUnits,
    colmodifier1,
    colmodifier2,
    colmodifier3,
    colmodifier4,
    colICD_list,
    colcpt_charge_amount,
    colcpt_debit_amount,
    colbilled_to_payer_name,
    colbilled_to_payer_id,
    colbilled_to_policy_id,
    colbilled_to_group_no,
    colcurrent_payer_name,
    colcurrent_payer_id,
    colcurrent_payer_policy_id,
    colcurrent_group_no,
    colprimary_payer_name,
    colprimary_payer_id,
    colprimary_policy_id,
    colprimary_group_no,
    colsecondary_payer_name,
    colsecondary_payer_id,
    colsecondary_policy_id,
    colsecondary_group_no,
    colEnteredBy,
    EobPayername,
    EobPayerId,
    serviceLocation,
    colclaimStatus,
    colclaimSubStatus,
    collabSalesReport,
    colclinicSiteName,
    colclaimServiceType,
    colclaimPos,
    colclaimTos
]


export const PaymentAdjustmentTable = [
    colClaimID,
    colAccessionNumber,
    colPatientID,
    colVisitID,
    colPatientFirstName,
    colPatientLastName,
    colPatientMiddleName,
    colPatientGender,
    colDateOfBirth,
    coldateOfEntry,
    coldateOfServiceFrom,
    coldateOfServiceTo,
    collastBilledDate,
    colfirstBilledDate,
    colrefferingProvider,
    colrefferingProviderNPI,
    colrenderingProvider,
    colrenderingProviderNPI,
    colbillingProvider,
    colbillingProviderNPI,
    colCPTCODE,
    colUnits,
    colmodifier1,
    colmodifier2,
    colmodifier3,
    colmodifier4,
    colICD_list,
    colbilled_to_payer_name,
    colbilled_to_payer_id,
    colbilled_to_policy_id,
    colbilled_to_group_no,
    colcurrent_payer_name,
    colcurrent_payer_id,
    colcurrent_payer_policy_id,
    colcurrent_group_no,
    colprimary_payer_name,
    colprimary_payer_id,
    colprimary_policy_id,
    colprimary_group_no,
    colsecondary_payer_name,
    colsecondary_payer_id,
    colsecondary_policy_id,
    colsecondary_group_no,
    colEnteredBy,
    paidGroupNo,
    paidPolicyID,
    paidPayerID,
    paidPayerName,
    cptAdjAmount,
    cptPaidAmount,
    checkDate,
    checkNumber,
    EobPayername,
    EobPayerId,
    serviceLocation,
    colclaimStatus,
    colclaimSubStatus,
    collabSalesReport,
    colclinicSiteName,
    colclaimServiceType,
    colclaimPos,
    colclaimTos,
    billedAmount,
    allowedAmount
];