import i18n from "../../../utilities/i18n";


const TableHeadData = [
    {
        "name": i18n.t("searchClaims.claimId"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": "Batch Level Status",
        "type": "string",
        "width": "medium",
        "sort": false,
    },
    {
        "name": "Message",
        "type": "string",
        "width": "large",
        "sort": false,
    }
];

export const TableBodyData = [
    [
        {
            "id": "",
            "name": "claim_id",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "needLink": true,
        },
        {
            "id": "",
            "name": "batch_level_status",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "medium",
            "needLink": false,
        },
        {
            "id": "",
            "name": "message",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "large",
        }
    ]
]

export const BatchLevelClaimsTable = {
    "name": "simpleTable",
    "isResize": true,
    "isFixedHead": true,
    "tableHeadings": TableHeadData,
    "tableBodyData": TableBodyData
}