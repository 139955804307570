import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

// MUI Components
import { Button, ButtonGroup, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList } from '@mui/material';

// MUI Icons
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// Theme
import theme from '../../../theme';

// Utilities
import { BATCH_STATUS } from '../../../utilities/staticConfigs';


/**
 * Renders a status filter for batch view.
 *
 * @param {number} selectedStatusItem - The currently selected status item.
 * @param {function} setSelectedStatusItem - Function to set the selected status item.
 * @returns {React.ReactNode} The rendered component.
 */
function BatchViewStatusFilter({ selectedStatusItem, setSelectedStatusItem }) {
    // Storing the batch status options
    const options = useMemo(() => BATCH_STATUS, []);

    // State to control the visibility of the dropdown menu
    const [open, setOpen] = useState(false);
    // Reference to the button that opens the dropdown menu
    const anchorRef = useRef(null);

    // Function to handle the click of a menu item
    const handleMenuItemClick = (event, id) => {
        setSelectedStatusItem(id);
        setOpen(false); 
    };

    // Function to toggle the visibility of the dropdown menu
    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const getButtonStyles = (selectedOption) => {
        switch (selectedOption) {
            case BATCH_STATUS.find(status => status.label == "Error")?.id:
                return {
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.secondary.darklight,
                    '&:hover': {
                        backgroundColor: theme.palette.error.light,
                    },
                };
            case BATCH_STATUS.find(status => status.label == "Success")?.id:
                return {
                    backgroundColor: theme.palette.success.main,
                    color: theme.palette.secondary.darklight,
                    '&:hover': {
                        backgroundColor: theme.palette.success.light,
                    },
                };
            case BATCH_STATUS.find(status => status.label == "All")?.id:
            default:
                return {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.secondary.darklight,
                    '&:hover': {
                        backgroundColor: theme.palette.primary.light,
                    },
                };
        }
    };

    const getButtonHoverStyles = (selectedOption) => {
        switch (selectedOption) {
            case BATCH_STATUS.find(status => status.label == "Error")?.id:
                return {
                    hoverBackground: theme.palette.error.light,
                };
            case BATCH_STATUS.find(status => status.label == "Success")?.id:
                return {
                    hoverBackground: theme.palette.success.light,
                };
            case BATCH_STATUS.find(status => status.label == "All")?.id:
            default:
                return {
                    hoverBackground: theme.palette.primary.light,
                };
        }
    };

    return (
        <>
            <ButtonGroup
                variant="contained"
                ref={anchorRef}
                aria-label="Button group with a nested menu"
                sx={{
                    minHeight: '36px',
                    fontFamily: 'inherit',
                    '& .MuiButton-root': {
                        '&:hover': {
                            backgroundColor: getButtonHoverStyles(selectedStatusItem).hoverBackground, // Lighter shade on hover
                        },
                    },
                    '& .MuiButton-contained': {
                        backgroundColor: getButtonStyles(selectedStatusItem).backgroundColor,
                        color: getButtonStyles(selectedStatusItem).color,
                        '&:not(:last-child)': {
                            borderRight: `1px solid ${theme.palette.primary.dark}`, // Separate buttons with a subtle border
                        },
                    },
                    zIndex: 11
                }}
            >
                <Button >{options.find(status => status.id == selectedStatusItem)?.label}</Button>
                <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select filter option"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>

            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                style={{ zIndex: 11 }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            zIndex: 11
                        }}
                    >
                        <Paper elevation={4} sx={{ position: 'relative', zIndex: 11 }}>
                            <div
                                style={{
                                    padding: '5px',
                                    minWidth: '150px',
                                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu" autoFocusItem>
                                        {options.map((option) => (
                                            <MenuItem
                                                key={option.id}
                                                selected={selectedStatusItem === option.label}
                                                onClick={(event) => handleMenuItemClick(event, option.id)}
                                                sx={{
                                                    display: 'block !important',
                                                    padding: '8px 16px !important',
                                                    marginBottom: '4px !important'
                                                }}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </div>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}

BatchViewStatusFilter.propTypes = {
    selectedStatusItem: PropTypes.number.isRequired,
    setSelectedStatusItem: PropTypes.func.isRequired
};

export default BatchViewStatusFilter;
