import * as staticConfigs from '../../../utilities/staticConfigs'

export const headers = {
    [staticConfigs.ROUTE_INBOX]: 'header.messages_Inbox',
    [staticConfigs.ROUTE_INBOX_MESSAGES]: 'header.message_inbox',
    [staticConfigs.ROUTE_SEND_INBOX]: 'header.send_messages',
    [staticConfigs.ROUTE_SEND_INBOX_MESSAGES]: 'header.message_sent',

    [staticConfigs.ROUTE_DRAFT_INBOX]: 'header.darft_messages',
    [staticConfigs.ROUTE_DRAFT_INBOX_MESSAGES]: 'header.message_draft',
    [staticConfigs.ROUTE_COMPOSE_MESSAGES]: 'header.compose',
    [staticConfigs.ROUTE_REPLY_MESSAGES]: 'header.reply_compose',
    [staticConfigs.ROUTE_TEMPLATE]: 'header.templates',
    [staticConfigs.ROUTE_ADD_TEMPLATE]: 'header.add_templates',
    // tasks
    [staticConfigs.ROUTE_MY_TASKS_LIST]: 'header.tasks',
    [staticConfigs.ROUTE_MY_TASKS]: 'header.task_add',
    [staticConfigs.ROUTE_MY_TASKS_EDIT]: 'header.task_edit',

    // Left menus 
    [staticConfigs.ROUTE_DASHBOARD]: 'menuNames.dashboard',
    [staticConfigs.ROUTE_PATIENTS_LIST]: 'menuNames.patients',
    [staticConfigs.ROUTE_RUN_ELIGIBILITY]: 'menuNames.patientsTab.run-eligibility',
    [staticConfigs.ROUTE_PATIENT_ACCOUNTING]: 'menuNames.patientsTab.patient-accounting',
    [staticConfigs.ROUTE_STATEMENT_MANAGEMENT]: 'menuNames.patientsTab.statement-management',
    [staticConfigs.ROUTE_PAYMENT_PLAN]: 'menuNames.patientsTab.payment-plan',
    [staticConfigs.ROUTE_ACTIVE_PAYMENT_PLAN]: 'menuNames.patientsTab.active-payment-plan',

    [staticConfigs.ROUTE_PATIENTS_LEDGER_LIST]: 'menuNames.patientsTab.ledger-list',
    [staticConfigs.ROUTE_PATIENTS_LEDGER]: 'menuNames.patientsTab.ledger-list',
    [staticConfigs.ROUTE_PATIENT_LETTERS]: 'menuNames.patientsTab.patient-letters',
    [staticConfigs.ROUTE_SEARCH_CLAIMS]: 'menuNames.claim-module.search-claims',
    [staticConfigs.ROUTE_SEARCH_CLAIMS_BETA]: 'menuNames.claim-module.search-claims-beta',
    [staticConfigs.ROUTE_CLAIMS_FILTER]: 'menuNames.claim-module.claim-filter',
    [staticConfigs.ROUTE_AR_CLAIMS]: 'menuNames.claim-module.ar-claims',
    [staticConfigs.ROUTE_AR_PAPER_CLAIMS]: 'menuNames.claim-module.paper-claims',
    [staticConfigs.ROUTE_POST_PAYMENTS]: 'menuNames.payments-module.post_payment',
    [staticConfigs.ROUTE_VIEW_PAYMENTS]: 'menuNames.payments-module.view_payment',
    [staticConfigs.ROUTE_ERA_POSTING]: 'menuNames.payments-module.era_posting',
    [staticConfigs.ROUTE_PRACTICES]: 'menuNames.customerSetup.practices',
    [staticConfigs.ROUTE_PRACTICES_ADD]: 'menuNames.customerSetup.practicesAdd',
    [staticConfigs.ROUTE_PRACTICES_EDIT]: 'menuNames.customerSetup.practicesEdit',

    [staticConfigs.ROUTE_PROVIDER_MANAGEMENT]: 'menuNames.customerSetup.provider-management',
    [staticConfigs.ROUTE_REFERRING_PROVIDER]: 'menuNames.customerSetup.referring-provider',
    [staticConfigs.ROUTE_REFERRING_PROVIDER_ADD]: 'menuNames.customerSetup.referring-provider-add',
    [staticConfigs.ROUTE_REFERRING_PROVIDER_EDIT]: 'menuNames.customerSetup.referring-provider-edit',
    [staticConfigs.ROUTE_PROVIDER_ADD]: 'menuNames.customerSetup.provider-add',
    [staticConfigs.ROUTE_PROVIDER_EDIT]: 'menuNames.customerSetup.provider-edit',
    [staticConfigs.ROUTE_FACILITIES]: 'menuNames.customerSetup.facilitiesList',
    [staticConfigs.ROUTE_FACILITIES_ADD]: 'menuNames.customerSetup.facilities-add',
    [staticConfigs.ROUTE_FACILITIES_EDIT]: 'menuNames.customerSetup.facilities-edit',
    [staticConfigs.ROUTE_CUSTOMER_PROFILE]: 'menuNames.customer-profile-List',
    [staticConfigs.ROUTE_ASSOCIATE_USERS]: 'menuNames.user-management',
    [staticConfigs.ROUTE_PRACTICE_ASSOCIATE_USERS]: 'menuNames.user-management',
    [staticConfigs.ROUTE_PAGE_LOCKS]: 'menuNames.page-locks',
    [staticConfigs.ROUTE_ROLES_AND_PERMISSIONS]: 'menuNames.roles-And-Permissions',
    [staticConfigs.ROUTE_ADMIN_ROLES_AND_PERMISSIONS]: 'menuNames.roles-And-Permissions',
    [staticConfigs.ROUTE_INTERFACE_SEARCH]: 'menuNames.interface_search',
    [staticConfigs.ROUTE_INTERFACE_GRID]: 'menuNames.interface_search',
    [staticConfigs.ROUTE_PAYER_NAME_MATCH]: 'menuNames.interfaceSubMenu.payerNameMatch',
    [staticConfigs.ROUTE_PROVIDER_NAME_MATCH]: 'menuNames.interfaceSubMenu.providerNameMatch',
    [staticConfigs.ROUTE_FACILITY_NAME_MATCH]: 'menuNames.interfaceSubMenu.facilityNameMatch',
    [staticConfigs.ROUTE_TEST_CODE_SETUP]: 'menuNames.interfaceSubMenu.testCodeSetup',
    [staticConfigs.ROUTE_SUPER_DICTIONARIES]: 'menuNames.dictionaries',
    [staticConfigs.ROUTE_PRACTICE_DICTIONARIES]: 'menuNames.practice-dictionaries',
    [staticConfigs.ROUTE_INSURANCE_COMPANIES]: 'dictionaries.payers.InsuranceManagement',
    [staticConfigs.ROUTE_INSURANCE_COMPANIES_ADD]: 'dictionaries.payers.InsuranceManagementAdd',
    [staticConfigs.ROUTE_INSURANCE_COMPANIES_EDIT]: 'dictionaries.payers.InsuranceManagementEdit',

    [staticConfigs.ROUTE_USER_INSURANCE_COMPANIES]: 'dictionaries.payers.InsuranceManagement',
    [staticConfigs.ROUTE_USER_INSURANCE_COMPANIES_ADD]: 'dictionaries.payers.InsuranceManagementAdd',
    [staticConfigs.ROUTE_USER_INSURANCE_COMPANIES_EDIT]: 'dictionaries.payers.InsuranceManagementEdit',


    [staticConfigs.ROUTE_POLICY_TYPE]: 'dictionaries.payers.PolicyTypes',
    // [staticConfigs.ROUTE_PATIENT_TYPE: 'dictionaries.patientDemographics.PatientType'
    [staticConfigs.ROUTE_LANGUAGE_TYPE]: 'dictionaries.patientDemographics.PatientLanguages',
    [staticConfigs.ROUTE_PRACTICE_LANGUAGE_TYPE]: 'dictionaries.patientDemographics.PatientLanguages',
    [staticConfigs.ROUTE_RELATIONSHIP]: 'dictionaries.patientDemographics.Relationship',
    [staticConfigs.ROUTE_PAYER_TYPES]: 'dictionaries.payers.PayerTypes',
    [staticConfigs.ROUTE_REFERRAL_SOURCES]: 'dictionaries.patientDemographics.ReferralSource',
    [staticConfigs.ROUTE_PRACTICE_REFERRAL_SOURCES]: 'dictionaries.patientDemographics.ReferralSource',
    [staticConfigs.ROUTE_TYPE_OF_NOTES]: 'dictionaries.general.TypeOfNotes',
    [staticConfigs.ROUTE_PRACTICE_TYPES]: 'dictionaries.general.PracticeType',
    [staticConfigs.ROUTE_FACILITY_TYPES]: 'dictionaries.general.FacilityType',
    [staticConfigs.ROUTE_ASSIGNMENT_BENEFITS]: 'dictionaries.billing.AssignmentOfBenefits',
    [staticConfigs.ROUTE_ACCIDENT_TYPES]: 'dictionaries.billing.AccidentType',
    [staticConfigs.ROUTE_CLAIM_STATUSES]: 'dictionaries.billing.ClaimStatus',
    [staticConfigs.ROUTE_PRACTICE_CLAIM_STATUSES]: 'dictionaries.billing.ClaimStatus',
    [staticConfigs.ROUTE_CLAIM_SUB_STATUSES]: 'dictionaries.billing.ClaimSubStatus',
    [staticConfigs.ROUTE_PRACTICE_CLAIM_SUB_STATUSES]: 'dictionaries.billing.ClaimSubStatus',
    [staticConfigs.ROUTE_DOCUMENTATION_METHODS]: 'dictionaries.billing.DocumentationMethod',
    [staticConfigs.ROUTE_DOCUMENT_TYPES]: 'dictionaries.billing.DocumentType',
    [staticConfigs.ROUTE_PRACTICE_DOCUMENT_TYPES]: 'dictionaries.billing.DocumentType',
    [staticConfigs.ROUTE_MODIFIERS]: 'dictionaries.codes.Modifiers',
    [staticConfigs.ROUTE_SERVICE_EXCEPTIONS]: 'dictionaries.billing.ServiceAuthorizationException',
    [staticConfigs.ROUTE_PRACTICE_SERVICE_EXCEPTIONS]: 'dictionaries.billing.ServiceAuthorizationException',
    [staticConfigs.ROUTE_POS_CODES]: 'dictionaries.codes.POSCODES',
    [staticConfigs.ROUTE_TRANSPORT_REASONS]: 'dictionaries.billing.TransportReason',
    [staticConfigs.ROUTE_TRACKING_STATUS_TYPES]: 'dictionaries.billing.TrackingStatusType',
    [staticConfigs.ROUTE_TRACKING_STATUS_SOURCES]: 'dictionaries.billing.TrackingStatusSource',
    [staticConfigs.ROUTE_APPOINTMENT_STATUSES]: 'dictionaries.appointments.AppointmentStatus',
    [staticConfigs.ROUTE_PRACTICE_APPOINTMENT_STATUSES]: 'dictionaries.appointments.AppointmentStatus',
    [staticConfigs.ROUTE_APPOINTMENT_TYPES]: 'dictionaries.appointments.AppointmentType',
    [staticConfigs.ROUTE_PRACTICE_APPOINTMENT_TYPES]: 'dictionaries.appointments.AppointmentType',
    [staticConfigs.ROUTE_FINANCIAL_CLASSESS]: 'dictionaries.codes.FinancialClass',
    [staticConfigs.ROUTE_PRACTICE_FINANCIAL_CLASSESS]: 'dictionaries.codes.FinancialClass',
    [staticConfigs.ROUTE_PAYER_CLASSESS]: 'dictionaries.codes.PayerClass',
    [staticConfigs.ROUTE_PRACTICE_PAYER_CLASSESS]: 'dictionaries.codes.PayerClass',
    [staticConfigs.ROUTE_REVENUE_CODE]: 'dictionaries.codes.RevenueCodes',
    [staticConfigs.ROUTE_REMITTANCE_REMARK_CODE]: 'dictionaries.codes.RemittanceRemarkCodes',
    [staticConfigs.ROUTE_PRACTICE_REMITTANCE_REMARK_CODE]: 'dictionaries.codes.RemittanceRemarkCodes',
    [staticConfigs.ROUTE_ADJUSTMENT_REASON_CODE]: 'dictionaries.codes.AdjustmentReasonCodes',
    [staticConfigs.ROUTE_PRACTICE_ADJUSTMENT_REASON_CODE]: 'dictionaries.codes.AdjustmentReasonCodes',
    [staticConfigs.ROUTE_CLAIM_FREQUENCY]: 'dictionaries.billing.ClaimFrequency',
    [staticConfigs.ROUTE_CODE_MANAGEMENT]: 'dictionaries.codes.PracticeTitle',
    [staticConfigs.ROUTE_REFERRING_PROVIDER_GROUP]: 'dictionaries.general.ReferringProviderGroup',
    [staticConfigs.ROUTE_PRACTICE_REFERRING_PROVIDER_GROUP]: 'dictionaries.general.ReferringProviderGroup',
    [staticConfigs.ROUTE_SERVICE_TYPES]: 'dictionaries.billing.ServiceTypes',
    [staticConfigs.ROUTE_TAXONOMIES]: 'dictionaries.billing.Taxonomies',
    [staticConfigs.ROUTE_CPT_CODES]: 'dictionaries.codes.cptCodes',
    [staticConfigs.ROUTE_PRACTICE_CPT_CODES]: 'dictionaries.codes.cptCodes',
    [staticConfigs.ROUTE_ICD10]: 'dictionaries.codes.icd10',
    [staticConfigs.ROUTE_PRACTICE_ICD10]: 'dictionaries.codes.icd10',
    [staticConfigs.ROUTE_FEE_SCHEDULE_MANAGEMENT]: 'dictionaries.codes.FeeSchedule',
    [staticConfigs.ROUTE_STANDARD_FEE_SCHEDULE_MANAGEMENT]: 'dictionaries.codes.FeeSchedule',
    [staticConfigs.ROUTE_DELAY_REASON_CODE]: 'dictionaries.codes.delayReasonCode',
    [staticConfigs.ROUTE_PAYMENT_ADJUSTMENT_TYPE]: 'dictionaries.codes.paymentAdjustmentType',
    [staticConfigs.ROUTE_USER_ACTION_LOGS]: 'menuNames.user-logs',
    [staticConfigs.ROUTE_CSVTEMPLATES]: 'rightSideMenu.csv_templates_import',
    [staticConfigs.ROUTE_CHANGE_PASSWORD]: 'changePassword.header.title',
    [staticConfigs.ROUTE_MYPROFILE]: 'commons.myprofile',
    [staticConfigs.ROUTE_ASSOCIATE_USERS_EDIT]: 'commons.userManagement',
    [staticConfigs.ROUTE_ASSOCIATE_USERS_ADD]: 'commons.userManagement',
    [staticConfigs.ROUTE_PRACTICE_ASSOCIATE_USERS_ADD]: 'commons.userManagement',
    [staticConfigs.ROUTE_CLAIM_ACTION_LOGS]: 'menuNames.claim-logs',
    [staticConfigs.ROUTE_USER_REPORTS_CHARGES_DEBIT_REPORT]: 'reportNames.dailymonthlycharge/paymentreport',
    [staticConfigs.ROUTE_USER_REPORTS_PAYMENT_ADJUSTMENTS_REPORT]: 'reportNames.dailymonthlyadjustmentreport',
    [staticConfigs.ROUTE_USER_REPORTS_360DEGREE_REPORT]: 'reportNames.360degreereport',
    [staticConfigs.ROUTE_USER_REPORTS_OPENAR_REPORT]: 'reportNames.openarreport',
    [staticConfigs.ROUTE_USER_REPORTS_CASHFLOW_REPORT]: 'reportNames.cashflowreport',
    [staticConfigs.ROUTE_USER_REPORTS_TRANSACTION_REPORT]:"reportNames.transactionreport",
    [staticConfigs.ROUTE_USER_REPORTS_WATERFALL_REPORT]: "reportNames.waterfallreport",
    [staticConfigs.ROUTE_PAYMENT_SOURCE]: "dictionaries.codes.PaymentSource",
    [staticConfigs.ROUTE_BACKGROUND_TASK_LOGS]: "header.background_task_batch_status",
}