import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import SelectInput from '../commons/input/SelectInput';
import { YesOrNo } from '../../utilities/dictionaryConstants';
import i18n from '../../utilities/i18n';
import TextInput from '../commons/input/input';
import { Form } from 'react-bootstrap';
import Divider from "@mui/material/Divider";
import { useDispatch } from 'react-redux';
import { GetServiceLocationPOSList } from './StateManagement/asyncThunkAPI';
import { updateBillingInfoData,updatePayerInfoDetails} from "./StateManagement/ClaimModuleSlice";
import { CLAIM_FREQUENCY_ORIGINAL_CLAIM_ID } from '../../utilities/staticConfigs';
const BillingInfo = () => {
    const dispatch = useDispatch();
    /**
     * selectors are declared here
     */
      const {
          renderingProvider,
          referringProvider,
          serviceLocations,
          relationList,
          claimFrequencyList,
      } = useSelector((state) => state.claimSelectInputManager); 

    const {
        billingInfoDetails,
        claimPK  
    } = useSelector((state) => state.claimManagement);

    const onHandleChange = (e) => {
        let name = e?.target?.name;
        let value = e?.target?.value
        if (name === "service_location_value") {
            let service_location_type = value.split("||")[1];
            if (service_location_type == "practice") {
                dispatch(
                    updateBillingInfoData({
                        field: "practice_location",
                        value: value,
                        claimPK: claimPK,
                    })
                );
            }
            else {
            dispatch(
                updateBillingInfoData({
                    field: "facility_location",
                    value: value,
                    claimPK: claimPK,
                })
            );
            }
        } else {
           dispatch(
               updateBillingInfoData({
                   field: name,
                   value: value,
                   claimPK: claimPK,
               })
           );
        }
        
    }

    const onHandleChangeInsurance = (e, fieldType, priority) => {
        let value = e.target.value;
        dispatch(
            updatePayerInfoDetails({
                fieldType: fieldType,
                priority: priority,
                value: value,
                claimPK: claimPK
            })
        );
    };


    /**Fetch POS from facility if there is no POS against the selected CPT */
    useEffect(() => {
        if (billingInfoDetails.service_location_value) {
            const serviceData = billingInfoDetails.service_location_value;
            let firstIndex = serviceData?.indexOf("||");
            let serviceId = serviceData?.substring(0, firstIndex).trim();
            dispatch(GetServiceLocationPOSList(serviceId));
        }
    }, [billingInfoDetails.service_location_value]);
    

    
    return (
        <div className="col-md-12 margin-top20">
            <Form autoComplete="off">
                <div className="row">
                    <div className="col">
                        <div className="font-bold color_blue">
                            <label className="margin-top-3px">
                                {i18n.t(
                                    "searchClaims.professionalClaims.billingInf.providerInfo"
                                )}
                            </label>
                        </div>
                    </div>
                </div>
                <Divider
                    style={{ color: "#000", marginTop: 2, marginBottom: 20 }}
                />
                <div className="row">
                    <div className="col-3">
                        <SelectInput
                            id="billing_provider"
                            data={renderingProvider}
                            required={true}
                            name="billing_provider"
                            value={billingInfoDetails?.billing_provider}
                            onValueChange={onHandleChange}
                            label={i18n.t(
                                "searchClaims.professionalClaims.billingInf.billingProvider"
                            )}
                        />
                    </div>
                    <div className="col-3 pl-0">
                        <SelectInput
                            id="rendering_provider"
                            data={renderingProvider}
                            required={true}
                            name="rendering_provider"
                            value={billingInfoDetails?.rendering_provider}
                            onValueChange={onHandleChange}
                            label={i18n.t(
                                "searchClaims.professionalClaims.billingInf.renderingProvider"
                            )}
                        />
                    </div>
                    <div className="col-3 pl-0">
                        <SelectInput
                            id="supervising_provider"
                            data={renderingProvider}
                            name="supervising_provider"
                            value={billingInfoDetails?.supervising_provider}
                            onValueChange={onHandleChange}
                            label={i18n.t(
                                "searchClaims.professionalClaims.billingInf.supervisingProvider"
                            )}
                        />
                    </div>
                    <div className="col-3 pl-0">
                        <SelectInput
                            id="referring_provider"
                            data={referringProvider}
                            name="referring_provider"
                            value={billingInfoDetails?.referring_provider}
                            onValueChange={onHandleChange}
                            label={i18n.t(
                                "searchClaims.professionalClaims.billingInf.referringProvider"
                            )}
                        />
                    </div>
                </div>
                <div className="row pb-2">
                    <div className="col-3">
                        <SelectInput
                            id="ordering_provider"
                            data={referringProvider}
                            name="ordering_provider"
                            value={billingInfoDetails?.ordering_provider}
                            onValueChange={onHandleChange}
                            label={i18n.t(
                                "searchClaims.professionalClaims.billingInf.orderingProvider"
                            )}
                        />
                    </div>
                    <div className="col-3 pl-0">
                        <SelectInput
                            id="service_location_value"
                            data={serviceLocations}
                            name="service_location_value"
                            value={billingInfoDetails?.service_location_value}
                            onValueChange={onHandleChange}
                            label={i18n.t(
                                "searchClaims.professionalClaims.billingInf.serviceLocations"
                            )}
                            selectOptionRemove={true}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="font-bold color_blue">
                            <label className="margin-top-3px">
                                {i18n.t(
                                    "searchClaims.professionalClaims.billingInf.payerInfo"
                                )}
                            </label>
                        </div>
                    </div>
                </div>
                <Divider
                    style={{ color: "#000", marginTop: 2, marginBottom: 20 }}
                />
                {billingInfoDetails?.primaryInsuranceDataList?.length == 0 &&
                billingInfoDetails?.secondaryInsuranceDataList?.length === 0 &&
                billingInfoDetails?.tertiaryInsuranceDataList?.length === 0 ? (
                    <div className="justify-normal">&nbsp;</div>
                ) : (
                    ""
                )}
                {billingInfoDetails?.primaryInsuranceDataList?.length > 0 ? (
                    <>
                        <div className="margin-top16">
                            <label className="margin-top-3px">
                                {i18n.t(
                                    "searchClaims.professionalClaims.billingInf.primaryPriority"
                                )}
                            </label>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <SelectInput
                                    id="primaryInsuranceList"
                                    data={
                                        billingInfoDetails?.primaryInsuranceDataList
                                    }
                                    value={billingInfoDetails?.insurancePrimary}
                                    className={billingInfoDetails?.insurancePrimary ? billingInfoDetails?.insurancePrimary : 'input-error' }
                                    name={"primaryInsuranceList"}
                                    onValueChange={(e) =>
                                        onHandleChangeInsurance(
                                            e,
                                            "insurance",
                                            "primary"
                                        )
                                    }
                                    label={i18n.t(
                                        "searchClaims.professionalClaims.billingInf.insurance"
                                    )}
                                    needUniqueOptions={true}
                                />
                            </div>
                            <div className="col-3 pl-0">
                                <div className="form-group ">
                                    <TextInput
                                        id="primary_policy_id"
                                        type="text"
                                        name={"primaryPolicyId"}
                                        label={i18n.t(
                                            "searchClaims.professionalClaims.billingInf.policyId"
                                        )}
                                        onValueChange={(e) =>
                                            onHandleChangeInsurance(
                                                e,
                                                "policyId",
                                                "primary"
                                            )
                                        }
                                        value={
                                            billingInfoDetails?.primarypolicyId
                                        }
                                        defaultClassName={billingInfoDetails?.primarypolicyId ? billingInfoDetails?.primarypolicyId : "input-error" }
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    ""
                )}
                {billingInfoDetails?.secondaryInsuranceDataList?.length > 0 ? (
                    <>
                        <div className="margin-top16">
                            <label className="margin-top-3px">
                                {i18n.t(
                                    "searchClaims.professionalClaims.billingInf.secondaryPriority"
                                )}
                            </label>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <SelectInput
                                    id="secondaryInsuranceList"
                                    data={
                                        billingInfoDetails?.secondaryInsuranceDataList
                                    }
                                    value={
                                        billingInfoDetails?.insurancesecondary
                                    }
                                    name={"secondaryInsuranceList"}
                                    onValueChange={(e) =>
                                        onHandleChangeInsurance(
                                            e,
                                            "insurance",
                                            "secondary"
                                        )
                                    }
                                    label={i18n.t(
                                        "searchClaims.professionalClaims.billingInf.insurance"
                                    )}
                                    className={billingInfoDetails?.insurancesecondary ? billingInfoDetails?.insurancesecondary : 'input-error'}
                                    needUniqueOptions={true}
                                />
                            </div>
                            <div className="col-3 pl-0">
                                <div className="form-group ">
                                    <TextInput
                                        id="secondary_policy_id"
                                        type="text"
                                        name={"secondaryPolicyId"}
                                        label={i18n.t(
                                            "searchClaims.professionalClaims.billingInf.policyId"
                                        )}
                                        onValueChange={(e) =>
                                            onHandleChangeInsurance(
                                                e,
                                                "policyId",
                                                "secondary"
                                            )
                                        }
                                        value={
                                            billingInfoDetails?.secondarypolicyId
                                        }
                                        defaultClassName={ billingInfoDetails?.secondarypolicyId ?  billingInfoDetails?.secondarypolicyId : "input-error"}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    ""
                )}
                {billingInfoDetails?.tertiaryInsuranceDataList?.length > 0 ? (
                    <>
                        <div className="margin-top16">
                            <label className="margin-top-3px">
                                {i18n.t(
                                    "searchClaims.professionalClaims.billingInf.tertiaryPriority"
                                )}
                            </label>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <SelectInput
                                    id="tertiaryInsuranceList"
                                    data={
                                        billingInfoDetails?.tertiaryInsuranceDataList
                                    }
                                    value={
                                        billingInfoDetails?.insuranceTertiary
                                    }
                                    name={"tertiaryInsuranceList"}
                                    onValueChange={(e) =>
                                        onHandleChangeInsurance(
                                            e,
                                            "insurance",
                                            "tertiary"
                                        )
                                    }
                                    label={i18n.t(
                                        "searchClaims.professionalClaims.billingInf.insurance"
                                    )}
                                    needUniqueOptions={true}
                                    className={ billingInfoDetails?.insuranceTertiary ?  billingInfoDetails?.insuranceTertiary : 'input-error'}
                                />
                            </div>
                            <div className="col-3 pl-0">
                                <div className="form-group ">
                                    <TextInput
                                        id="tertiary_policy_id"
                                        type="text"
                                        name={"tertiaryPolicyId"}
                                        label={i18n.t(
                                            "searchClaims.professionalClaims.billingInf.policyId"
                                        )}
                                        onValueChange={(e) =>
                                            onHandleChangeInsurance(
                                                e,
                                                "policyId",
                                                "tertiary"
                                            )
                                        }
                                        value={
                                            billingInfoDetails?.tertiarypolicyId
                                        }
                                        defaultClassName={billingInfoDetails?.tertiarypolicyId ? billingInfoDetails?.tertiarypolicyId : "input-error"}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    ""
                )}
                <div className="row">
                    <div className="col">
                        <div className="font-bold color_blue">
                            <label className="margin-top-3px">
                                {i18n.t(
                                    "searchClaims.professionalClaims.billingInf.otherBillingInfo"
                                )}
                            </label>
                        </div>
                    </div>
                </div>
                <Divider
                    style={{ color: "#000", marginTop: 2, marginBottom: 20 }}
                />
                <div className="row">
                    <div className="col-3">
                        <SelectInput
                            id="accept_assignments"
                            data={YesOrNo}
                            name="accept_assignments"
                            value={billingInfoDetails?.accept_assignments}
                            onValueChange={onHandleChange}
                            label={i18n.t(
                                "searchClaims.professionalClaims.billingInf.acceptAssignment"
                            )}
                            selectOptionRemove={true}
                        />
                    </div>
                    <div className="col-3 pl-0">
                        <SelectInput
                            id="insurer_relation"
                            data={relationList}
                            name="insurer_relation"
                            value={billingInfoDetails?.insurer_relation}
                            onValueChange={onHandleChange}
                            label={i18n.t(
                                "searchClaims.professionalClaims.billingInf.patientInsurer"
                            )}
                            selectOptionRemove={true}
                        />
                    </div>
                    <div className="col-3 pl-0">
                        <SelectInput
                            id="patient_signature"
                            data={YesOrNo}
                            name="patient_signature"
                            value={billingInfoDetails?.patient_signature}
                            onValueChange={onHandleChange}
                            label={i18n.t(
                                "searchClaims.professionalClaims.billingInf.patientSignature"
                            )}
                            selectOptionRemove={true}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <SelectInput
                            id="claim_fequency"
                            data={claimFrequencyList}
                            name="claim_fequency"
                            value={billingInfoDetails?.claim_fequency}
                            onValueChange={onHandleChange}
                            label={i18n.t(
                                "searchClaims.professionalClaims.billingInf.claimFrequency"
                            )}
                            includeId={true}
                            prefixName={true}
                        />
                    </div>
                    {Number(billingInfoDetails?.claim_fequency) !== CLAIM_FREQUENCY_ORIGINAL_CLAIM_ID  ? (  
                        <div className="col-3 pl-0">
                            <div className="form-group">
                                <TextInput
                                    id="claim_reference"
                                    type="text"
                                    name="claim_reference"
                                    required={true}
                                    label={i18n.t(
                                        "searchClaims.professionalClaims.billingInf.claimRef"
                                    )}
                                    onValueChange={onHandleChange}
                                    value={billingInfoDetails?.claim_reference}
                                />
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    <div className="col-3 pl-0">
                        <SelectInput
                            id="insured_signature"
                            data={YesOrNo}
                            name="insured_signature"
                            value={billingInfoDetails?.insured_signature}
                            onValueChange={onHandleChange}
                            label={i18n.t(
                                "searchClaims.professionalClaims.billingInf.insureSignature"
                            )}
                            selectOptionRemove={true}
                        />
                    </div>
                </div>
            </Form>
        </div>
    );


}

export default BillingInfo;