import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./style.css";
import i18n from "../../utilities/i18n";
import CommonButton from "../commons/Buttons";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2 }}
      {...other}
      className={"modal-header-custom d-flex justify-content-between"}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedSmallDialogs(props) {

  React.useEffect(() => {
    BootstrapDialogTitle(props);
  }, []);


  let { isAlert, isDelete, isSave, isSubmit, isReset, isSendMail, isNoSaveCancel, isYes, isAdd, isExport, isAssign, isConfirmation } = false

  switch (props.type) {
      case "delete":
          isDelete = true;
          break;
      case "save":
          isSave = true;
          break;
      case "formSubmit":
          isSubmit = true;
          break;
      case "alert":
          isAlert = true;
          break;
      case "reset":
          isReset = true;
          break;
      case "sendMail":
          isSendMail = true;
          break;
      case "yes":
          isYes = true;
          break;
      case "add":
          isAdd = true;
          break;
      case "export":
          isExport = true;
          break;
      case "assign":
          isAssign = true;
      break;
      case 'confirmation':
          isConfirmation=true;
          break;      
      default:
          break;
  }
  
  let hideSave = props.hideSave ? props.hideSave : false;

  let selectNPIBtn = props.selectNPIBtn ? props.selectNPIBtn : false;

  let cropImageBtn = props.cropImageBtn ? props.cropImageBtn : false;

  let saveClose = Object.prototype.hasOwnProperty.call(props, "saveType") ? props.saveType ? true : false : false;


  const handleClose = () => {
    props.setShowModalWindow(false);
    if(props.resetForm){
      props.resetForm();
    }
    if(props.onHide){
      props.onHide();
    }
  };

  function onHandleInactiveChange(e) {
    let value = e.target.checked;
    props.setInactive(value);
  }

  return (
    <div>
      <BootstrapDialog
        open={props.showModal}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        id="isScrollBar"
        aria-labelledby={"alert-dialog-title"}
        aria-describedby={"alert-dialog-description"}
        fullWidth={props?.fullWidth}
      >
        <BootstrapDialogTitle
          id={"customized-dialog-title" + props.header}
          className="modal-header-custom"
          onClose={handleClose}
        >
          <div
            className={
              props.eligibility
                ? "pl-3 d-contents modal-title-text"
                : "pl-3 modal-title-text"
            }
          >
            {props.header}
            {props.eligibility && (
              <button
                onClick={props.update}
                className="btn btn-primary-blue btn-common-height35 mr-5 update-btn"
              >
                {i18n.t("buttons.update")}
              </button>
            )}
          </div>
        </BootstrapDialogTitle>
        {!cropImageBtn && (
          <DialogContent
            dividers
            style={{ minHeight: props?.minHeight }}
            className={
              "small " + (props?.addNoOverflowXClass ? "overflow-x-hidden" : "")
            }
          >
            {props.children}
          </DialogContent>
        )}
        {cropImageBtn && (
          <DialogContent dividers className="cropImageModalBody">
            {props.children}
          </DialogContent>
        )}
        {!isNoSaveCancel && (
          <DialogActions style={{ marginRight: "12px", margin: "10px 0" }}>
            {isExport && (
              <div className="col-2">
                <div className="justify-right">
                  <div className="custom-checkbox">
                    <input
                      type="checkbox"
                      id={"selectAllField"}
                      name="selectAllField"
                      checked={props.fieldsChecked ? true : false}
                      onChange={props.onCheckBoxChange}
                    />
                    <label
                      className="checkbox top-14"
                      htmlFor="selectAllField"
                    ></label>
                  </div>
                  <div className="padding-top5 padding-left-5">
                    <label className="padding-top8">
                      {i18n.t("buttons.selectAll")}
                    </label>
                  </div>
                </div>
              </div>
            )}
            {props.onDownload && (
              <a
                onClick={props.onDownload}
                className="btn btn-primary-blue float-left btn-common-height35 mr-3 p-2"
              >
                Download
              </a>
            )}
            {props.chkInactive && (
              <div className={"d-contents pl-2 padding-left15"}>
                <div className="form-group d-flex justify-content-start padding-left10">
                  <div className="custom-checkbox lh-0">
                    <input
                      type="checkbox"
                      id={"modalInactiveChk"}
                      checked={props.inactive}
                      name="modalInactiveChk"
                      onChange={onHandleInactiveChange}
                    />
                    <label
                      className="checkbox top-14"
                      htmlFor={"modalInactiveChk"}
                    ></label>
                  </div>
                  <div className="padding-top5 padding-left-5">
                    <label className="padding-top5 margin-top-3px">
                      {"Inactive"}
                    </label>
                  </div>
                </div>
              </div>
            )}
                
          
            {!isAlert && !saveClose && !isConfirmation && (
              <div
                className=" px-4 float-right  margin-right10 "
              >
                <CommonButton label={i18n.t("buttons.cancel")} variant="outlined" onClick={handleClose} />
              </div>
            )}
       
            {!isAlert && saveClose && (
              <div
                className="px-4 float-right margin-right10 "
              >
                <CommonButton label={i18n.t("buttons.no")} variant="outlined" onClick={handleClose} />
              </div>
            )}

            {isAssign && (
              <div
                className="float-right margin-right10"
              >
                <CommonButton label={i18n.t("buttons.assign")} variant="contained" onClick={props.addAssigned} />
              </div>
            )}
            {isAlert && (
              <div
                className="float-right margin-right10"
              >
                <CommonButton onClick={props.alertOK} label={i18n.t("buttons.ok")} variant="contained" />
              </div>
            )}
            {isAdd && (
              <div
                className="float-right margin-right10"
              >
                <CommonButton label={i18n.t("buttons.add")} variant="contained" onClick={props.add} />
              </div>
            )}
            {isExport && (
              <div
                className="float-right btn-common-height35 margin-right10"
              >
                <CommonButton label={i18n.t("buttons.export")} variant="contained" onClick={props.exportData} />
              </div>
            )}
            {isDelete && (
              <div
                className="float-right margin-right10"
              >
                <CommonButton onClick={props.deleteItem} label={i18n.t("buttons.delete")} variant="contained" />
              </div>
            )}

            {isConfirmation && (
              <>
              <div
                className="float-right margin-right10"
              >
                <CommonButton onClick={props.goBack} label={i18n.t("buttons.no")} variant="outlined" />
              </div>
                <div
                className="float-right margin-right10"
              >
                <CommonButton onClick={props.alertOK} label={i18n.t("buttons.yes")} variant="contained" />
              </div>
              </>
            )}


            {selectNPIBtn && (
              <button
                form="form_searchNPI"
                className="btn btn-primary-blue float-right btn-common-height35 margin-right10"
              >
                {i18n.t("userPages.practices.labelSelectNPI")}
              </button>
            )}

            {cropImageBtn && (
              <button
                form="form_cropImage"
                onClick={props.onCropImage}
                className="btn btn-primary-blue float-right btn-common-height35 margin-right10"
              >
                {i18n.t("buttons.crop")}
              </button>
            )}

            {isSave && !hideSave && props.submit !== false && (
              <button
                form="form_dataEntry"
                className="btn btn-primary-blue float-right btn-common-height35 margin-right10"
                data-testid="modalSaveButton"
              >
                {i18n.t("buttons.save")}
              </button>
            )}
            {isSave && !hideSave && props.submit == false && (
              <button
                onClick={props.onSaveData}
                className="btn btn-primary-blue float-right btn-common-height35 margin-right10"
              >
                {i18n.t("buttons.save")}
              </button>
            )}
            {isSubmit && (
              <button
                form="formSubmit"
                type="button"
                onClick={props.onSaveFormData}
                className="btn btn-primary-blue float-right btn-common-height35 margin-right10"
              >
                {i18n.t("buttons.save")}
              </button>
            )}
            {isReset && (
              <button
                form="formSubmit"
                type="button"
                onClick={props.onSaveFormData}
                className="btn btn-primary-blue float-right btn-common-height35 margin-right10"
              >
                {i18n.t("buttons.reset")}
              </button>
            )}
            {isSendMail && (
              <div
                form="formSubmit"
                type="button"
                className="float-right margin-right10"
              >
                <CommonButton onClick={props.onSaveFormData} label={i18n.t("buttons.sendMail")} variant="contained" />
              </div>
            )}
            {isYes && (

              <div
                form="formSubmit"
                type="button"
                className={
                  props.buttonStyle == "danger"
                    ? "px-4 float-right margin-right10"
                    : " float-right margin-right10"
                }
              >
                <CommonButton onClick={props.inactveItem} label={i18n.t("buttons.yes")} variant="contained" />
              </div>
            )}
          </DialogActions>
        )}
      </BootstrapDialog>
    </div>
  );
}
