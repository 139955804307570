import api from '../../service/api';


export const getDashboardsList = async (dashboardType) => {
    if (!dashboardType)
        throw new Error("A valid dashboard type is required.")

    return await api.get(`dashboards/get_charts_list?type=${dashboardType}`);
}


export const getChartData = async (chartUri) => {
    if (!chartUri)
        throw new Error("A valid chart api is missing.")

    return await api.get(chartUri);
}
