import React, { useState, useEffect, memo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";
import Notify from '../commons/notify'
import { Form } from 'react-bootstrap'
import PatientDropDownSearch from '../patientManagement/patients/patientDropDownSearch'
import RightSidePanel from '../commons/RightSidePanel/RightSidePanel'
import PatientsAdvancedSearch from '../patientManagement/patients/PatientsAdvancedSearch'
import TextInput from '../../components/commons/input/input';
import CalendarInput from '../commons/input/CalendarInput'
import CommonButton from '../commons/Buttons'
import i18n from '../../utilities/i18n'
import SelectInput from '../commons/input/SelectInput'
import { addRemoveFromSelectedTab, getSearchClaimsTableData } from './StateManagement/asyncThunkAPI'
import { resetSearchClaimData,setSearchClaimFilterData,setSearchClaimsSort} from "./StateManagement/ClaimModuleSlice";
import { ACTIVE_STATUS } from '../../utilities/dictionaryConstants'
import { format } from 'date-fns'
import { getStorage } from '../../utilities/browserStorage'
import Table from '../commons/Table/Table'
import { SearchClaimTableData, TableBodyData } from './SearchClaimTableData'
import { checkPermission, commonTableBody } from '../../utilities/commonUtilities'
import Pagination from '../commons/pagination'
import { MAX_OPEN_TABS_CLAIMS, MAX_OPEN_TABS_PATIENTS, PAGING_END_INDEX, ROUTE_PATIENTS_LIST } from '../../utilities/staticConfigs'
import { permission_key_values_patient } from '../../utilities/permissions'
import service from "./Services/service";


// Caching the report table to avoid un-necessary table render
const MemoizedTable = memo(({ tableObject, sortingFunction, onLinkClick, dropDownFunction }) => {
    return <Table
        tableObject={tableObject}
        onLinkClick={onLinkClick}
        dropDownFunction={dropDownFunction}
        sortingFunction={sortingFunction}
    />
});
MemoizedTable.displayName = 'MemoizedTable';

function SearchClaimsTableComponent() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { searchClaimsTable,
        apiError,
        openTabPks,
        tabsOpen,
    } = useSelector((state) => state.claimManagement);
    const [tableDataApiDispatchFlag, setTableDataApiDispatchFlag] = useState(true)
    // eslint-disable-next-line
    const [searchClaimsList, setSearchClaimsList] = useState([]);
    const [patientPK, setPatientPK] = useState("");
    const [patientSelected, setPatientSelected] = useState([]);
    const [patientAdvSearchData, setPatientAdvSearchData] = useState([]);

    /**
     * NOTIFY & LOADINGBAR CONFIGS
     */
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");
    // eslint-disable-next-line
    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }
    /*******************************/

    /**
    * table initial ordering and sorting initialization
    */
    SearchClaimTableData.tableHeadings[0].initialOrdering = searchClaimsTable.initialOrderingClaimId;
    SearchClaimTableData.tableHeadings[0].orderType = searchClaimsTable.orderType;
    SearchClaimTableData.tableHeadings[1].initialOrdering = searchClaimsTable.initialOrderingPatientName;
    SearchClaimTableData.tableHeadings[1].orderType = searchClaimsTable.orderType;
    SearchClaimTableData.tableHeadings[2].initialOrdering = searchClaimsTable.initialOrderingDos;
    SearchClaimTableData.tableHeadings[2].orderType = searchClaimsTable.orderType;
    SearchClaimTableData.tableHeadings[5].initialOrdering = searchClaimsTable.initialOrderingBillDate;
    SearchClaimTableData.tableHeadings[5].orderType = searchClaimsTable.orderType;


    useEffect(() => {
        if (searchClaimsTable.tableData.length > 0) {
            const rowArray = commonTableBody(
                searchClaimsTable.tableData?.length > 0 ? searchClaimsTable.tableData : [],
                SearchClaimTableData.tableBodyData[0]
            );
            SearchClaimTableData.tableBodyData = rowArray;
            setSearchClaimsList(searchClaimsTable.tableData);
        } else {
            SearchClaimTableData.tableBodyData = TableBodyData
            setSearchClaimsList([]);
        }
    }, [searchClaimsTable.tableData])

    // When returning from the component store the patient drop down data in the redux state
    useEffect(() => {
        if (searchClaimsTable.patientPk) {
            setPatientPK(searchClaimsTable.patientPk)
            setPatientSelected(searchClaimsTable.patientSelected)
            setPatientAdvSearchData(searchClaimsTable.patientSelected);
        }
    }, []);

    useEffect(() => {
        dispatch(setSearchClaimFilterData({ field: 'patientPk', value: patientPK ?? "" }))
        dispatch(setSearchClaimFilterData({ field: 'patientSelected', value: patientSelected ?? [] }))
        dispatch(setSearchClaimFilterData({ field: 'patientAdvanceSearchData', value: patientAdvSearchData ?? [] }))
    }, [patientPK])

    const onHandleChange = (e) => {
        const { name, value } = e.target
        dispatch(setSearchClaimFilterData({ field: name, value: value }))
    }

    useEffect(() => {
        if (apiError)
            showNotifyWindow("show", "error", apiError, 6000);
    }, [apiError])


    const getClaimsTableData = (isSearch) => {
        let dos_from = "";
        let dos_to = "";
        let claim_from = "";
        let claim_to = "";
        let page = isSearch ? 1 : searchClaimsTable.page;
        try {
            dos_from = searchClaimsTable.dosFrom
                ? format(searchClaimsTable.dosFrom, "yyyy-MM-dd")
                : "";
            dos_to = searchClaimsTable.dosTo ? format(searchClaimsTable.dosTo, "yyyy-MM-dd") : "";
            claim_from = searchClaimsTable.claimEnteredFrom
                ? format(searchClaimsTable.claimEnteredFrom, "yyyy-MM-dd")
                : "";
            claim_to = searchClaimsTable.claimEnteredTo ? format(searchClaimsTable.claimEnteredTo, "yyyy-MM-dd") : "";
        } catch {
            return;
        }

        let query =
            "?page_size=" +
            searchClaimsTable.pageSize +
            "&page=" +
            page +
            "&practice_pk=" +
            getStorage("practice") +
            "&claim_id=" +
            searchClaimsTable.searchByClaimId +
            "&active=" +
            searchClaimsTable.activeStatus +
            "&claim_from=" +
            claim_from +
            "&claim_to=" +
            claim_to +
            "&list=true&patient_pk=" +
            patientPK +
            "&dos_from=" +
            dos_from +
            "&dos_to=" +
            dos_to;

        if (searchClaimsTable.sortField) {
            query += "&column=" + searchClaimsTable.orderType + searchClaimsTable.sortField;
        }

        dispatch(getSearchClaimsTableData({ query: query, search: isSearch }));
    }

    useEffect(() => {
        if (tableDataApiDispatchFlag) {
            getClaimsTableData(false);
            setTableDataApiDispatchFlag(false)
        }
    }, [tableDataApiDispatchFlag])

    function onPagePrevious() {
        let previousPage = searchClaimsTable.startIndex + 1 - PAGING_END_INDEX;
        dispatch(setSearchClaimFilterData({ field: 'page', value: previousPage }));

        if (searchClaimsTable.startIndex !== 0) {
            dispatch(setSearchClaimFilterData({ field: 'startIndex', value: searchClaimsTable.startIndex - PAGING_END_INDEX }));
            dispatch(setSearchClaimFilterData({ field: 'endIndex', value: searchClaimsTable.endIndex - PAGING_END_INDEX }));
        }

        setTableDataApiDispatchFlag(true)
    }

    function onPageUp(e) {
        let page = Number(e.target.id);
        dispatch(setSearchClaimFilterData({ field: 'page', value: page }));
        setTableDataApiDispatchFlag(true)
    }

    function onPageNext() {
        let nextPage = searchClaimsTable.startIndex + 1 + PAGING_END_INDEX;
        if (searchClaimsTable.endIndex === searchClaimsTable.totalPages || searchClaimsTable.totalPages <= PAGING_END_INDEX) {
            dispatch(setSearchClaimFilterData({ field: 'page', value: nextPage }));
        } else {
            dispatch(setSearchClaimFilterData({ field: 'page', value: nextPage }));
            dispatch(setSearchClaimFilterData({ field: 'startIndex', value: searchClaimsTable.startIndex + PAGING_END_INDEX }));
            dispatch(setSearchClaimFilterData({ field: 'endIndex', value: searchClaimsTable.endIndex + PAGING_END_INDEX }));
        }
        setTableDataApiDispatchFlag(true)
    }

    function onSearchClaimSortClick(e, name) {
        name = name.replace(" ", "_").toLowerCase();
        let fieldName = "";
        if (name == "patient_name") {
            fieldName = "patient__full_name";

        } else if (name == "claim_id") {
            fieldName = "custom_claim_id";

        } else if (name == "dos") {
            fieldName = "service_from_date";

        } else if (name == "billed_date") {
            fieldName = "last_billed_date";

        }

        dispatch(setSearchClaimsSort(fieldName));
        setTableDataApiDispatchFlag(true);
    }

    function handleReset() {
        dispatch(resetSearchClaimData());
        setSearchClaimsList([]);
        setPatientPK("")
        setPatientSelected([]);
        setPatientAdvSearchData([]);
        setTableDataApiDispatchFlag(true);
    }

    /**
 * function to open a existing claim id
 */
    const openClaim = (id, name) => {
        /**
         * on Edit button click
         */
        if (name.toLowerCase() == "edit") {
            let item = searchClaimsTable?.tableData?.find((obj) => obj?.id == id);
            onEditClaim(
                item?.id,
                item?.patient_name,
                item?.custom_claim_id,
                item?.patient_id
            );
        }
        /**
         * on custom claim id Link click
         */
        if (name.toLowerCase() == "custom_claim_id") {
            let item = searchClaimsTable?.tableData?.find((obj) => obj?.id == id);
            onEditClaim(
                item?.id,
                item?.patient_name,
                item?.custom_claim_id,
                item?.patient_id
            );
        }

        /**
         * on patient namee click it will go to patient tabs
         */
        if (name.toLowerCase() == "patient_name") {
            if (checkPermission(permission_key_values_patient.patient_list_sub_module_view)) {
                let item = searchClaimsTable?.tableData?.find((obj) => obj?.id == id);
                onPatientNameClick(item?.patient_id);
            } else {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            }
        }
    }


    const onEditClaim = (pk, name, claimId, patientID) => {
        let claimOpened = false;
        if (openTabPks && Array.isArray(openTabPks)) {
            claimOpened = openTabPks.includes(patientID);
        }

        /**
         * checking already tab opened if opened showing error message
         */
        if (!claimOpened && tabsOpen >= MAX_OPEN_TABS_CLAIMS) {
            showNotifyWindow(
                "show",
                "error",
                i18n.t("errorMessages.max_claim_tabs")
            );
        } else {
            let item = {
                pk: pk,
                claim_id: claimId,
                type: "claims",
                action: "add",
            };

            dispatch(
                addRemoveFromSelectedTab({
                    item: item,
                    tab: "editClaims" + pk,
                })
            );
        }
    };


    function onPatientNameClick(patientID) {
        const result = service.GetSelectedTabs("patients", getStorage("practice"));
        result.then((response) => {
            let openedPKs =
                response.data && response.data.opened_tab_pks
                    ? response.data.opened_tab_pks.filter(
                        (item) => parseInt(item, 10) > 0
                    )
                    : [];
            if (
                openedPKs &&
                Array.isArray(openedPKs) &&
                openedPKs.length >= MAX_OPEN_TABS_PATIENTS &&
                !openedPKs.includes(patientID)
            ) {
                showNotifyWindow(
                    "show",
                    "error",
                    i18n.t("errorMessages.max_patient_tabs")
                );
            } else {
                let item = { selectedID: patientID }
                history.push(ROUTE_PATIENTS_LIST, item)
            }
        });
    }


    return (
        <div className="">
            <Notify
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            />
            <Form autoComplete="off">
                <div
                    className="searchBox mt-2 px-4 pt-5 pb-3"
                    style={{ backgroundColor: "#fbfbfb" }}
                >
                    <div className="row padding-bottom10">
                        <PatientDropDownSearch
                            setPatientPK={setPatientPK}
                            patientSelected={patientSelected}
                            setPatientSelected={setPatientSelected}
                            patientAdvSearchData={patientAdvSearchData}
                        ></PatientDropDownSearch>
                        <div className="col pl-0 padding-top23">
                            <div className="input-content-box padding-top2">
                                <RightSidePanel
                                    title={i18n.t("commons.advancedSearch")}
                                    onclickLabel={i18n.t("commons.advancedSearch")}
                                >
                                    <PatientsAdvancedSearch
                                        setPatientPK={setPatientPK}
                                        setPatientSelected={setPatientSelected}
                                        setPatientAdvSearchData={setPatientAdvSearchData}
                                    ></PatientsAdvancedSearch>
                                </RightSidePanel>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <div className="form-group">
                                <TextInput
                                    id="basic-typeahead-single"
                                    name="searchByClaimId"
                                    label={i18n.t("searchClaims.searchByClaimId")}
                                    value={searchClaimsTable.searchByClaimId}
                                    onValueChange={onHandleChange}
                                />
                            </div>
                        </div>

                        <div className="col-3 pl-0 margin-left20">
                            <div className="form-group">
                                <SelectInput
                                    data={ACTIVE_STATUS}
                                    name="activeStatus"
                                    value={searchClaimsTable.activeStatus}
                                    onValueChange={onHandleChange}
                                    label={i18n.t("patientPages.patients.labelActiveStatus")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <CalendarInput
                                name="dosFrom"
                                id="dateOfServiceFrom"
                                label={i18n.t("searchClaims.dateOfService")}
                                selected={searchClaimsTable.dosFrom}
                                onValueChange={(value) => { dispatch(setSearchClaimFilterData({ field: 'dosFrom', value: value })) }}
                            />
                        </div>
                        <div className="col-3 pl-0 margin-left20">
                            <CalendarInput
                                name="dosTo"
                                minDate={searchClaimsTable.dosFrom}
                                id="dateOfServiceTo"
                                label={i18n.t("searchClaims.to")}
                                selected={searchClaimsTable.dosTo}
                                onValueChange={(value) => { dispatch(setSearchClaimFilterData({ field: 'dosTo', value: value })) }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <CalendarInput
                                name="claimEnteredFrom"
                                id="claimEnteredFrom"
                                label={i18n.t("searchClaims.claimEnteredDate")}
                                selected={searchClaimsTable.claimEnteredFrom}
                                onValueChange={(value) => { dispatch(setSearchClaimFilterData({ field: 'claimEnteredFrom', value: value })) }}
                            />
                        </div>
                        <div className="col-3 pl-0 margin-left20">
                            <CalendarInput
                                name="claimEnteredTo"
                                minDate={searchClaimsTable.claimEnteredFrom}
                                id="claimEnteredTo"
                                label={i18n.t("searchClaims.to")}
                                selected={searchClaimsTable.claimEnteredTo}
                                onValueChange={(value) => { dispatch(setSearchClaimFilterData({ field: 'claimEnteredTo', value: value })) }}
                            />
                        </div>
                        <div className="col padding-top26 justify-right">
                            <div style={{ marginLeft: 12 }}>
                                <CommonButton label={i18n.t("commons.clear")} variant="outlined" onClick={handleReset} />
                            </div>
                            <div style={{ marginLeft: 12 }}>
                                <CommonButton label={i18n.t("searchClaims.searchClaims")} variant="contained" onClick={()=>getClaimsTableData(true)} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="padding-top5 mt-3" style={{
                    overflowX: "auto", width: "100%"
                }}>
                    <div>
                        <MemoizedTable
                            tableObject={SearchClaimTableData}
                            onLinkClick={openClaim}
                            dropDownFunction={openClaim}
                            sortingFunction={onSearchClaimSortClick}
                        />
                    </div>
                    {/* </div> */}
                    {searchClaimsTable.totalPages <= 1 && (
                        <div className="clear-b-scroll-sector">&nbsp;</div>
                    )}
                    <div className="clear-b-scroll-sector">&nbsp;</div>
                </div>

                <div className='padding-top10'></div>
                {searchClaimsTable.totalPages > 0 ?
                    <Pagination
                        totalPage={searchClaimsTable.totalPages}
                        activePage={searchClaimsTable.page}
                        startIndex={searchClaimsTable.startIndex}
                        endIndex={searchClaimsTable.endIndex}
                        onPagePrevious={onPagePrevious}
                        onPageUp={onPageUp}
                        onPageNext={onPageNext}
                    />
                    : <></>}
            </Form>
        </div>
    )
}

export default SearchClaimsTableComponent