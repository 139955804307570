import React, { useState, useEffect, useContext } from "react";
import { Form } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import service from "./service";

import Pagination from "../../commons/pagination";
import LoadingContext from "../../../container/loadingContext";
import i18n from "../../../utilities/i18n";
import {
  DEFAULT_PAGING_SIZE,
  PAGING_END_INDEX,
  ROUTE_PATIENTS_LEDGER
} from "../../../utilities/staticConfigs";
import { getStorage } from "../../../utilities/browserStorage";
import {
  checkPermission,
  commonTableBody,
} from "../../../utilities/commonUtilities";

import RightSidePanel from "../../commons/RightSidePanel/RightSidePanel";

import PatientsAdvancedSearch from "../patients/PatientsAdvancedSearch";
import PatientDropDownSearch from "../../patientManagement/patients/patientDropDownSearch";
import { LedgerListTableData, TableBodyData } from "./PatientLedgerListTableData";
import Table from "../../commons/Table/Table";
import { permission_key_values } from "../../../utilities/permissions";
import { useDispatch } from "react-redux";
import { resetTaskDetails } from "../../MyTasksScreen/TaskSlice/taskSlice";

const PatientLedgerList = () => {
  const dispatch = useDispatch();
  const setShowLoadingBar = useContext(LoadingContext);
  const practicePK = getStorage("practice");
  const searchValue="";
  const [patientLedgerList, setPatientLedgerList] = useState([]);
  const [gotoLedger, setGotoLedger] = useState(false);
  const [patientPK, setPatientPK] = useState("");
  const [patientSelected, setPatientSelected] = useState([]);

  const [orderType, setOrderType] = useState("");
  const [sortField, setSortField] = useState("");
  const [initialOrderingPatientName, setInitialOrderingPatientName] = useState(true);
  const [initialOrderingPatientId, setInitialOrderingPatientId] = useState(true);
  LedgerListTableData.tableHeadings[0].initialOrdering = initialOrderingPatientName;
  LedgerListTableData.tableHeadings[0].orderType = orderType;
  LedgerListTableData.tableHeadings[1].initialOrdering = initialOrderingPatientId;
  LedgerListTableData.tableHeadings[1].orderType = orderType;

  //Pagination start
  const [totalPage, setTotalPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);
  const [patientAdvSearchData, setPatientAdvSearchData] = useState([]);
  const [patientRemoved, setPatientRemoved] = useState(false);

  function onPagePrevious() {
    let previousPage = startIndex + 1 - PAGING_END_INDEX;
    setActivePage(previousPage);
    if (startIndex !== 0) {
      setStartIndex(startIndex - PAGING_END_INDEX);
      setEndIndex(endIndex - PAGING_END_INDEX);
    }
    getPatientsLedegrData(DEFAULT_PAGING_SIZE,previousPage,sortField);
  }

  function onPageUp(e) {
    let page = Number(e.target.id);
    setActivePage(page);
    getPatientsLedegrData(DEFAULT_PAGING_SIZE,page,sortField);
  }

  function onPageNext() {
    let nextPage = startIndex + 1 + PAGING_END_INDEX;
    if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
      setActivePage(nextPage);
      setStartIndex(startIndex);
      setStartIndex(endIndex);
    } else {
      setActivePage(nextPage);
      setStartIndex(startIndex + PAGING_END_INDEX);
      setEndIndex(endIndex + PAGING_END_INDEX);
    }
    getPatientsLedegrData(DEFAULT_PAGING_SIZE,nextPage,sortField);
  }
  //Pagination ends

  useEffect(() => {
    getPatientsLedegrData(DEFAULT_PAGING_SIZE,activePage);
  }, []);

  useEffect(() => {
  if(patientRemoved){
    setTimeout(()=> {
      getPatientsLedegrData(DEFAULT_PAGING_SIZE,1);
      setActivePage(1);
      setPatientRemoved(false)
    },300)
  }
  }, [patientRemoved])

  
  async function getPatientsLedegrData(DEFAULT_PAGING_SIZE, activePage,
    // DOBDateConverted,
    fieldName, sorting) {
    const fieldOrderType = sorting ? (!orderType ? '-' : '') : orderType;
    setShowLoadingBar(true);

    try {
      const response = await service.PatientsLegderList(
        DEFAULT_PAGING_SIZE,
        activePage,
        practicePK,
        searchValue,
        // DOBDateConverted,
        patientPK,
        fieldName,
        fieldOrderType,
        'yes'
      );

      if (response?.data?.results !== undefined) {
        setTotalPage(Math.ceil(response.data.count / response.data.page_size));

        const rowArray = commonTableBody(response.data.results, LedgerListTableData.tableBodyData[0]);
        LedgerListTableData.tableBodyData = rowArray;

        setPatientLedgerList(response.data.results || []);
      } else {
        LedgerListTableData.tableBodyData = TableBodyData;
        setPatientLedgerList([]);
      }
    } catch (error) {
      console.error("An error occured while fetching patient ledger data:", error)
    } finally {
      setShowLoadingBar(false);
    }
  }

  function onClickPatient(patient_pk) {
    setPatientPK(patient_pk);
    setGotoLedger(true);
    dispatch(resetTaskDetails());
  }

  if (gotoLedger) {
    return (
      <Redirect
        to={{
          pathname: ROUTE_PATIENTS_LEDGER,
          state: { patientPK: patientPK },
        }}
      />
    );
  }


const tableSorting = (e,name) => {
  setShowLoadingBar(true);
    let sortingField = getFieldName(name);
    if (!orderType) setOrderType('-');
    else setOrderType('');
    if (sortingField) getPatientsLedegrData(DEFAULT_PAGING_SIZE,activePage, sortingField, true);
}

function getFieldName(name) {
  name = name.replace(" ", "_").toLowerCase();
  let fieldName = "";
  if (name == "patient_name") {
    fieldName = "full_name";
    setSortField("full_name")
    setInitialOrderingPatientName(false);
    setInitialOrderingPatientId(true);
  } else if (name == "patient_id") {
    fieldName = "custom_patient_id";
    setSortField("custom_patient_id")
    setInitialOrderingPatientId(false);
    setInitialOrderingPatientName(true);
  } 
  return fieldName;
}

  function onSearchPatientList() {
    getPatientsLedegrData(DEFAULT_PAGING_SIZE,1);
    setInitialOrderingPatientName(true);
    setInitialOrderingPatientId(true)
   
  }

  return (
    <React.Fragment>
      <div className="col-md-12">
        <div className="box mr-4" >
          <Form autoComplete="off">
            <div className="patient-ledger-search" style={{backgroundColor:'#fff'}}>
              <PatientDropDownSearch
                inputClass={"padding-top15"}
                orPaddingTop="45px"
                setPatientPK={setPatientPK}
                patientSelected={patientSelected}
                setPatientSelected={setPatientSelected}
                setPatientRemoved={setPatientRemoved}
                onSearchPatientList={onSearchPatientList}
                patientAdvSearchData={patientAdvSearchData}
              ></PatientDropDownSearch>
            
              <div className="col margin-leftM10 padding-top40"> 
                <button
                  type="button"
                  className="btn btn-primary-blue"
                  onClick={onSearchPatientList}
                >
                  {i18n.t("patientLetters.search")}
                </button>
              </div>

              <div className="ml-3 justify-right  padding-top40 margin-right17">
                <RightSidePanel
                  title={i18n.t("commons.advancedSearch")}
                  onclickLabel={i18n.t("commons.advancedSearch")}
                >
                  <PatientsAdvancedSearch
                    setPatientPK={setPatientPK}
                    setPatientSelected={setPatientSelected}
                    setPatientAdvSearchData={setPatientAdvSearchData}
                  ></PatientsAdvancedSearch>
                </RightSidePanel>
              </div>
            </div>
          

            <div className="box-content-white mt-4">
            <div className="box-content-padding">
              <div className="padding-top20">
                <div
                  style={{
                    overflowX: "auto",
                    width: "100%",
                  }}
                >
                  <Table
                    tableObject={LedgerListTableData}
                    list={patientLedgerList}
                    isActionVisible={checkPermission(permission_key_values.patients_patient_ledger_modify) }
                    onLinkClick={onClickPatient}
                    sortingFunction={tableSorting}
                  />
                </div>
                <Pagination
                  totalPage={totalPage}
                  activePage={activePage}
                  startIndex={startIndex}
                  endIndex={endIndex}
                  onPagePrevious={onPagePrevious}
                  onPageUp={onPageUp}
                  onPageNext={onPageNext}
                />
                <div className="clear-b-scroll-sector">&nbsp;</div>
              </div>
            </div>
            </div>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
};
export default PatientLedgerList;
