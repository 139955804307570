import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import './style.css'
import i18n from '../../utilities/i18n';
import CommonButton from '../commons/Buttons';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other} className={'modal-header-custom d-flex justify-content-between'}>
            {children}
            {onClose ? (
                <IconButton
                    id="customized-dialogue-box-close-btn"
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs(props) {
    React.useEffect(() => {
        BootstrapDialogTitle(props);
    }, [])

    let { isAlert, isDelete, isSave, isSubmit, isReset, isSendMail, isYes, isAdd, isExport, isAssign } = false;
    let isNoSaveCancel = props.isNoSaveCancel === true ? true : false;

    switch (props.type) {
        case 'delete':
            isDelete = true;
            break;
        case 'save':
            isSave = true;
            break;
        case 'formSubmit':
            isSubmit = true;
            break;
        case 'alert':
            isAlert = true;
            break;
        case 'reset':
            isReset = true;
            break;
        case 'sendMail':
            isSendMail = true;
            break;
        case 'yes':
            isYes = true;
            break;
        case 'add':
            isAdd = true;
            break;
        case 'export':
            isExport = true;
            break;
        case 'assign':
            isAssign = true;
            break;
        default:
            break;
    }
    

    let hideSave = props.hideSave ? props.hideSave : false;

    let selectNPIBtn = props.selectNPIBtn ? props.selectNPIBtn : false;

    let cropImageBtn = props.cropImageBtn ? props.cropImageBtn : false;


    const handleClose = () => {
        props.setShowModalWindow(false);
        if (props.resetForm) {
            props.resetForm()
        }
        if (props.onHide){
            props.onHide()
        }
    };

    function onHandleInactiveChange(e) {
        let value = e.target.checked;
        props.setInactive(value);
    }


    return (
        <div>
            <BootstrapDialog
                open={props.showModal}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
                id="isScrollBar"
                aria-labelledby={"alert-dialog-title"}
                aria-describedby={"alert-dialog-description"}
            >
                <BootstrapDialogTitle
                    id={"customized-dialog-title" + props.header}
                    className={props.eligibility?' text-center modal-header-custom':'modal-header-custom'} 
                    onClose={handleClose} style={{ height: 45 }}>
                    <div className={props.eligibility ? "pl-3 d-contents modal-title-text" : "pl-3 padding-top2"}>
                        <p
                                className={props.eligibility && ' pl-2 '}>
                            {props.header}
                        </p>
                        {props.eligibility &&
                            <button onClick={props.update} className="btn btn-primary-blue btn-common-height35 update-btn">{i18n.t('buttons.update')}</button>
                        }
                    </div>

                </BootstrapDialogTitle >
                {!cropImageBtn &&
                    <DialogContent dividers style={{ minHeight: props?.minHeight, minWidth: props?.minWidth }} className={props?.addNoOverflowXClass ? 'overflow-x-hidden' : ''}>
                        {props.children}
                    </DialogContent>
                }
                {cropImageBtn &&
                    <DialogContent dividers className='cropImageModalBody'>
                        {props.children}
                    </DialogContent>
                }
                {!isNoSaveCancel &&
                    <DialogActions style={{ marginRight: "12px", margin: "10px 0" }}>
                        {isExport &&
                            <div className="col-2">
                                <div className="justify-right">
                                    <div className="custom-checkbox">
                                        <input
                                            type="checkbox"
                                            id={"selectAllField"}
                                            name="selectAllField"
                                            checked={props.fieldsChecked ? true : false}
                                            onChange={props.onCheckBoxChange}
                                        />
                                        <label className="checkbox top-14"
                                            htmlFor="selectAllField"
                                        ></label>
                                    </div>
                                    <div className="padding-top5 padding-left-5">
                                        <label className="padding-top8">{i18n.t("buttons.selectAll")}</label>
                                    </div>
                                </div>
                            </div>
                        }

                        {props.onDownload &&
                            <a onClick={props.onDownload} className="btn btn-primary-blue float-left btn-common-height35">Download</a>
                        }
                        {props.chkInactive &&
                            <div className={'d-contents pl-2 padding-left15'}>
                                <div className="form-group d-flex justify-content-start padding-left10">
                                    <div className="custom-checkbox lh-0">
                                        <input type="checkbox" id={"modalInactiveChk"}
                                            checked={props.inactive} name="modalInactiveChk" onChange={onHandleInactiveChange}
                                        />
                                        <label className="checkbox top-14" htmlFor={"modalInactiveChk"}></label>
                                    </div>
                                    <div className="padding-top5 padding-left-5">
                                        <label className="padding-top5 margin-top-3px">{'Inactive'}</label>
                                    </div>
                                </div>
                            </div>
                        }

                        {(!isAlert && props.type != 'info') && <div
                            className=" px-4 float-right margin-right10 btn-common-height35">
                            <CommonButton label={i18n.t('buttons.cancel')} onClick={handleClose} variant="outlined" />
                        </div>}

                        {isAssign &&
                            <div
                                className="float-right margin-right10">
                                <CommonButton label={i18n.t('buttons.assign')} variant="conatined" onClick={props.addAssigned} />
                            </div>
                        }
                        {isAlert &&
                            <div className=" float-right margin-right10">
                                <CommonButton
                                    variant="contained"
                                    label={i18n.t('buttons.ok')}
                                    disabled={false}
                                    onClick={props.alertOK}
                                />
                            </div>
                        }
                        {isAdd &&
                            <div
                                className="float-right margin-right10">
                                <CommonButton label={i18n.t('buttons.add')} variant="conatined" onClick={props.add} />
                            </div>
                        }
                        {isExport &&
                            <div
                                data-testid="export-btn"
                                className="float-right margin-right10">
                                <CommonButton label={i18n.t('buttons.export')} id="export-modal-btn" variant="conatined" onClick={props.exportData} />
                            </div>
                        }
                        {isDelete &&
                            <div
                                className="float-right margin-right10">
                                <CommonButton label={i18n.t('buttons.delete')} variant="outlined" onClick={props.deleteItem} />
                            </div>
                        }

                        {selectNPIBtn &&
                            <button form="form_searchNPI" className="btn btn-primary-blue float-right btn-common-height35 margin-right10">{i18n.t('userPages.practices.labelSelectNPI')}</button>
                        }

                        {cropImageBtn &&
                            <button form="form_cropImage" onClick={props.onCropImage} className="btn btn-primary-blue float-right btn-common-height35 margin-right10">{i18n.t('buttons.crop')}</button>
                        }

                        {isSave && !hideSave && props.submit !== false &&
                            <button disabled={props?.insuranceDetails?.length === 0} form="form_dataEntry" data-testid="saveButton2" className="btn btn-primary-blue float-right btn-common-height35 margin-right10">{i18n.t('buttons.save')}</button>
                        }

                        {isSave && !hideSave &&  props.submit==false &&
                            <div
                                className=" float-right margin-right10">
                                <CommonButton label={i18n.t('buttons.save')} variant="contained" onClick={props.onSaveData} />
                            </div>
                        }
                        {isSubmit &&
                            <button form="formSubmit" id="saveButton" type="button" onClick={props.onSaveFormData} className="btn btn-primary-blue float-right btn-common-height35 margin-right10">
                                {i18n.t('buttons.save')}
                            </button>
                        }
                        {isReset &&
                            <button form="formSubmit" type="button" onClick={props.onSaveFormData} className="btn btn-primary-blue float-right btn-common-height35 margin-right10">
                                {i18n.t('buttons.reset')}
                            </button>
                        }
                        {isSendMail &&
                            <button form="formSubmit" type="button" onClick={props.onSaveFormData} className="btn btn-primary-blue float-right btn-common-height35 margin-right10">
                                {i18n.t('buttons.sendMail')}
                            </button>
                        }
                        {isYes &&
                            <div
                                className={props.buttonStyle == 'danger' ? 'px-4 float-right margin-right10' : 'float-right margin-right10'}>
                                <CommonButton label={i18n.t('buttons.yes')} variant="contained" onClick={props.inactveItem} />
                            </div>
                        }
                    </DialogActions>
                }
            </BootstrapDialog>
        </div>
    );
}