// React and Hooks
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// MUI Components
import { Box, IconButton, TextField } from '@mui/material';

// Icons
import SendRoundedIcon from '@mui/icons-material/SendRounded';

// Third-party libraries
import ReactApexChart from 'react-apexcharts';
import { fromJS } from 'immutable';

// Custom Components
import ChartSkeleton from '../ChartSkeleton';
import ErrorFallback from '../ErrorFallback';

// Api Service
import { getChartData } from '../service';

// Redux Toolkit utils
import { useDispatch, useSelector } from 'react-redux';
import { selectChartDataById, updateChartChartData } from '../StateManagement/DashboardSlice';
import { DashboardDateRangeGetter } from '../../../utilities/commonUtilities';

import { DateTime } from "luxon";
import i18n from '../../../utilities/i18n';
/**
 * @description JSX Element to render Combo Line Chart
 * @param {object} chartObject
 * @param {Function} props.showNotifyWindow - Function to show notification window
 * @returns {React.ReactElement} The rendered Combo Line bar chart
 */
const CommonChart = ({ chartObject, showNotifyWindow }) => {
    const dispatch = useDispatch();

    // Cached Chart Data from Redux Store
    const chartDataFromRedux = useSelector(state => selectChartDataById(state, chartObject.id));

    // State variables to store the chart configuration, including the series data and options
    const [chartData, setChartData] = useState(null);
    // Api status to handle the loading, success, and error states
    const [apiStatus, setApiStatus] = useState('idle');

    // Date range state variables to store the start and end date
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [showDateRange, setShowDateRange] = useState(false);
    const [dateRangeLimits, setDateRangeLimits] = useState(null);

    // Fetch chart data on component mount
    useEffect(() => {
        // If the chart data already there in the redux then render the chart with redux data
        if (chartDataFromRedux) {
            setApiStatus('loading');
            // chartDataFromRedux holds row response data, which does not have drill-down and reset events;
            // Hence, first passing the redux state data to process and attach the events.
            setChartData(processChartData(fromJS(chartDataFromRedux).toJS()));
            setTimeout(() => {
                setApiStatus('success');
            }, 100);
        } else {
            fetchChartData();
        }

        // On component un-mount, kill this chart object attached to the global window
        return () => {
            if (window.ApexCharts) {
                window.ApexCharts.exec(chartObject.id, 'destroy');
            }
        };
    }, [chartObject.id]);


    /**
     * Fetches the chart data from the API and sets the chart data and API status.
     * @param {string} timeFrameStart - optional - Start date for the date range.
     * @param {string} timeFrameEnd - optional - End date for the date range.
     * @param {number} reqDrillDownSelection - optional - New drill-down selection to fetch chart data.
     * @link API Response structure - https://aimadev.atlassian.net/wiki/spaces/PBD/pages/236421156/Combo+Line+Bar+Chart+Spec?atlOrigin=eyJpIjoiMmY3NmRlMWY4MGY0NDZhZGJhN2JkYTEzZDZlMDZhMzQiLCJwIjoiaiJ9
     */
    const fetchChartData = async (reqDrillDownSelection, timeFrameStart = startDate, timeFrameEnd = endDate) => {
        setApiStatus('loading');

        // Check if the chart object has an API URI to fetch data from
        if (!chartObject.apiUri) throw new Error("No API URI provided for the chart.");

        try {
            // Create a URLSearchParams object to store the query parameters
            const params = new URLSearchParams({
                ...(reqDrillDownSelection && { drillDownSelection: reqDrillDownSelection }),
                ...(timeFrameStart && { date_from: timeFrameStart }),
                ...(timeFrameEnd && { date_to: timeFrameEnd }),
            });

            const response = await getChartData(`${chartObject.apiUri}?${params.toString()}`);

            if (!response.data) throw new Error("Chart data not found or an error occurred while fetching chart list.");

            // Dispatching the row response without the events attached to redux state
            // the response holds, drillDownflag, options and seriesData
            dispatch(updateChartChartData({ chartId: chartObject.id, chartData: fromJS(response.data).toJS() }));

            // Create a deep clone of the response data using immutable.js
            const chart = fromJS(response.data).toJS();

            // Passing the deep closed response object to processChartData() to attach clickable events
            const processedChartData = processChartData(chart);

            // finally, set the chart data and API status so the component can re-render with the new data
            setChartData(processedChartData);
            setApiStatus('success');
        } catch (error) {
            console.error('Error fetching chart data:', error);
            showNotifyWindow("show", "error", `An error occurred while fetching ${chartObject.chartName ?? ""} chart.`);
            // set the API status to 'error' so the error fallback component will be displayed
            setApiStatus('error');
        }
    };


    /**
     *  @description Util function to validate and set events to the chart object
     * @param {object} chartDataToProcess
     * @returns {object} chart - an object holding necessary properties to render the chart
     */
    const processChartData = (chartDataToProcess) => {
        const chart = { ...chartDataToProcess };
        // If the chart has drillDown options and selection, add an event listener to handle the drill-down click callback
        if (chart.drillDown) {
            chart.options.chart['events'] = {
                // This function first holds the drillDownOptions and drillDownSelection in its closure
                // Then it returns a function that will be called when the chart is clicked
                // This returned function will check if the clicked element has the class 'apexcharts-bar-area' to ensure it's a bar click
                // If it does, it will calculate the next drillDownSelection index
                // If the next index is greater than or equal to the length of the drillDownOptions i.e the last option is reached, it will fetch the first drillDownOption
                // Otherwise, it will fetch the next drillDownOption
                click: (function (drillDownOptions, drillDownSelection, timeFrameRanges) {
                    return function (event, chartContext, config) {
                        if (event.target.classList.contains('apexcharts-bar-area') || event.target.classList.contains('apexcharts-backgroundBar')) {
                            const currentIndex = drillDownOptions.findIndex(option => option === drillDownSelection);

                            // Check if we can drill down further
                            if (currentIndex >= drillDownOptions.length - 1) {
                                fetchChartData('yearly');
                                return;
                            }

                            const nextDrillDownOption = drillDownOptions[currentIndex + 1];
                            const dateRangeUtil = new DashboardDateRangeGetter(timeFrameRanges.from, timeFrameRanges.to);

                            let currentDateRange;
                            try {
                                switch (drillDownSelection) {
                                    case 'yearly': {
                                        currentDateRange = dateRangeUtil.getDateRangeForYearly(config.dataPointIndex);
                                        break;
                                    }
                                    case 'monthly': {
                                        currentDateRange = dateRangeUtil.getDateRangeForMonthly(config.dataPointIndex);
                                        break;
                                    }
                                    case 'weekly': {
                                        currentDateRange = dateRangeUtil.getDateRangeForWeekly(config.dataPointIndex);
                                        break;
                                    }
                                    case 'daily': {
                                        currentDateRange = dateRangeUtil.getDateRangeForDaily(config.dataPointIndex);
                                        break;
                                    }
                                    default:
                                        return;
                                }
                            } catch (error) {
                                console.error("An error occurred while getting the date range:", error);
                                showNotifyWindow('show', 'error', i18n.t("commons.unexpectedError"));
                                return

                            }
                            if (!currentDateRange?.startDate || !currentDateRange?.endDate) {
                                console.error('Invalid date range calculated');
                                return;
                            }
                            // Fetch data for the next drill down level
                            fetchChartData(
                                nextDrillDownOption,
                                currentDateRange.startDate,
                                currentDateRange.endDate
                            );
                        }
                    };
                })(chart?.drillDownOptions ?? [], chart?.drillDownSelection, chart.timeFrameRanges ?? {})
            }
        }

        // If the chart needs a reset button, add a custom toolbar option and  event listener to handle the reset click callback
        if (chart.reset) {
            chart.options.chart.toolbar = {
                ...chart.options.chart.toolbar,
                tools: {
                    ...chart.options.chart.toolbar?.tools,
                    customIcons: [{
                        icon: '<i class="custom-icon-reset"></i>',
                        title: 'Reset Chart',
                        class: 'custom-icon',
                        click: function (chart, options, e) {
                            e.preventDefault();
                            fetchChartData();
                        }
                    }]
                }
            };
        }

        if (chart.timeFrame) {
            chart.options.chart.toolbar = {
                ...chart.options.chart.toolbar,
                tools: {
                    ...chart.options.chart.toolbar?.tools,
                    customIcons: [
                        ...(chart.options?.chart?.toolbar?.tools?.customIcons ?? []),
                        {
                            icon: '<i class="custom-icon-date"></i>',
                            title: 'Date Range',
                            class: '',
                            click: function (chart, options, e) {
                                e.preventDefault();
                                setShowDateRange(prev => !prev);
                            },
                        }
                    ]
                }
            };
            setStartDate(chart.timeFrameRanges?.from ?? null);
            setEndDate(chart.timeFrameRanges?.to ?? null);
        }

        // Set allowed date range boundaries user can choose on the date range picker,
        // to prevent selecting date ranges that are beyond the allowed span like 10 year etc..
        const dateRangeLimits = getdateRangeLimits(chart);
        setDateRangeLimits(dateRangeLimits);

        return chart;
    }

      /**
         * @description Util function to set min and max date ranges allowed to call the fetch chart api
         * @param {object} chartData - Original chart config received from the api response 
         * @returns {object} dateRangeLimits - Object containing date range limits and error message
         * @returns {string} dateRangeLimits.min - Minimum allowed date in 'yyyy-MM-dd' format
         * @returns {string} dateRangeLimits.max - Maximum allowed date in 'yyyy-MM-dd' format 
         * @returns {string} dateRangeLimits.errorMessage - Localized error message for date range validation
         * @returns {object} - Empty object if drillDownSelection is not recognized
      */

    const getdateRangeLimits = (startDate, endDate, drillDownSelection) => {
        const maxYearlyLimit = 10 * 365;  // 10 years in days
        const maxMonthlyLimit = 12 * 30;  // 12 months approximated to 30 days each
        const maxWeeklyLimit = 56;        // 8 weeks in days
        const maxDailyLimit = 13;         // 14 days

        let rangeLimitInDays;
        let errorMessage;

        switch (drillDownSelection) {
            case 'yearly':
                rangeLimitInDays = maxYearlyLimit;
                errorMessage = i18n.t("dashboard-graph.years_validation");
                break;
            case 'monthly':
                rangeLimitInDays = maxMonthlyLimit;
                errorMessage = i18n.t("dashboard-graph.monthly_validation");
                break;
            case 'weekly':
                rangeLimitInDays = maxWeeklyLimit;
                errorMessage = i18n.t("dashboard-graph.weekly_validation");
                break;
            case 'daily':
                rangeLimitInDays = maxDailyLimit;
                errorMessage = i18n.t("dashboard-graph.daily_validation");
                break;
            default:
                return {};
        }

        // Convert startDate and endDate to DateTime instances for manipulation
        const start = DateTime.fromISO(startDate);
        const end = DateTime.fromISO(endDate);
        const min = end.minus({ days: rangeLimitInDays }).toFormat("yyyy-MM-dd");
        const max = start.plus({ days: rangeLimitInDays }).toFormat("yyyy-MM-dd");

        return { min, max, errorMessage };
    };

     /**
      * @description Utility function to validate the date range for the chart's drill-down view
      * before making an API call. The function checks if the provided start and end dates
      * fall within the valid range determined by the selected drill-down level.
      * @param {string} startDate - The start date in ISO format (yyyy-MM-dd).
      * @param {string} endDate - The end date in ISO format (yyyy-MM-dd).
      * @param {string} drillDownSelection - The selected drill-down view (e.g., 'daily', 'weekly', 'monthly', 'yearly').
      * @returns {boolean} validation result as boolean
      */

    const validateDateRange = (startDate, endDate, drillDownSelection) => {
        const { min, max, errorMessage } = getdateRangeLimits(startDate, endDate, drillDownSelection);

        const start = DateTime.fromISO(startDate);
        const end = DateTime.fromISO(endDate);

        if (start < DateTime.fromISO(min) || end > DateTime.fromISO(max)) {
            showNotifyWindow('show', 'error', errorMessage);
            return false;
        }
        return true;
    };

     /**
      * @description  Handles the submission of a date range for fetching chart data. It validates the input
      * date range using the `validateDateRange` function, checks if the start date is before
      * the end date, and then proceeds to fetch the chart data if all validations pass. 
      * If any validation fails, an error notification is shown.
      * @returns {void} - This function doesn't return anything. It either proceeds with fetching data or
      * shows an error notification based on validation results.
      */
    // Event handler for date range submission
    const handleDateRangeSubmit = () => {
        if (!validateDateRange(startDate, endDate, chartData?.drillDownSelection)) {
            return;
        }
        if (startDate > endDate) {
            showNotifyWindow('show', 'error', i18n.t("dashboard-graph.dashboard_dates_validation"));
            return;
        }
        fetchChartData(chartData?.drillDownSelection, startDate, endDate);
    };

    return (
        <Box sx={{
            borderRadius: '8px',
            overflow: 'hidden',
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }}>
            {apiStatus === 'loading' && (
                <ChartSkeleton count={1} chartType='bar' gridXs={12} />
            )}
            {apiStatus === 'success' && chartData && (
                <>
                    {showDateRange && (
                        <Box p={2} display="flex" flexDirection="column" gap={2}>
                            <Box display="flex" gap={2} justifyContent="space-between" alignItems="center">
                                <TextField
                                    type="date"
                                    label="Start Date"
                                    value={startDate}
                                    onChange={(e) => {
                                        setStartDate(e.target.value)
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    size="small"
                                    fullWidth
                                    inputProps={{
                                        min: dateRangeLimits.min,
                                        max: dateRangeLimits.max,
                                    }}
                                />
                                <TextField
                                    type="date"
                                    label="End Date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    size="small"
                                    fullWidth
                                    inputProps={{
                                        min: dateRangeLimits.min,
                                        max: dateRangeLimits.max,
                                    }}
                                />
                                <IconButton
                                    onClick={handleDateRangeSubmit}
                                    style={{ color: '#1479bb', marginTop: '10px' }}
                                    aria-label="Get Chart with Date Range"
                                >
                                    <SendRoundedIcon fontSize='medium' />
                                </IconButton>
                            </Box>
                        </Box>
                    )}
                    <Box sx={{ flexGrow: 1 }}>
                        {chartData?.seriesData && chartData.seriesData.length > 0 ? (
                            <ReactApexChart
                                id={chartObject.id}
                                key={chartObject.id}
                                options={chartData.options}
                                series={chartData.seriesData}
                                type={chartData.options?.chart?.type}
                                height="100%"
                                width="100%"
                            />
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '100%',
                                    fontSize: '1.2rem',
                                    color: 'gray'
                                }}
                            >
                                No data to show
                            </Box>
                        )}
                    </Box>
                </>
            )}

            {apiStatus === 'error' && (
                <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <ErrorFallback
                        errorMessage="Oops! Something went wrong"
                        errorSubtext="We're having trouble loading the Coding SLA charts. This might be due to a temporary glitch or network issue. Let's give it another shot!"
                        retryFunction={() => { fetchChartData() }}
                    />
                </Box>
            )}
        </Box>
    );
};

export default CommonChart;


CommonChart.propTypes = {
    chartObject: PropTypes.shape({
        id: PropTypes.number.isRequired,
        chartName: PropTypes.string.isRequired,
        gridIndexPosition: PropTypes.number.isRequired,
        gridWidth: PropTypes.number.isRequired,
        gridMinHeight: PropTypes.string.isRequired,
        gridElevation: PropTypes.number.isRequired,
        parent: PropTypes.string.isRequired,
        chartType: PropTypes.string.isRequired,
        apiUri: PropTypes.string.isRequired
    }).isRequired,
    showNotifyWindow: PropTypes.func.isRequired
};