import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Box, Typography, Fade } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CommonButton from '../commons/Buttons';

const paperStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    bgcolor: '#FFF5F5', // Light red background
    borderRadius: 4,
    p: 4,
    textAlign: 'center',
    border: '1px solid',
    borderColor: '#FFCCCB', // Light red border
    position: 'relative',
    overflow: 'hidden',
};

const topBarStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    bgcolor: '#FF6B6B', // Red top bar
};

/**
 * ErrorFallback Component
 * 
 * This component displays a user-friendly error message with a retry option.
 * It's designed to be reusable across different parts of the dashboard.
 *
 * @param {Object} props
 * @param {string} props.errorMessage - The main error message to display
 * @param {string} props.errorSubtext - Additional details about the error
 * @param {Function} props.retryFunction - The function to call when the retry button is clicked
 * @param {string} [props.retryButtonText='Try Again'] - The text to display on the retry button
 * @returns {React.ReactElement} The rendered error fallback UI
 */
const ErrorFallback = ({
    errorMessage,
    errorSubtext,
    retryFunction,
    retryButtonText = 'Try Again'
}) => (
    <Fade in={true} timeout={800}>
        <Paper elevation={3} sx={paperStyles}>
            <Box sx={topBarStyles} />
            <ErrorOutlineIcon sx={{ fontSize: 80, color: '#FF6B6B', mb: 3 }} />
            <Typography variant="h4" color="#FF4136" gutterBottom fontWeight="medium">
                {errorMessage}
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph sx={{ maxWidth: '80%', mb: 4 }}>
                {errorSubtext}
            </Typography>
            {retryFunction && (
                <CommonButton
                    variant='contained'
                    label={retryButtonText}
                    onClick={retryFunction}
                />
            )}
        </Paper>
    </Fade>
);

ErrorFallback.propTypes = {
    errorMessage: PropTypes.string.isRequired,
    errorSubtext: PropTypes.string.isRequired,
    retryFunction: PropTypes.func,
    retryButtonText: PropTypes.string
};

export default ErrorFallback;