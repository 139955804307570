import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    charts: {},
    chartsList: {}
}


const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: initialState,
    reducers: {
        updateChartChartData: (state, action) => {
            const { chartId, chartData } = action.payload;
            if (chartData && chartId) {
                state.charts[chartId] = chartData;
            }
        },
        updateChartList: (state, action) => {
            const { dashboardTab, chartsList } = action.payload;
            if (dashboardTab && chartsList) {
                state.chartsList[dashboardTab] = chartsList;
            }
        },
        resetToInitial: () => initialState,
    }
});

export const { updateChartList, updateChartChartData, resetToInitial } = dashboardSlice.actions;
export default dashboardSlice.reducer;

// Selector Hooks
export const selectChartDataById = (state, chartId) => state.dashboard.charts[chartId];
export const getChartsListByTab = (state, dashboardTab) => state.dashboard.chartsList[dashboardTab];
