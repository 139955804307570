// React and Hooks
import React, { useMemo, useState } from 'react';

// Style Hook
import styled from '@emotion/styled';

// MUI Components
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';

// Custom Components
import CodingSla from './CodingSla';
import Notify from '../commons/notify';


// Custom style for the indicator
const CustomIndicator = styled('span')(() => ({
    backgroundColor: 'white',
    height: 7,
    position: 'absolute',
    bottom: 0,
    width: 'calc(100% - 4px)',
    marginLeft: '2px'

}));

const StyledTabList = styled(TabList)`
  & .MuiTabs-indicator {
    background-color: transparent !important;
    height: 0px !important;
  }
`;



const DashboardComponent = () => {
    // Tabs Configs
    const dashboardTabs = useMemo(() => [
        { label: "Billing", value: "billing" },
        { label: "Coding", value: "coding" },
        { label: "Revenue", value: "revenue" },
    ], []);

    const tabContentMap = {
        coding: <CodingSla showNotifyWindow={showNotifyWindow} tab={'coding'} />,
        billing: <h3>Billing SLAs</h3>,
        revenue: <h3>Revenue SLAs</h3>,
    };

    // Current Tab Selection state
    const [currentDashTab, setCurrentDashTab] = useState("coding");
    /***************************/


    // Notify Configs
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");
    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }
    /***************************/


    /**
     * 
     * @param {Event} e Button event
     * @param {Number} reqTab Tab id
     */
    function onTabChange(e, reqTab) {
        setCurrentDashTab(reqTab)
    }


    return (
        <React.Fragment>

            <Notify
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            />

            <div className="col-md-12">
                <div className="pb-5 basic-info-padding">
                    <div className="box-head padding-left5">
                        <TabContext value={currentDashTab}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <StyledTabList
                                    aria-label="lab API tabs example"
                                    TabIndicatorProps={{
                                        children: <CustomIndicator />,
                                    }}
                                    onChange={onTabChange}
                                >
                                    {
                                        dashboardTabs.map((tab) => {
                                            return (
                                                <Tab key={tab.value} label={tab.label} value={tab.value} className='tabMainMenu' />
                                            )
                                        })
                                    }
                                </StyledTabList>
                            </Box>

                            {dashboardTabs.map((tab) => (
                                <TabPanel key={tab.value} value={tab.value} className="bordering">
                                    {tabContentMap[tab.value] || <div>No content for this tab</div>}
                                </TabPanel>
                            ))}
                        </TabContext>

                    </div>
                </div>
            </div>
        </React.Fragment>

    )

}

export default DashboardComponent;
